export enum SatisDurumu
{
    satis_tarihi_bekleniyor="satisTarihiBekleniyor",
    ihale_alicisi_ve_bedelinin_girilmesi_bekleniyor="ihaleAlicisiVeBedelininGirilmesiBekleniyor",
    ihale_bedelinin_yatirilmasi_bekleniyor="ihaleBedelininYatirilmasiBekleniyor",
    ihaleFeshiDavasiBekleniyor="ihaleFeshiDavasiBekleniyor",
    gerceklesti="gerceklesti",
    satis_yapilmadi = "yapilmadi",
}

export enum SatisDurumuLabel
{
    satis_tarihi_bekleniyor="Satış Tarihi Bekleniyor",
    ihale_alicisi_ve_bedelinin_girilmesi_bekleniyor="İhale Alicisi Ve Bedelinin Girilmesi Bekleniyor",
    ihale_bedelinin_yatirilmasi_bekleniyor="İhale Bedelinin Yatırılması Bekleniyor",
    ihaleFeshiDavasiBekleniyor="İhale Feshi Davası Bekleniyor",
    gerceklesti="Gerçekleşti",
    satis_yapilmadi = "Satış Yapılmadı",
}