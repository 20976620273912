<template>
  <html>
  <title>Save File</title>
  <body>
  <div id="invoice">
    <p style="box-sizing: inherit; border: 0px; margin-bottom: 15px; padding: 0px;
    font-size: 22px; color: rgb(34, 34, 34); overflow-wrap: break-word;
    line-height: 24px; font-family: Verdana, sans-serif; text-align: center;
">
      <span
          style="box-sizing: inherit; border: 0px; margin: 0px; padding: 0px; font-family: helvetica, arial, sans-serif;">
        <strong style="box-sizing: inherit; border: 0px; margin: 0px; padding: 0px;">
          {{ fileTitle }}
        </strong>
      </span>
    </p>
    <p style="box-sizing: inherit; border: 0px; margin-bottom: 15px; padding: 0px; font-size: 15px; color: rgb(34, 34, 34); overflow-wrap: break-word; line-height: 24px; font-family: Verdana, sans-serif; text-align: justify;"
       v-for="item in items">
      <span
          style="box-sizing: inherit; border: 0px; margin: 0px; padding: 0px; font-family: helvetica, arial, sans-serif;">
        <strong style="box-sizing: inherit; border: 0px; margin: 0px; padding: 0px;">
        {{ item.header + " " + (item.content ? item.content : "Bilgi Yok") }}
        </strong>
      </span>
    </p>
    <p></p>
  </div>
  </body>
  </html>
</template>


<script lang="js">

import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import html2pdf from "../../node_modules/html2pdf.js/dist/html2pdf";
import {Udf} from "@/classes/HtmlToUdf";
import {FileType} from "@/enum/FileType";
import ObjectInputMixin from "@/mixins/ObjectInputMixin";

@Component({})

export default class SaveFile extends Mixins(ObjectInputMixin) {
  @Prop() items;
  @Prop({default: false}) download;
  @Prop() fileType;
  @Prop() fileName;
  @Prop() fileTitle;

  mounted() {
    if (this.download && this.fileType === FileType.pdf) {
      this.savePDF();
    } else if (this.download && this.fileType === FileType.word) {
      this.saveWord();
    } else if (this.download && this.fileType === FileType.udf) {
      this.saveUDF();
    }
  }

  @Watch('download')
  onDownloadPdf() {
    if (this.download && this.fileType === FileType.pdf) {
      this.savePDF();
    }
  }

  @Watch('download')
  onDownloadWord() {
    if (this.download && this.fileType === FileType.word) {
      this.saveWord();
    }
  }

  @Watch('download')
  onDownloadUdf() {
    if (this.download && this.fileType === FileType.udf) {
      this.saveUDF();
    }
  }

  async savePDF() {

    const element = document.getElementById('invoice');
    let options = {
      margin: 1,
      image: {type: 'jpeg', quality: 0.98},
      html2canvas: {scale: 2},
      jsPDF: {unit: 'in', format: 'letter', orientation: 'portrait'}
    };
    await html2pdf().set(options).from(element).toPdf().get('pdf').then((pdf) => {
      const totalPages = pdf.internal.getNumberOfPages()
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i)
        pdf.setFontSize(10)
        pdf.setTextColor(150)
        pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88),
            (pdf.internal.pageSize.getHeight() - 0.3))
      }
    }).save(this.fileName + '.pdf');
    this.$emit("onClose");
  }

  saveWord() {

    let header = "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
        "xmlns:w='urn:schemas-microsoft-com:office:word' " +
        "xmlns='http://www.w3.org/TR/REC-html40'>" +
        "<head><meta charset='utf-8'><title>Save File</title></head><body>";
    let footer = "</body></html>";
    let sourceHTML = header + document.getElementById("invoice").innerHTML + footer;
    let source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(sourceHTML);
    let fileDownload = document.createElement("a");
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = this.fileName + '.doc';
    fileDownload.click();
    document.body.removeChild(fileDownload);
    this.$emit("onClose");
  }

  saveUDF() {
    let sourceHTML = document.getElementById('invoice').innerHTML;
    new Udf(sourceHTML, this.list).generate(this.fileName + ".udf");
    this.$emit("onClose");
  }
}
</script>
