
import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import IstihkakForm from "@/components/forms/haciz/IstihkakForm.vue";
import {IstihkakEntity} from "@/entity/Haciz/IstihkakEntity";

@Component({
  components: {FormDialog, DeleteDialog, IstihkakForm}
})
export default class IstihkakList extends Mixins(SimpleInputMixin) {
  @Prop() successOnValidate !: any;
  headers = [
    {text: "Ad Soyad", value: "ad_soyad"},
    {text: "TC Kimlik No", value: "tc_kimlik_no"},
    {text: "İşlemler", value: "actions", width: 100, sortable: false, align:'center'},
  ];
  localValue!: Array<IstihkakEntity>;

  mounted() {
    if (!this.value) {
      this.localValue = [];
    }
  }

  onAdd(item: IstihkakEntity) {
    this.localValue.push(item);
    this.input();
  }

  onUpdate(item: IstihkakEntity, index: number) {
    let newArray = [...this.localValue];
    newArray[index] = item;
    this.localValue = newArray;
    this.input();
  }

  onDelete(index: number) {
    this.localValue.splice(index, 1);
    this.input();
  }
}
