

import {Component, Mixins, Prop} from "vue-property-decorator";
import EnumPicker from "@/components/pickers/EnumPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {TebligatTuru, TebligatTuruLabel} from "@/enum/TebligatTuru";

@Component({
  components: {EnumPicker}
})
export default class TebligatTuruPicker extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;

  turler = TebligatTuru;
  turlerLabel = TebligatTuruLabel;
}
