
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import TasinmazBilgileriList from "@/components/lists/haciz/TasinmazBilgileriList.vue";
import IstihkakList from "@/components/lists/haciz/IstihkakList.vue";
import IpotekList from "@/components/lists/haciz/IpotekList.vue";
import NoterPicker from "@/components/pickers/NoterPicker.vue";
import EnabledSwitch from "@/components/inputs/EnabledSwitch.vue";
import Dates from "@/components/inputs/Dates.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import BorcluTakbisPicker from "@/components/pickers/uyap/BorcluTakbisPicker.vue";
import {IBorcluTakbisSorgulamaSonuc} from "@/plugins/uyap-plugin/uyap/BorcluTakbisSorgulama";
import {AvukatTalepListCevapRow} from "@/plugins/uyap-plugin/uyap/AvukatTalepList";

@Component({
  components: {
    BorcluTakbisPicker,
    FormDialog,
    TasinmazBilgileriList,
    IstihkakList,
    IpotekList,
    NoterPicker,
    EnabledSwitch,
    Dates,
    Tutar,
  }
})
export default class GayrimenkulHacziForm extends Mixins(ObjectInputMixin) {
  @Prop() kisiKurumId!: number;
  @Prop() dosyaId!: number;
  @Prop() avukatTalepList!: Array<AvukatTalepListCevapRow>;

  mounted() {
    if ((!this.localValue.uyap_data || this.localValue.uyap_data.talepKodu != this.localValue.haciz_turu.talep_kodu) && this.$store.getters.isUyapConnected) {
      let talep = this.avukatTalepList.find(value => value.talepKodu == this.localValue.haciz_turu.talep_kodu);
      this.localValue.uyap_data = {...talep};
      this.localValue.uyap_data.sorguId = 0;
      this.input();
    }
  }

  onTakbisChange(takbisData: IBorcluTakbisSorgulamaSonuc) {
    this.localValue.uyap_data.il = takbisData.tasinmazDVO.il;
    this.localValue.uyap_data.ilce = takbisData.tasinmazDVO.ilce;
    this.localValue.uyap_data.mahalle = takbisData.tasinmazDVO.mahalle;
    this.localValue.uyap_data.ada = takbisData.tasinmazDVO.ada;
    this.localValue.uyap_data.parsel = takbisData.tasinmazDVO.parsel;
    this.input();
  }
}
