
import {Component, Prop, Mixins} from "vue-property-decorator";
import {MahkemeEntity} from "@/entity/MahkemeEntity";
import YargiBirimleriPicker from "@/components/pickers/YargiBirimleriPicker.vue";
import IlamTuruPicker from "@/components/pickers/IlamTuruPicker.vue";
import DosyaTuruPicker from "@/components/pickers/DosyaTuruPicker.vue";
import SehirPicker from "@/components/pickers/SehirPicker.vue";
import AdliyePicker from "@/components/pickers/AdliyePicker.vue";
import MahkemePicker from "@/components/pickers/MahkemePicker.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";

@Component({
  components: {
    SehirPicker,
    AdliyePicker,
    YargiBirimleriPicker,
    IlamTuruPicker,
    DosyaTuruPicker,
    MahkemePicker,
    FormWrapper,
  },
})
export default class MahkemeForm extends Mixins(ObjectInputMixin) {
  localValue!: MahkemeFormValue;

  onItemActivated() {
    this.localValue.mahkeme.aktif_mi = 1;
    this.$http.put('/api/v1/mahkeme/' + this.localValue.mahkeme.id, this.localValue.mahkeme).then(() => {
      this.$emit("onSuccess",this.localValue);
    });
  }

  onClose() {
    this.$emit("onClose");
  }
}

interface MahkemeFormValue {
  adliye: number
  dosya: number
  ilam: number
  mahkeme: MahkemeEntity
  sehir: number
  yargiBirimi: number
}
