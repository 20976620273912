
import {Component, Mixins} from 'vue-property-decorator';
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import Dates from "@/components/inputs/Dates.vue";
import SatisYapilamamaSebebiPicker from "@/components/pickers/SatisYapilamamaSebebiPicker.vue";
import Times from "@/components/inputs/Times.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import MahkemeSecim from "@/components/inputs/MahkemeSecim.vue";
import KabulRetPicker from "@/components/pickers/KabulRetPicker.vue";
import IhaleAlicisiPicker from "@/components/pickers/IhaleAlicisiPicker.vue";
import {SatisYapilamamaSebebi} from "@/enum/SatisYapilamamaSebebi";
import {IhaleAlicisi} from "@/enum/IhaleAlicisi";
import {SatisEntity} from "@/entity/SatisEntity";

@Component({
  computed: {
    IhaleAlicisi() {
      return IhaleAlicisi
    },
    SatisYapilamamaSebebi() {
      return SatisYapilamamaSebebi
    }
  },
  components: {IhaleAlicisiPicker, KabulRetPicker, MahkemeSecim, Tutar, Times, SatisYapilamamaSebebiPicker, Dates}
})
export default class SatisForm extends Mixins(ObjectInputMixin) {
  localValue!: SatisEntity;

  get ihaleBedeliCheck(): boolean {
    if (this.localValue.baslangic_tarihi) {
      let fark = this.$helper.tarihFarkiGunSayisi(new Date(this.localValue.baslangic_tarihi), new Date());
      if (this.localValue.satis_yapildi_mi && !this.localValue.ihale_bedeli_yatirildi_mi && fark >= 7) {
        return true;
      }
    }
    return false;
  }
}
