import {FaizOranEntity} from "@/entity/FaizOranEntity";
import {HesapSekli} from "@/enum/HesapSekli";

export class FaizTuruEntity {
    id!: number;
    isim: string | null = null;
    standart_mi: boolean = false;
    yillik_devreyle_islensin_mi: boolean = false;
    uyap_kod!: string;
    uyap_aciklama!: string;
    aktif_mi!: boolean;
    takip_id!: number;
    hesap_sekli: HesapSekli = HesapSekli['365_gun'];
    faiz_oranlari: Array<FaizOranEntity> = [];
}
