import { render, staticRenderFns } from "./AlacakliPicker.vue?vue&type=template&id=96f1abb4&scoped=true"
import script from "./AlacakliPicker.vue?vue&type=script&lang=ts"
export * from "./AlacakliPicker.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96f1abb4",
  null
  
)

export default component.exports