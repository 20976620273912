import {KisiEntity} from "@/entity/KisiEntity";
import {KurumEntity} from "@/entity/KurumEntity";
import {TakipEntity} from './TakipEntity';
import {TahsilatEntity} from './TahsilatEntity';
import {TebligatEntity} from './TebligatEntity';
import {HacizEntity} from "@/entity/Haciz/HacizEntity";
import {TarafSifatiEntity} from "@/entity/TarafSifatiEntity";
import {BorcluVekiliEntity} from "@/entity/BorcluVekiliEntity";
import {SatisEntity} from "@/entity/SatisEntity";
import {FileEntity} from "@/entity/FileEntity";
import {HacizTalebiEntity} from "@/entity/Haciz/HacizTalebiEntity";

export class BorcluEntity {
    id!: number;
    takip_id!: number;
    takip!: TakipEntity;
    kisi_id!: number;
    kisi!: KisiEntity;
    kurum_id!: number;
    kurum!: KurumEntity;
    sorumluluk_siniri_var_mi!: boolean;
    tereke_mi!: boolean;
    sorumluluk_siniri!: number;
    tebligatlar: Array<TebligatEntity> | null = [];
    hacizler: Array<HacizEntity> | null = [];
    haciz_talepleri: Array<HacizTalebiEntity> | null = [];
    taraf_sifati_id!: number;
    taraf_sifati!: TarafSifatiEntity;
    tahsilatlar: Array<TahsilatEntity> = [];
    vekiller: Array<BorcluVekiliEntity> = [];
    birinci_satis_id !: number;
    birinci_satis !: SatisEntity;
    ikinci_satis!: SatisEntity;
    ikinci_satis_id !: number;
    file: Array<FileEntity> = [];
}
