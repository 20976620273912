
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import Dates from "@/components/inputs/Dates.vue";
import EnabledSwitch from "@/components/inputs/EnabledSwitch.vue";
import {SeksenDokuzTebligatEntity} from "@/entity/Haciz/SeksenDokuzTebligatEntity";

@Component({
  components: {
    EnabledSwitch,
    FormDialog,
    Dates,
  }
})
export default class SeksenDokuzForm extends Mixins(ObjectInputMixin) {
  @Prop() label!: string;
  localValue!: SeksenDokuzTebligatEntity;

  barkodSorgulama() {
    window.open('https://www.turkiye.gov.tr/ptt-gonderi-takip', '_blank');
  }
}
