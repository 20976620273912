
import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import TasinirEkleForm from "@/components/forms/haciz/TasinirEkleForm.vue";
import {TasinirBilgileriEntity} from "@/entity/Haciz/TasinirBilgileriEntity";

@Component({
  components: {TasinirEkleForm, FormDialog, DeleteDialog}
})
export default class TasinirBilgileriList extends Mixins(SimpleInputMixin) {
  @Prop({}) successOnValidate !: any;
  headers = [
    {text: "Malın Cinsi", value: "malin_cinsi"},
    {text: "Malın Adeti", value: "malin_adeti"},
    {text: "Takdir Edilen Değer", value: "takdir_edilen_deger"},
    {text: "İşlemler", value: "actions", width: 150, sortable: false},
  ];
  localValue!: Array<TasinirBilgileriEntity>;

  mounted() {
    if (!this.value) {
      this.localValue = [];
    }
  }

  onAdd(item: TasinirBilgileriEntity) {
    this.localValue.push(item);
    this.input();
  }

  onUpdate(item: TasinirBilgileriEntity, index: number) {
    let newArray = [...this.localValue];
    newArray[index] = item;
    this.localValue = newArray;
    this.input();
  }

  onDelete(index: number) {
    this.localValue.splice(index, 1);
    this.input();
  }
}
