
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import {TasinmazEntity} from "@/entity/Haciz/TasinmazEntity";
import SehirPicker from "@/components/pickers/SehirPicker.vue";
import IlcePicker from "@/components/pickers/IlcePicker.vue";

@Component({
  components: {
    IlcePicker,
    SehirPicker,
    FormWrapper,
  }
})
export default class TasinmazEkleForm extends Mixins(ObjectInputMixin) {
  @Prop({}) successOnValidate !: any;
  localValue!: TasinmazEntity;
};
