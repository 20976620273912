
import {Component, Mixins, Prop} from "vue-property-decorator";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {DatabasePicker},
})
export default class IlamTuruPicker extends Mixins(SimpleInputMixin) {
  @Prop() returnId!: boolean;
  @Prop({}) required!: boolean;
  @Prop({}) rules!: string;
  @Prop() label!:string;

  returnObject(e: any) {
    this.$emit('return-object', e)
  }
}
