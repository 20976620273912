export enum IlamsizTebligatBelgeTuru {
    odemeemri = "odemeemri",
    yuzucdavetiyesi = "yuzucdavetiyesi",
    bakiyeborcmuhtirasi = "bakiyeborcmuhtirasi",
    yenilemeemri = "yenilemeemri",
    kiymettakdiri = "kiymettakdiri",
    adresarastirma = "adresarastirma",
    seksendokuzabirhaciz = "seksendokuzabirhaciz",
    satisilani="satisilani",
    diger = "diger",
}
export enum IlamliTebligatBelgeTuru {
    icraemri = "icraemri",
    yuzucdavetiyesi = "yuzucdavetiyesi",
    bakiyeborcmuhtirasi = "bakiyeborcmuhtirasi",
    yenilemeemri = "yenilemeemri",
    kiymettakdiri = "kiymettakdiri",
    adresarastirma = "adresarastirma",
    seksendokuzabirhaciz = "seksendokuzabirhaciz",
    satisilani="satisilani",
    diger = "diger",
}

export enum TebligatBelgeTuruLabel {
    odemeemri = "Ödeme Emri",
    icraemri = "İcra Emri",
    yuzucdavetiyesi = "103 Davetiyesi",
    bakiyeborcmuhtirasi = "Bakiye Borç Muhtırası",
    yenilemeemri = "Yenileme Emri",
    kiymettakdiri = "Kıymet Takdiri",
    adresarastirma = "Adres Araştırma",
    seksendokuzabirhaciz="89/1 Haciz",
    satisilani="Satış İlanı",
    diger = "Diğer",
}
