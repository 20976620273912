export enum HacizTuru {
    alacakliOlduguDosya = 1,
    aracHacziTalebi,
    bankaHacziTalebi,
    gayrimenkulHacziTalebi,
    maasHacziTalebi,
    postaCekiHacziTalebi,
    sgkMossipHacziTalebi,
    tasinirHacizTalebi,
    tasinirHacizTalimatiTalebi,
    ihbarnameTalebi89_1,
    ihbarnameTalebi89_2,
    ihbarnameTalebi89_3,
    muzekkereAlacakHaczi,
    hisseKarPayiHaczi,
    markaPatentEndustriyelTasarimHaczi,
    gemiHaczi,
    digerHaciz
}