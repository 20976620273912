export enum TahsilatTuru {
    avukat = "avukat",
    muvekkil = "muvekkil",
    icra = "icra",
    ptt = "ptt"
}

export enum TahsilatTuruLabel {
    avukat = "Haricen Avukata",
    muvekkil = "Haricen Müvekkile",
    icra = "İcraya Tahsilat",
    ptt = "PTT'ye Tahsilat"
}