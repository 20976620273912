

import {Component, Mixins, Prop} from "vue-property-decorator";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";
import {BorcluEntity} from "@/entity/BorcluEntity";
import {AxiosResponse} from "axios";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {DatabasePicker}
})
export default class TakipBorcluPicker extends Mixins(SimpleInputMixin) {
  @Prop({required: true}) takipId!: number;
  @Prop() label!: string;
  @Prop() rules!: string;
  @Prop() clearable!: string | boolean;

  items: Array<BorcluEntity> = []

  get isClearable() {
    return this.clearable || this.clearable === "";
  }

  mounted() {
    this.load();
  }

  load() {
    this.$http.get("/api/v1/takip/" + this.takipId + "/borclu").then((response: AxiosResponse<BorcluEntity[]>) => {
      this.items = response.data;
      this.input();
    });
  }
}
