import {AdresEntity} from "@/entity/AdresEntity";
import {TebligatDurumu} from "@/enum/TebligatDurumu";
import {TebligatSekli} from "@/enum/TebligatSekli";
import {IlamliTebligatBelgeTuru, IlamsizTebligatBelgeTuru} from "@/enum/TebligatBelgeTuru";
import {TebligatAdresTuru} from "@/enum/TebligatAdresTuru";
import {TakipTipiEntity} from "@/entity/TakipTipiEntity";
import {ItirazinIptaliDavasiEntity} from "@/entity/ItirazinIptaliDavasiEntity";
import {TebligatTuru} from "@/enum/TebligatTuru";

export class TebligatEntity {
    id!: number;
    teslim_alan!: string | undefined;
    adres_id!: number;
    adres!: AdresEntity;
    tebligat_adres_turu !: TebligatAdresTuru;
    barkod_no!: number;
    bila_sebebi!: string | undefined;
    bila_tarihi!: Date | undefined;
    borclu_id!: number;
    haciz_id!: number;
    belge_turu!: IlamliTebligatBelgeTuru | IlamsizTebligatBelgeTuru;
    itiraz_tarihi!: Date | undefined;
    itiraz_var_mi !: boolean;
    itiraz_nedeni !: string | undefined;
    itirazin_iptali_davasi!: Array<ItirazinIptaliDavasiEntity>;
    kesinlesme_tarihi!: Date | undefined;
    son_odeme_tarihi !: Date | undefined;
    mal_beyaninda_bulundu_mu: boolean = false;
    teblig_tarihi!: Date | undefined;
    tebligat_durumu!: TebligatDurumu;
    tebligat_gonderim_tarihi!: Date;
    tebligat_sekli!: TebligatSekli;
    tebligat_turu!: TebligatTuru;
    tebligat_teslim_tipi_id!: number | undefined;
    takip_tipi_id!: number;
    takip_tipi !: TakipTipiEntity;
}
