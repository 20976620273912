
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import TurkUluslararasiGemiSicilineKayitliGemilerForm
  from "@/components/forms/haciz/haciz-turu/TurkUluslararasiGemiSicilineKayitliGemilerForm.vue";
import MilliGemiSicilineKayitliGemilerForm
  from "@/components/forms/haciz/haciz-turu/MilliGemiSicilineKayitliGemilerForm.vue";
import GemiTipiPicker from "@/components/pickers/haciz/GemiTipiPicker.vue";
import {GemiTuru} from "@/enum/haciz/GemiTuru";

@Component({
  components: {
    TurkUluslararasiGemiSicilineKayitliGemilerForm,
    MilliGemiSicilineKayitliGemilerForm,
    GemiTipiPicker,
  }
})
export default class GemiHacziForm extends Mixins(ObjectInputMixin) {
  GemiTuru = GemiTuru;

  mounted() {
    if (this.localValue.uyap_data)
      delete this.localValue.uyap_data;
  }
}
