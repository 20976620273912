
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import YedieminPicker from "@/components/pickers/haciz/YedieminPicker.vue";

@Component({
  components: {
    FormDialog,
    DeleteDialog,
    YedieminPicker,
  },
})
export default class YedieminInfo extends Mixins(ObjectInputMixin) {
  load() {
    this.$emit("load");
  }

  onDelete(index: number) {
    this.localValue.splice(index, 1);
    this.input();
  }
}
