
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import BorcluIcraDosyasiPicker from "@/components/pickers/uyap/BorcluIcraDosyasiPicker.vue";
import {BorcluIcraDosyasiCevapRow} from "@/plugins/uyap-plugin/uyap/BorcluIcraDosyasi";
import {AvukatTalepListCevapRow} from "@/plugins/uyap-plugin/uyap/AvukatTalepList";

@Component({
  components: {BorcluIcraDosyasiPicker, FormWrapper}
})
export default class AlacakliOlduguDosyaHacizForm extends Mixins(ObjectInputMixin) {
  @Prop() kisiKurumId!: number;
  @Prop() dosyaId!: number;
  @Prop() avukatTalepList!: Array<AvukatTalepListCevapRow>;

  mounted() {
    if ((!this.localValue.uyap_data || this.localValue.uyap_data.talepKodu != this.localValue.haciz_turu.talep_kodu) && this.$store.getters.isUyapConnected) {
      let talep = this.avukatTalepList.find(value => value.talepKodu == this.localValue.haciz_turu.talep_kodu);
      this.localValue.uyap_data = {...talep};
      this.localValue.uyap_data.sorguId = 0;
      this.input();
    }
  }

  onDosyaChange(data: BorcluIcraDosyasiCevapRow) {
    this.localValue.uyap_data.kisiKurumId = data.kisiKurumId;
    this.localValue.uyap_data.birimAdi = data.birimAdi;
    this.localValue.uyap_data.dosyaNo = data.dosyaNo;
    this.localValue.uyap_data.dosyaTurKod = data.dosyaTurKod;
    this.input();
  }
}
