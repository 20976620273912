export default class AlacakliData {
    isim: string = "";
    isKurum: boolean = false;
    uniqueDataText: string = "";
    uniqueData: string = "";
    adres: string="";
    vekilIsimler: string[] =[];
    vekilAdres: string = "";
    bankaHesapBilgisi: string = "";
    bankaHesapBilgisiSahibi: string = "";
}