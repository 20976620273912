
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import NoterPicker from "@/components/pickers/NoterPicker.vue";
import Dates from "@/components/inputs/Dates.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import {TemlikEntity} from "@/entity/Haciz/TemlikEntity";
import YevmiyeNumarasi from "@/components/inputs/YevmiyeNumarasi.vue";

@Component({
  components: {
    YevmiyeNumarasi,
    FormWrapper,
    NoterPicker,
    Dates,
    Tutar
  }
})
export default class TemlikForm extends Mixins(ObjectInputMixin) {
  @Prop({}) successOnValidate !: any;
  localValue!: TemlikEntity;
}
