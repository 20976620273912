

import {Component, Mixins} from "vue-property-decorator";
import EnumPicker from "@/components/pickers/EnumPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {TebligatAlicisi, TebligatAlicisiLabel} from "@/enum/TebligatAlicisi";

@Component({
  components: {EnumPicker}
})
export default class TebligatAlicisiPicker extends Mixins(SimpleInputMixin) {
  alicilar = TebligatAlicisi;
  alicilarLabel = TebligatAlicisiLabel;
}
