
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import AdresList from "@/components/lists/AdresList.vue";
import {IsverenEntitiy} from "@/entity/Haciz/IsverenEntitiy";

@Component({
  components: {AdresList, FormWrapper}
})
export default class IsverenForm extends Mixins(ObjectInputMixin) {
  @Prop({}) successOnValidate !: any;
  localValue!: IsverenEntitiy;


}
