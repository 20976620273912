export enum NotTuru{
    gorusme=1,
    haciz_satis_rehin,
    borclu,
    alacakli,
    tebligat,
    ozel
}

export enum NotTuruLabel{
    "Görüşme"=1,
    "Haciz-Satış-Rehin",
    "Borçlu",
    "Alacaklı",
    "Tebligat",
    "Özel"
}