

import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {Component, Mixins, Prop} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import EnabledSwitch from "@/components/inputs/EnabledSwitch.vue";
import Tutar from "@/components/inputs/Tutar.vue";

@Component({
  components: {
    Tutar,
    ParaBirimiPicker,
    EnabledSwitch,
    Dates,
  }
})
export default class KiymetTakdiriForm extends Mixins(ObjectInputMixin) {
  @Prop() label!: string;
  @Prop() description!: string;

}
