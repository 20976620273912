export enum TebligatAlicisi {
    borclu = "borclu",
    ucuncuSahis = "ucuncuSahis",
}

export enum TebligatAlicisiLabel {
    borclu = "Borçlu",
    ucuncuSahis = "Üçüncü Şahıs",
}

// todo:kaldırılacak
