
import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {IIcraDosyasiSonuc} from "@/plugins/uyap-plugin/uyap/BorcluIcraDosyasi";

@Component({
  components: {}
})

export default class BorcluIcraDosyasiPicker extends Mixins(SimpleInputMixin) {
  @Prop({required: true}) dosyaId!: string;
  @Prop({required: true}) kisiKurumId!: number;
  @Prop() rules!: string;
  @Prop() showUyapIcon!: string | boolean;
  @Prop() tooltip!: string;

  items: Array<IIcraDosyasiSonuc> = [];

  get isShowUyapIcon() {
    return this.showUyapIcon || this.showUyapIcon === "";
  }

  onIcraDosyasiChange(v: number) {
    this.$emit('dosyaData', this.items.find(item => item.dosyaId == this.localValue));
    this.input();
  }

  created() {
    this.load();
  }

  async load() {
    let icraDosyaRes = (await this.$uyap.BorcluIcraDosyasi().run({
      dosyaId: this.dosyaId,
      kisiKurumId: this.kisiKurumId
    }));
    if (icraDosyaRes.sorguSonucu) {
      // @ts-ignore
      this.items = icraDosyaRes.sorguSonucDVO;
    }
  }
}
