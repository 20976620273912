export enum DavaSonucu {
    kabul = "kabul",
    ret = "ret",
    kismi_kabul = "kismi_kabul",
    diger = "diger"
}

export enum DavaSonucuLabel {
    kabul = "Kabul",
    ret = "Ret",
    kismi_kabul = "Kısmi Kabul",
    diger = "Diğer"
}