export enum IlamTipi {
    mahkeme = "mahkeme",
    noter = "noter",
    hakem_karari = "hakem_karari",
    arabulucu = "arabulucu",
    diger = "diger",
}

export enum IlamTipiLabel {
    mahkeme = "Mahkeme",
    noter = "Noter",
    hakem_karari = "Hakem Kararı",
    arabulucu = "Arabulucu",
    diger = "Diğer",
}
