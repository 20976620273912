import { render, staticRenderFns } from "./KiymetTakdiriForm.vue?vue&type=template&id=9ebfa354&scoped=true"
import script from "./KiymetTakdiriForm.vue?vue&type=script&lang=ts"
export * from "./KiymetTakdiriForm.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ebfa354",
  null
  
)

export default component.exports