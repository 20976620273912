
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import Dates from "../../inputs/Dates.vue";
import IhaleAlicisiPicker from "@/components/pickers/IhaleAlicisiPicker.vue";
import KabulRetPicker from "@/components/pickers/KabulRetPicker.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import {IhaleAlicisi} from "@/enum/IhaleAlicisi";
import SatisYapilamamaSebebiPicker from "@/components/pickers/SatisYapilamamaSebebiPicker.vue";
import {SatisYapilamamaSebebi} from "@/enum/SatisYapilamamaSebebi";
import MahkemeSecim from "@/components/inputs/MahkemeSecim.vue";
import {SatisTuru} from "@/enum/haciz/SatisTuru";
import Times from "@/components/inputs/Times.vue";
import {SatisEntity} from "@/entity/SatisEntity";


@Component({
  components: {
    Times,
    MahkemeSecim,
    SatisYapilamamaSebebiPicker,
    KabulRetPicker, IhaleAlicisiPicker, Dates, Tutar, FormDialog,
  }
})
export default class BirinciSatisForm extends Mixins(ObjectInputMixin) {
  @Prop() kiymeti!: number | null;

  localValue!: SatisEntity;
  IhaleAlicisi = IhaleAlicisi;
  SatisYapilamamaSebebi = SatisYapilamamaSebebi;
  SatisTuru = SatisTuru;
  dialog!: false;

  @Watch('localValue.mahkeme')
  onMahkemeChange() {
    if (this.localValue.mahkeme) {
      this.localValue.mahkeme_id = this.localValue.mahkeme.id;
    }
  }

  onSatisMasrafiChange() {
    console.log(this.kiymeti)
    if (this.kiymeti)
      this.localValue.muhammen_bedel = (this.kiymeti / 2) + this.localValue.satis_masrafi;
    this.input()
  }

  mounted() {
    this.load();
  }

  load() {
    this.$emit("load");
  }

  ihaleBedeliCheck() {
    if (this.localValue.baslangic_tarihi) {
      let fark = this.$helper.tarihFarkiGunSayisi(new Date(this.localValue.baslangic_tarihi), new Date());
      if (this.localValue.satis_yapildi_mi && this.localValue.ihale_bedeli_yatirildi_mi === false && fark >= 7) {
        return true;
      }
    }
    return false;
  }

}
