
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import EskiSatisForm from "@/components/forms/satis/EskiSatisForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {HacizEntity} from "@/entity/Haciz/HacizEntity";
import FormWrapper from "@/components/FormWrapper.vue";
import {SatisEntity} from "@/entity/SatisEntity";
import {SatisDurumuLabel} from "@/enum/SatisDurumu";
import MasrafForm from "@/components/forms/MasrafForm.vue";
import {IhaleAlicisi} from "@/enum/IhaleAlicisi";
import {BorcluEntity} from "@/entity/BorcluEntity";
import SatisMasrafList from "@/components/forms/SatisMasrafList.vue";
import SatisKopyalaForm from "@/components/forms/satis/SatisKopyalaForm.vue";
import {SatisYapilamamaSebebiLabel} from "@/enum/SatisYapilamamaSebebi";

@Component({
  components: {
    SatisKopyalaForm,
    SatisMasrafList,
    MasrafForm,
    FormWrapper,
    FormDialog,
    SatisForm: EskiSatisForm,
    DeleteDialog,
  }
})
export default class SatisList extends Mixins(ObjectInputMixin) {
  @Prop() haciz !: HacizEntity;
  @Prop() borclu !: BorcluEntity;

  satisYapilmamaSebebiLabel = SatisYapilamamaSebebiLabel

  headers = [
    {text: "Satış", value: "satis_no", sortable: false},
    {text: "Başlangıç Tarihi", value: "baslangic_tarihi", sortable: false},
    {text: "Durum", value: "satis_durumu"},
    {text: "Satış Yapıldı Mı", value: "satis_yapildi_mi", sortable: false},
    {text: "Sebep", value: "satis_yapilamama_sebebi", sortable: false},
    {text: "İşlemler", value: "actions", width: 100, align: "center", sortable: false}
  ]

  get satislar(): Array<SatisEntity> {
    let satislar: Array<SatisEntity> = [];
    // this.borclu.takip.satislar.forEach((satis, index) => {
    //   if (satis.birinci_satis) {
    //     satis.birinci_satis.satis_no = 1;
    //     satis.birinci_satis.satis_talebi_index = index;
    //     satislar.push(satis.birinci_satis);
    //   }
    //   if (satis.ikinci_satis) {
    //     satis.ikinci_satis.satis_no = 2;
    //     satis.birinci_satis.satis_talebi_index = index;
    //     satislar.push(satis.ikinci_satis)
    //   }
    // });
    return satislar;
  }

  load() {
    this.$emit("load");
  }

  deleteButtonDisabled(item: SatisEntity) {
    return this.satislar?.length > 1 && item.satis_no === 1;
  }

  findSatisDurumu(satis: SatisEntity) {
    let fark = this.$helper.tarihFarkiGunSayisi(new Date(), new Date(satis.baslangic_tarihi));
    if (!satis.satis_yapildi_mi)
      return SatisDurumuLabel.satis_yapilmadi;
    else {
      if (fark > 0 || !satis.baslangic_tarihi)
        return SatisDurumuLabel.satis_tarihi_bekleniyor;
      if (fark <= 0 && !satis.ihale_alicisi && !satis.ihale_bedeli)
        return SatisDurumuLabel.ihale_alicisi_ve_bedelinin_girilmesi_bekleniyor;
      if (satis.ihale_alicisi === IhaleAlicisi.ucuncu_sahis && satis.ihale_bedeli_yatirildi_mi)
        return SatisDurumuLabel.ihale_bedelinin_yatirilmasi_bekleniyor;
      return SatisDurumuLabel.gerceklesti;
    }
  }

  isAuthorized(keySuffix: string) {
    let ap = this.$store.state.activeProcesses;
    let authKey = null;
    if (keySuffix[0] !== '-')
      keySuffix = '-' + keySuffix;
    if (ap.filter((item: any) => item.key === "takip-ac").length > 0) {
      authKey = "takip-ac";
    } else if (ap.filter((item: any) => item.key === "takip-duzenle").length > 0) {
      if (ap.filter((item: any) => item.key === "borclu-detay").length > 0) {
        authKey = "satis" + keySuffix;
      }
    }
    if (!authKey)
      return false;
    return this.$helper.isAuthorized(authKey)
  }
}
