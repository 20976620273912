

import {Component, Mixins} from "vue-property-decorator";
import EnumPicker from "@/components/pickers/EnumPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {TebligatSekli, TebligatSekliLabel} from "@/enum/TebligatSekli";

@Component({
  components: {EnumPicker}
})
export default class TebligatSekliPicker extends Mixins(SimpleInputMixin) {
  turler = TebligatSekli;
  turlerLabel = TebligatSekliLabel;
}
