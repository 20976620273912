
import {Prop, Component, Mixins} from "vue-property-decorator";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";
import PickerInputMixin from "@/mixins/PickerInputMixin.vue";

@Component({
  components: {DatabasePicker},
})
export default class AlacakliPicker extends Mixins(PickerInputMixin) {
  @Prop() takipId!: number;
  @Prop() clearable!: string | boolean;

  items: Array<any> = [];

  get isClearable() {
    return this.clearable || this.clearable === "";
  }

  get filteredData() {
    return this.items.filter(item => item.takip_id === this.takipId);
  }

  mounted() {
    this.load();
  }

  load() {
    this.$http.get("/api/v1/alacakli").then((response) => {
      this.items = response.data;
    });
  }
}
