
import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import TemlikForm from "@/components/forms/haciz/TemlikForm.vue";
import {TemlikEntity} from "@/entity/Haciz/TemlikEntity";

@Component({
  components: {
    FormDialog,
    DeleteDialog,
    TemlikForm,
  }
})
export default class TemlikList extends Mixins(SimpleInputMixin) {
  @Prop({}) successOnValidate !: any;
  headers = [
    {text: "Alacaklı Adı Soyadı/Unvanı", value: "alacakli_adi_soyadi_unvani"},
    {text: "Temlik Tutarı", value: "temlik_tutari"},
    {text: "İşlemler", value: "actions", width: 100, sortable: false, align: 'center'},
  ];
  localValue!: Array<TemlikEntity>;

  mounted() {
    if (!this.value) {
      this.localValue = [];
    }
  }

  onAdd(item: TemlikEntity) {
    this.localValue.push(item);
    this.input();
  }

  onUpdate(item: TemlikEntity, index: number) {
    let newArray = [...this.localValue];
    newArray[index] = item;
    this.localValue = newArray;
    this.input();
  }

  onDelete(index: number) {
    this.localValue.splice(index, 1);
    this.input();
  }
}
