
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import SatisTebligatZarfiYazdirForm from "@/components/forms/satis/SatisTebligatZarfiYazdirForm.vue";
import {BorcluEntity} from "@/entity/BorcluEntity";
import {HacizEntity} from "@/entity/Haciz/HacizEntity";
import BirinciSatisForm from "@/components/forms/satis/BirinciSatisForm.vue";
import IkinciSatisForm from "@/components/forms/satis/IkinciSatisForm.vue";
import {SatisEntity} from "@/entity/SatisEntity";
import {SatisYapilamamaSebebi} from "@/enum/SatisYapilamamaSebebi";
import Dates from "@/components/inputs/Dates.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import EnabledSwitch from "@/components/inputs/EnabledSwitch.vue";
import KiymetTakdiriForm from "@/components/forms/KiymetTakdiriForm.vue";
import {KiymetTakdiriEntity} from "@/entity/KiymetTakdiriEntity";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import TasinirlarPicker from "@/components/pickers/TasinirlarPicker.vue";
import TasinmazlarPicker from "@/components/pickers/TasinmazlarPicker.vue";
import {TakipTipi} from "@/enum/TakipTipi";
import {SatisTalebiEntity} from "@/entity/SatisTalebiEntity";

@Component({
  components: {
    TasinmazlarPicker,
    TasinirlarPicker,
    KiymetTakdiriForm,
    EnabledSwitch,
    ParaBirimiPicker,
    Tutar,
    Dates,
    IkinciSatisForm,
    BirinciSatisForm,
    FormWrapper, FormDialog,
    SatisTebligatZarfiYazdirForm
  }
})
export default class EskiSatisForm extends Mixins(ObjectInputMixin) {
  @Prop({required: true}) borclu!: BorcluEntity;
  @Prop() haciz!: BorcluEntity;

  localValue!: HacizEntity | SatisTalebiEntity;

  get kiymeti() {
    if (this.localValue.kiymet_takdiri)
      return this.localValue.kiymet_takdiri.takdir_edilen_deger
    else
      return null;
  }

  get isTakipTipiRehin(): boolean {
    let rehin = false;
    this.borclu.takip.talepler.forEach((talep: TakipTalebiEntity) => {
      if (talep.takip_tipi.rehin_mi)
        rehin = true;
    })
    return rehin;
  }

  get kiymetTakdiriRule() {
    if (this.localValue.kiymet_takdiri_yapildi_mi !== undefined) {
      if (!this.localValue.kiymet_takdiri_yapildi_mi)
        return ['Satış bilgisi eklemek için kıymet takdiri yapılması gerekmektedir.']
      else true;
    } else return ['Lütfen bu alanı doldurun.']
  }

  get ikinciSatisGosterme() {
    if (this.localValue.birinci_satis) {
      if (this.localValue.birinci_satis.satis_yapilamama_sebebi && this.localValue.birinci_satis.satis_yapildi_mi === false) {
        return this.localValue.birinci_satis.satis_yapilamama_sebebi === SatisYapilamamaSebebi.alici_istekli_cikmadigindan;
      }
    }
  }

  get isTasinirTakibi() {
    if (this.borclu.takip.talepler.find((talep: TakipTalebiEntity) => (talep.takip_tipi_id == TakipTipi.menkulRehni || talep.takip_tipi_id == TakipTipi.tasinirRehni)))
      return true;
    else
      return false;
  }

  @Watch('ikinciSatisGosterme')
  onIkinciSatisGostermeChange() {
    if (this.ikinciSatisGosterme) {
      if (!this.localValue.ikinci_satis) {
        this.localValue.ikinci_satis = new SatisEntity();
      }
    } else {
      this.localValue.ikinci_satis = null;
    }
  }

  protected onValueChange() {
    if (!this.localValue) {
      this.localValue = new HacizEntity();
    }
    if (!this.localValue.birinci_satis) {
      this.localValue.birinci_satis = new SatisEntity();
    }
    this.birinciSatisFormDeletion();
    if (this.localValue.ikinci_satis) {
      this.ikinciSatisFormDeletion();
    }
    if (!this.localValue.kiymet_takdiri && !this.isTakipTipiRehin) {
      this.localValue.kiymet_takdiri = new KiymetTakdiriEntity();
    }
  }

  mounted() {
    this.onValueChange();
    this.onIkinciSatisGostermeChange();
    if (!this.localValue.id)
      this.localValue=new SatisTalebiEntity();
  }

  save() {
    if (this.localValue) {
      if (!this.isTakipTipiRehin) {
        if (this.localValue.id) {
          this.$http.put('/api/v1/satis-talebi/' + this.localValue.id, this.localValue).then(() => {
            this.$emit('onSuccess');
          });
        } else {
          this.$http.post('/api/v1/haciz/' + this.haciz.id + '/satis-talebi', this.localValue).then(() => {
            this.$emit('onSuccess');
          });
        }
      } else {
        if (this.localValue.id) {
          this.$http.put('/api/v1/satis-talebi/' + this.localValue.id, this.localValue).then(() => {
            this.$emit('onSuccess');
          });
        } else {
          this.$http.post('/api/v1/takip/' + this.borclu.takip.id + '/satis-talebi', this.localValue).then(() => {
            this.$emit('onSuccess');
          });
        }
      }
    }
  }

  satisTarihiAlert() {
    if (this.localValue.birinci_satis?.baslangic_tarihi) {
      let fark = this.$helper.tarihFarkiGunSayisi(new Date(), new Date(this.localValue.birinci_satis.baslangic_tarihi));
      if (fark == 0)
        return true;
    }
    if (!this.localValue.birinci_satis?.satis_yapildi_mi) {
      if (this.localValue.ikinci_satis?.baslangic_tarihi) {
        let fark = this.$helper.tarihFarkiGunSayisi(new Date(), new Date(this.localValue.ikinci_satis.baslangic_tarihi));
        if (fark == 0)
          return true;
      }
    }
  }

  birinciSatisFormDeletion() {
    if (this.localValue.birinci_satis.satis_yapildi_mi) {
      this.localValue.birinci_satis.satis_yapilamama_sebebi = null;
      this.localValue.birinci_satis.manuel_satis_yapilamama_sebebi = null;
    } else {
      this.localValue.birinci_satis.ihale_alicisi = null;
      this.localValue.birinci_satis.ihale_kesinlesti_mi = null;
      this.localValue.birinci_satis.ucuncu_sahis_adi_soyadi_unvani = null;
      this.localValue.birinci_satis.muhafaza_ve_bakim_giderleri = null;
    }
    if (this.localValue.birinci_satis.ihale_kesinlesti_mi) {
      this.localValue.birinci_satis.ihalenin_feshi_davasi_var_mi = null;
      this.localValue.birinci_satis.ihalenin_feshi_davasi_sonucu = null;
      this.localValue.birinci_satis.mahkeme = null;
    } else {
      this.localValue.birinci_satis.ihale_bedeli_yatirildi_mi = null;
      this.localValue.birinci_satis.ihale_kesinlesme_tarihi = null;
      this.localValue.birinci_satis.ihale_bedeli = null;
    }
    if (!this.localValue.birinci_satis.ihale_bedeli_yatirildi_mi) {
      this.localValue.birinci_satis.ihale_bedeli = null;
    }
    if (!this.localValue.birinci_satis.ihalenin_feshi_davasi_var_mi) {
      this.localValue.birinci_satis.mahkeme = null;
      this.localValue.birinci_satis.ihalenin_feshi_davasi_sonucu = null;
    }
  }

  ikinciSatisFormDeletion() {
    if (this.localValue.ikinci_satis.satis_yapildi_mi) {
      this.localValue.ikinci_satis.satis_yapilamama_sebebi = null;
      this.localValue.ikinci_satis.manuel_satis_yapilamama_sebebi = null;
    } else {
      this.localValue.ikinci_satis.ihale_alicisi = null;
      this.localValue.ikinci_satis.ihale_kesinlesti_mi = null;
      this.localValue.ikinci_satis.ucuncu_sahis_adi_soyadi_unvani = null;
      this.localValue.ikinci_satis.muhafaza_ve_bakim_giderleri = null;
    }
    if (this.localValue.ikinci_satis.ihale_kesinlesti_mi) {
      this.localValue.ikinci_satis.ihalenin_feshi_davasi_var_mi = null;
      this.localValue.ikinci_satis.ihalenin_feshi_davasi_sonucu = null;
      this.localValue.ikinci_satis.mahkeme = null;
    } else {
      this.localValue.ikinci_satis.ihale_bedeli_yatirildi_mi = null;
      this.localValue.ikinci_satis.ihale_kesinlesme_tarihi = null;
      this.localValue.ikinci_satis.ihale_bedeli = null;
    }
    if (!this.localValue.ikinci_satis.ihale_bedeli_yatirildi_mi) {
      this.localValue.ikinci_satis.ihale_bedeli = null;
    }
    if (!this.localValue.ikinci_satis.ihalenin_feshi_davasi_var_mi) {
      this.localValue.ikinci_satis.mahkeme = null;
      this.localValue.ikinci_satis.ihalenin_feshi_davasi_sonucu = null;
    }
  }
}
