export enum SatisTuru {
    tasinir =  1,
    arac ,
    markapatent ,
    hissekarpayi ,
    gemi ,
    tasinmaz ,

}

export let SatisTuruIcon = {
    [SatisTuru.arac]: "mdi-truck-outline",
    [SatisTuru.hissekarpayi]: "mdi-bitcoin",
    [SatisTuru.markapatent]: "mdi-bookmark-multiple",
};

// TODO:kaldırılacak