export enum TebligatSekli {
    normal = "normal",
    yirmibir = "21_e_gore",
    otuzbes = "35_e_gore",
    ilanen = "ilanen",
    elektronik = "elektronik"
}

export enum TebligatSekliLabel {
    normal = "Normal",
    yirmibir = "21'e göre",
    otuzbes = "35'e göre",
    ilanen = "İlanen",
    elektronik = "Elektronik",
}
