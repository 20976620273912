
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import TebligatForm from "@/components/anakart/tebligat/TebligatForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {BorcluEntity} from "@/entity/BorcluEntity";
import {TebligatSekliLabel} from "@/enum/TebligatSekli";
import {TebligatDurumuLabel} from "@/enum/TebligatDurumu"
import {TebligatEntity} from "@/entity/TebligatEntity";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import {NotTuru} from "@/enum/NotTuru";
import NotList from "@/components/lists/NotList.vue";
import TebligatMasrafList from "@/components/forms/TebligatMasrafList.vue";
import {TebligatBelgeTuruLabel} from "@/enum/TebligatBelgeTuru";

@Component({
  components: {TebligatMasrafList, NotList, FormWrapper, FormDialog, TebligatForm, DeleteDialog}
})
export default class TebligatList extends Mixins(SimpleInputMixin) {
  @Prop() borclu!: BorcluEntity;
  @Prop() successOnValidate !: any;

  tebligatSekli = TebligatSekliLabel;
  tebligatDurumuLabel = TebligatDurumuLabel;
  tebligatBelgeTuruLabel = TebligatBelgeTuruLabel;
  search: string = "";
  notTuru = NotTuru;

  headers = [
    {text: "Belge Türü", align: "start", sortable: true, value: "belge_turu"},
    {text: "Gönderim Tarihi", value: "tebligat_gonderim_tarihi"},
    {text: "Durumu", value: "tebligat_durumu"},
    {text: "Bila Tarihi", value: "bila_tarihi"},
    {text: "Tebliğ Tarihi", value: "teblig_tarihi"},
    {text: "Şekli", value: "tebligat_sekli"},
    {text: "Kesinlesme Tarihi", value: "kesinlesme_tarihi"},
    {text: "Son Ödeme Tarihi", value: "son_odeme_tarihi"},
    {text: "İşlemler", value: "actions", sortable: false, width: 150, align: 'center'}
  ];

  localValue!: Array<TebligatEntity>;

  mounted() {
    if (!this.value) {
      this.localValue = [];
    }
  }

  get isSuccessOnValidate() {
    return this.successOnValidate || this.successOnValidate === "";
  }

  load() {
    this.$emit("load");
  }

  onAdd(item: TebligatEntity) {
    if (!this.isSuccessOnValidate) {
      this.load();
    } else {
      this.localValue.push(item);
      this.input();
    }
  }

  onUpdate(item: TebligatEntity, index: number) {
    if (!this.isSuccessOnValidate) {
      this.load();
    } else {
      let newArray = [...this.localValue];
      newArray[index] = item;
      this.localValue = newArray;
      this.input();
    }
  }

  onDelete(index: number) {
    this.localValue.splice(index, 1);
    this.input();
  }
}
