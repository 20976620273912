export enum HarcTuru {
    tahsil_harci = 1,
    hacizden_sonra = 2,
    hacizden_rehinli = 3,
    resmi_ozel = 4,
    takip_talebi_bulunmayan = 5,
    gayrimenkul_ve_gemilerin_icrasi = 6,
    gayrimenkul_ve_gemilerin_tahliyesi = 7,
    menkul_teslimi_icra = 8,
    menkul_teslimi_tahliye = 9,
    icraya_basvurma = 10,
    vekalet_suret = 11,
    pesin = 12,
    cezaevi = 13,
    mts = 14,
    suret = 15,
    haciz_teslim_satis = 16,
    iflas_basvurma = 17,
    iflas_defter_tutma = 18,
    icranin_yerine_getirilmesi = 19,
    tebligat = 20,
    suret_harci = 21

}

export enum HarcTuruLabel {
    "Tahsil Harcı" = 1,
    "Hacizden sonra" = 2,
    "Hacizden veya rehinli mallardan" = 3,
    "Resmi ve özel müesseseler" = 4,
    "Takip talebi bulunmayan alacaklılar" = 5,
    "Gayremenkullerin ve gemilerin icra emri" = 6,
    "Gayremenkullerin ve gemilerin tahliye ve teslim" = 7,
    "Menkul tesliminde icra emri" = 8,
    "Menkul tesliminde tahliye ve teslim" = 9,
    "İcraya Başvurma Harcı" = 10,
    "Vekalet Suret Harcı" = 11,
    "Peşin Harç" = 12,
    "Cezaevi Harcı" = 13,
    "MTS Harcı" = 14,
    "Suret Harcı" = 15,
    "Haciz, Teslim ve Satış Harcı" = 16,
    "İflas Başvurma Harcı" = 17,
    "İflas Defter Tutma Harçları" = 18,
    "Değeri belli olmayan icra takibi" = 19,
    "Tebligat Gideri" = 20,
    "Suret Harcıı" = 21





}