import { render, staticRenderFns } from "./SatisTebligatZarfiYazdirForm.vue?vue&type=template&id=bb2d3b06&scoped=true"
import script from "./SatisTebligatZarfiYazdirForm.vue?vue&type=script&lang=ts"
export * from "./SatisTebligatZarfiYazdirForm.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb2d3b06",
  null
  
)

export default component.exports