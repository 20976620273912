
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import MasrafCesidiPicker from "@/components/pickers/MasrafCesidiPicker.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import Dates from "@/components/inputs/Dates.vue";
import {MasrafCesidi} from "@/enum/MasrafCesidi";
import {MasrafCesidiAlani} from "@/enum/MasrafCesidiAlani";
import {AlacakKalemiAlani} from "@/enum/AlacakKalemiAlani";

@Component({
  components: {MasrafCesidiPicker, FormWrapper, Dates, Tutar},
})
export default class TebligatMasrafForm extends Mixins(ObjectInputMixin) {
  @Prop() masrafCesidiAlani!: MasrafCesidiAlani;
  @Prop() tebligatId !: number;


  alacakKalemi !: any;


  defaultValues = {
    "masraf_cesidi_id": MasrafCesidi.diger
  }


  save() {
    if (!this.localValue.alacak_kalemi_alani_id) {
      this.localValue.alacak_kalemi_alani_id = AlacakKalemiAlani.takipsonrasiMasraf;
      this.localValue.kur = 1;
      this.localValue.para_birimi_id = 1;

      this.localValue.tebligat_id = this.tebligatId;
      this.$http.post('/api/v1/tebligat/' + this.tebligatId + '/masraf', this.localValue)
          .then((response) => {
            this.$emit('onSuccess')
          });
    } else {
      this.$http.put('/api/v1/tebligat/' + this.tebligatId + '/masraf', this.localValue)
          .then((response) => {
            this.$emit('onSuccess')
          });
    }
  }


  onMasrafCesidiChange() {
    if (this.localValue.masraf_cesidi_id) {
      this.localValue.tutar = this.localValue.masraf_cesidi.tutar > 0 ? this.localValue.masraf_cesidi.tutar : "";
      this.localValue.gayri_resmi = this.localValue.masraf_cesidi.gayri_resmi;
    }
  }
}
