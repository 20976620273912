

import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {NotEntity} from "@/entity/NotEntity";
import NotEditor from "@/components/inputs/NotEditor.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {NotTuruLabel} from "@/enum/NotTuru";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import NotForm from "@/components/forms/NotForm.vue";
import IsForm from "@/components/forms/IsForm.vue";

@Component({
  components: {IsForm, NotForm, FormDialog, DeleteDialog, NotEditor}
})

export default class NotInfo extends Mixins(ObjectInputMixin) {
  showAll = false;
  localValue!: NotEntity;
  NotTuruLabel = NotTuruLabel;

  load() {
    this.$emit('load');
  }

  onFavoriChange() {
    this.localValue.favori_mi = !this.localValue.favori_mi;
    this.$http.put("/api/v1/not/" + this.localValue.id, this.localValue).then(() => {
      this.load();
    });
  }
}
