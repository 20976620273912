
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import AdresForm from "@/components/forms/AdresForm.vue";
import TcKimlikNumarasi from "@/components/inputs/TcKimlikNumarasi.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import {IstihkakEntity} from "@/entity/Haciz/IstihkakEntity";

@Component({
  components: {
    FormWrapper,
    AdresForm,
    TcKimlikNumarasi,
    Tutar
  }
})
export default class IstihkakForm extends Mixins(ObjectInputMixin) {
  @Prop({}) successOnValidate !: any;
  localValue!: IstihkakEntity;
}
