import {BorcluEntity} from "@/entity/BorcluEntity";
import {AlacakliEntity} from "@/entity/AlacakliEntity";

export class NotEntity {
    id!: number;
    alacakli!: AlacakliEntity;
    updated_at!:Date
    alacakli_id!: number;
    borclu!: BorcluEntity;
    borclu_id!: number;
    ekleyen_ad_soyad: string = "";
    ekleyen_id!: number;
    not!: string | null;
    not_tur_id!: number;
    takip_id!: number;
    favori_mi: boolean = false;
}

