
import {Component, Mixins} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {
    Dates,
  }
})
export default class HisseTuruPicker extends Mixins(SimpleInputMixin) {
  items: any[] = [];

  async getHisseTurleri() {
    let response: any = await this.$http.get("/api/v1/hisse-turu");
    this.items = response.data;
  }

  async mounted() {
    await this.getHisseTurleri();
  }
};
