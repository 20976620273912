
import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import TasinmazEkleForm from "@/components/forms/haciz/TasinmazEkleForm.vue";
import {TasinmazEntity} from "@/entity/Haciz/TasinmazEntity";


@Component({
  components: {TasinmazEkleForm, FormDialog, DeleteDialog}
})
export default class TasinmazBilgileriList extends Mixins(SimpleInputMixin) {
  @Prop({}) successOnValidate !: any;
  headers = [
    {text: "İli", value: "ili"},
    {text: "İlçesi", value: "ilcesi"},
    {text: "Köy/Mahalle", value: "koy_mah"},
    {text: "Parsel", value: "parsel"},
    {text: "İşlemler", value: "actions", width: 100, sortable: false, align: 'center'},
  ];
  localValue!: Array<TasinmazEntity>;

  mounted() {
    if (!this.value) {
      this.localValue = [];
    }
  }

  onAdd(item: TasinmazEntity) {
    this.localValue.push(item);
    this.input();
  }

  onUpdate(item: TasinmazEntity, index: number) {
    let newArray = [...this.localValue];
    newArray[index] = item;
    this.localValue = newArray;
    this.input();
  }

  onDelete(index: number) {
    this.localValue.splice(index, 1);
    this.input();
  }
}
