
import {Prop, Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import {ItirazinIptaliDavasiEntity} from "@/entity/ItirazinIptaliDavasiEntity";
import Dates from "@/components/inputs/Dates.vue";
import {DavaSonucu} from "@/enum/DavaSonucu";
import DavaSonucuPicker from "@/components/pickers/DavaSonucuPicker.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import DosyaEsasNumarasi from "@/components/inputs/DosyaEsasNumarasi.vue";
import AlacakPicker from "@/components/pickers/AlacakPicker.vue";
import ItirazinIptaliAlacakKalemiList from "@/components/lists/alacak-kalemi/ItirazinIptaliAlacakKalemiList.vue";

@Component({
  components: {
    ItirazinIptaliAlacakKalemiList,
    AlacakPicker, DosyaEsasNumarasi, Tutar, DavaSonucuPicker, Dates, FormWrapper
  }
})
export default class ItirazinIptaliDavasiForm extends Mixins(ObjectInputMixin) {
  @Prop() takipId!: number;
  @Prop() takipTipiId!: number;

  localValue!: ItirazinIptaliDavasiEntity;
  davaSonucu = DavaSonucu;
}
