export default class BorcluData {
    borcluAdi: string = "";
    isTereke: boolean = false;
    uniqueDataText: string = "";
    uniqueData: string = "";
    adres: string="";
    vekilIsimler: string[] =[];
    vekilAdres: string = "";
    // @ts-ignore
    checks:{
        tebligTarihi:any,
        malBeyani_check:boolean,
        bilaTarihi:any,
        itirazVar_check:boolean,
        ikinciTebligat_check:boolean,
        taahKabulAyVar_check:boolean,
        kabulTarihi:any,
    } = {};
}