
import {Component, Prop, Vue} from "vue-property-decorator";
import {ISgkHacizSonuc} from "@/plugins/uyap-plugin/uyap/BorcluSgkHaciz";

@Component({
  components: {}
})

export default class BorcluSgkHacizPicker extends Vue {
  @Prop({required: true}) dosyaId!: string;
  @Prop({required: true}) kisiKurumId!: number;
  @Prop() showUyapIcon!: string | boolean;
  @Prop() tooltip!: string;

  items: Array<ISgkHacizSonuc> = [];

  get isShowUyapIcon() {
    return this.showUyapIcon || this.showUyapIcon === "";
  }

  created() {
    this.load();
  }

  async load() {
    let items = await this.$uyap.BorcluSgkHaciz().run({dosyaId: this.dosyaId, kisiKurumId: this.kisiKurumId});
    if (items.sorguSonucu && items.sorguSonucDVO)
      this.items = items.sorguSonucDVO;
  }
}
