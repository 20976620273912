import {AdresEntity} from "@/entity/AdresEntity";
import {IletisimBilgisiEntity} from "@/entity/IletisimBilgisiEntity";
import {IlEntity} from "@/entity/IlEntity";

export class BorcluVekiliEntity {
    id!: number;
    ad!: string;
    soyad!: string;
    tc_kimlik_no!: number;
    baro_numarasi!: number;
    il_id!: number;
    teblig_tarihi!: Date;
    adres_id!: number;
    borclu_id!: number;
    adres!: AdresEntity;
    iletisim_bilgileri: Array<IletisimBilgisiEntity> = [];
}