
import {Component, Mixins, Prop} from "vue-property-decorator";
import PickerInputMixin from "@/mixins/PickerInputMixin.vue";
import MahkemeForm from "@/components/forms/MahkemeForm.vue";
import {MahkemeEntity} from "@/entity/MahkemeEntity";

@Component({
  components: {MahkemeForm},
})
export default class MahkemeSecim extends Mixins(PickerInputMixin) {
  @Prop() hideDetails!: string | boolean;
  @Prop({default: null}) item!: MahkemeEntity;
  @Prop({type: Object}) defaultValues!: any;

  dialog = false;
  items!: Array<MahkemeEntity>;

  get isHideDetails() {
    return this.hideDetails || this.hideDetails === "";
  }

  mounted() {
    this.load();
  }

  async load() {
    await this.$http.get('/api/v1/mahkeme/filter', {params: {aktif: 1}}).then(response => {
      this.items = response.data;
    })
  }

  onSelectedItemChanged() {
    this.selectedId = this.value;
    this.$emit('mahkemeData', this.selectedItem);
    this.input();
  }

  async saveMahkeme(eklenenMahkeme: any) {
    await this.load();
    this.dialog = false;
    this.$emit('mahkemeData', eklenenMahkeme.mahkeme);
    this.selectedItem = eklenenMahkeme.mahkeme;
    this.selectedId = eklenenMahkeme.mahkeme.id;
    this.input();
  }
}
