
import {Component, Prop, Vue} from "vue-property-decorator";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import {BorcluEntity} from "@/entity/BorcluEntity";
import NotList from "@/components/lists/NotList.vue";
import {NotTuru} from "@/enum/NotTuru";
import HacizTalepForm from "@/components/forms/haciz/HacizTalepForm.vue";
import {HacizTalebiEntity} from "@/entity/Haciz/HacizTalebiEntity";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";

@Component({
  components: {DeleteDialog, HacizTalepForm, NotList, FormDialog}
})
export default class HacizTalepList extends Vue {
  @Prop() borclu!: BorcluEntity;

  notTuru = NotTuru;

  headers = [
    {text: "Türler", align: "start", value: "haciz_turleri"},
    {text: "Tarih", align: "start", value: "haciz_tarihi"},
    {text: "Talep Tutarı", align: "start", value: "talep_tutari"},
    {text: "İşlemler", value: "actions", width: 150, sortable: false, align: 'center'},
  ];

  get defaultHacizTalebi() {
    let hacizTalepObj = new HacizTalebiEntity();
    return hacizTalepObj;
  }

  load() {
    this.$emit('load');
  }
}
