

import {Component, Mixins, Prop, Watch} from "vue-property-decorator";

import EnumPicker from "@/components/pickers/EnumPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {IlamliTebligatBelgeTuru, IlamsizTebligatBelgeTuru, TebligatBelgeTuruLabel} from "@/enum/TebligatBelgeTuru";

@Component({
  components: {EnumPicker}
})
export default class BelgeTuruPicker extends Mixins(SimpleInputMixin) {
  @Prop() ilamli !: any;
  @Prop() defaultFirst !: any;
  turler: any = null;
  turlerLabel = TebligatBelgeTuruLabel;

  @Watch("ilamli")
  onIlamliChange() {
    this.load();
  }

  mounted() {
    this.load();
  }

  load() {
    if (this.ilamli) {
      this.turler = IlamliTebligatBelgeTuru;
    } else if (this.ilamli === false) {
      this.turler = IlamsizTebligatBelgeTuru;
    }
  }

}
