
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import Dates from "../../inputs/Dates.vue";
import {IhaleAlicisi} from "@/enum/IhaleAlicisi";
import IhaleAlicisiPicker from "@/components/pickers/IhaleAlicisiPicker.vue";
import KabulRetPicker from "@/components/pickers/KabulRetPicker.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import SatisYapilamamaSebebiPicker from "@/components/pickers/SatisYapilamamaSebebiPicker.vue";
import {SatisYapilamamaSebebi} from "@/enum/SatisYapilamamaSebebi";
import MahkemeSecim from "@/components/inputs/MahkemeSecim.vue";
import {SatisTuru} from "@/enum/haciz/SatisTuru";
import Times from "@/components/inputs/Times.vue";


@Component({
  components: {
    Times,
    MahkemeSecim,
    SatisYapilamamaSebebiPicker,
    KabulRetPicker, IhaleAlicisiPicker, Dates, Tutar, FormDialog
  }
})
export default class IkinciSatisForm extends Mixins(ObjectInputMixin) {
  @Prop() birinci_satis_tarihi !: string;
  @Prop() kiymeti!: number | null;

  SatisTuru = SatisTuru;
  IhaleAlicisi = IhaleAlicisi;
  SatisYapilamamaSebebi = SatisYapilamamaSebebi;

  @Watch('birinci_satis_tarihi')
  checkBirinciSatisTarihi() {
    if (this.localValue.baslangic_tarihi) {
      if (this.birinci_satis_tarihi) {
        let fark = this.$helper.tarihFarkiGunSayisi(new Date(this.birinci_satis_tarihi), new Date(this.localValue.baslangic_tarihi));
        if (fark <= 0) {
          this.localValue.baslangic_tarihi = null;
        }
      }
    }
  }

  // onSatisMasrafiChange() {
  //   if (this.kiymeti)
  //     this.localValue.muhammen_bedel = (this.kiymeti / 2) + this.localValue.satis_masrafi;
  //   this.input()
  // }

  mounted() {
    this.load();
  }

  load() {
    this.$emit("load");
  }

  @Watch('localValue.satis_yapildi_mi')
  onSatisYapildiMiChange() {
    if (this.localValue.satis_yapildi_mi) {
      this.localValue.satis_yapilamama_sebebi = null;
      this.localValue.manuel_satis_yapilamama_sebebi = null;
    }
  }

  ihaleBedeliCheck() {
    if (this.localValue.baslangic_tarihi) {
      let fark = this.$helper.tarihFarkiGunSayisi(new Date(this.localValue.baslangic_tarihi), new Date());
      if (this.localValue.satis_yapildi_mi && !this.localValue.ihale_bedeli_yatirildi_mi && fark >= 7) {
        return true;
      }
    }
    return false;
  }
}
