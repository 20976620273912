
import {Component, Mixins, Prop} from "vue-property-decorator";
import PickerInputMixin from "@/mixins/PickerInputMixin.vue";
import {AxiosResponse} from "axios";
import {AlacakTuruEntity} from "@/entity/AlacakTuruEntity";

@Component({})
export default class AlacakTuruPicker extends Mixins(PickerInputMixin) {
  @Prop({required: true}) alacakBelgeTuruId!: number;
  @Prop({required: true}) takipTipiId!: number;
  @Prop({}) rules!: string;

  mounted() {
    this.load();
  }

  load() {
    this.$http.get(`/api/v1/takip-tipi/${this.takipTipiId}/alacak-belge-turu/${this.alacakBelgeTuruId}/alacak-kalemi-turleri`)
              .then((response: AxiosResponse<AlacakTuruEntity[]>) => (this.items = response.data));
  }
}
