

import {Component, Mixins} from "vue-property-decorator";
import EnumPicker from "@/components/pickers/EnumPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {KabulRet, KabulRetLabel} from "@/enum/KabulRet";

@Component({
  components: {EnumPicker}
})
export default class KabulRetPicker extends Mixins(SimpleInputMixin) {
  kabulretlabel = KabulRetLabel;
  kabulret = KabulRet;
}
