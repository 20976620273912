
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {TakipEntity} from "@/entity/TakipEntity";
import {BankaHesabiSahibiLabel} from "@/enum/BankaHesabiSahibi";

import TakipTalebiGenerator from "@/classes/evrak/TakipTalebi/TakipTalebiGenerator";
import FormWrapper from "@/components/FormWrapper.vue";
import Evrak from "@/classes/evrak/Evrak";

@Component({
  components: {FormWrapper},
})
export default class TahliyeEmri56Taslak extends Vue {
  @Prop() takip!: TakipEntity;
  @Prop() isKartNoVisible!: boolean;

  ttEvrak: Evrak = new Evrak();
  bankaHesabiSahibiLabel = BankaHesabiSahibiLabel;

  mounted() {
    if (this.takip) {
      this.onTakipChange();
    }
  }

  @Watch("takip")
  onTakipChange() {
    let takipTalebiGenerator = new TakipTalebiGenerator()
    takipTalebiGenerator.setTakip(this.takip);
    this.ttEvrak = takipTalebiGenerator.getTakipTalebiEvrak();
  }
}
