

import {Component, Mixins, Prop} from "vue-property-decorator";
import EnumPicker from "@/components/pickers/EnumPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {TebligatDurumu, TebligatDurumuLabel} from "@/enum/TebligatDurumu";

@Component({
  components: {EnumPicker}
})
export default class TebligatDurumuPicker extends Mixins(SimpleInputMixin) {
  @Prop() disabled!: boolean | string;
  @Prop() rules!: string;

  turler = TebligatDurumu;
  turlerLabel = TebligatDurumuLabel;

  get isDisabled(){
    return this.disabled || this.disabled === "";
  }
}
