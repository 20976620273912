
import {Component, Mixins} from 'vue-property-decorator';
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import SatisForm from "@/components/forms/satis/SatisForm.vue";
import {SatisTalebiEntity} from "@/entity/SatisTalebiEntity";
import {SatisYapilamamaSebebi} from "@/enum/SatisYapilamamaSebebi";

@Component({
  components: {SatisForm, FormWrapper}
})
export default class SatisTalebiForm extends Mixins(ObjectInputMixin) {
  localValue!: SatisTalebiEntity;

  get ikinciSatisGosterme() {
    if (this.localValue.birinci_satis.satis_yapilamama_sebebi && this.localValue.birinci_satis.satis_yapildi_mi === false) {
      return this.localValue.birinci_satis.satis_yapilamama_sebebi === SatisYapilamamaSebebi.alici_istekli_cikmadigindan;
    }
  }
}
