import {TakipEntity} from "@/entity/TakipEntity";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import {AlacakEntity} from "@/entity/AlacakBelgeler/AlacakEntity";
import {AlacakBelgeTuru} from "@/enum/AlacakBelgeTuru";
import TakipTalebiDataProvider from "@/classes/evrak/TakipTalebi/TakipTalebiDataProvider";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {AlacakKalemiTuru} from "@/enum/AlacakKalemiTuru";
import {AlacakliEntity} from "@/entity/AlacakliEntity";
import {BorcluEntity} from "@/entity/BorcluEntity";
import {TasinirEntity} from "@/entity/AlacakBelgeler/TasinirEntity";
import {AlacakKalemiAlani} from "@/enum/AlacakKalemiAlani";
import Evrak from "@/classes/evrak/Evrak";
import {EvrakMaddeler} from "@/enum/EvrakMaddeler";
import {TasinmazEntity} from "@/entity/Haciz/TasinmazEntity";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";

export default class TakipTalebiGenerator {
    private takip!: TakipEntity;
    private takipTalebiEvrak!: Evrak;
    private takipTalebiDataProvider: TakipTalebiDataProvider = TakipTalebiDataProvider.getInstance();
    AlacakKalemiAlani = AlacakKalemiAlani;

    setTakip(takip: TakipEntity) {
        this.takip = takip;
        this.generateTakipTalebiEvrak();
    }

    getTakipTalebiEvrak(): Evrak {
        if (this.takipTalebiEvrak) {
            return this.takipTalebiEvrak;
        }
        return new Evrak();
    }

    private generateTakipTalebiEvrak() {
        this.takipTalebiEvrak = new Evrak();
        this.takipTalebiEvrak.dosyaEsasNo = this.takip.dosya_esas_no;
        this.takipTalebiEvrak.kartNo = this.takip.id + "";
        this.takipTalebiEvrak.icraDairesi = this.takip.icra_dairesi?.isim;
        this.takipTalebiEvrak.bankaAdi = this.takip.icra_dairesi?.banka_hesabi?.banka.isim ? this.takip.icra_dairesi?.banka_hesabi?.banka.isim : "" + "";
        this.takipTalebiEvrak.ibanNo = this.takip.icra_dairesi?.banka_hesabi?.iban ? this.takip.icra_dairesi?.banka_hesabi?.iban : "" + "";
        this.takipTalebiEvrak.takipTarihi = this.takip.takip_tarihi;

        this.takip.alacaklilar.forEach((alacakli: AlacakliEntity) => {
            this.setAlacakliData(alacakli);
        });
        this.takip.borclular.forEach((borclu: BorcluEntity) => {
            this.setBorcluData(borclu);
        });

        this.setTakipData(this.takip);
        this.takip.talepler.forEach((takipTalebi: TakipTalebiEntity) => {
            this.setTakipTalebiData(takipTalebi);

            takipTalebi.alacaklar.forEach((alacak: AlacakEntity) => {
                this.setAlacakData(alacak);

                alacak.alacak_kalemleri.forEach((alacakKalemi: AlacakKalemiEntity) => {
                    this.setAlacakKalemiData(alacakKalemi);
                    if (alacakKalemi.alacak_kalemi_alani_id == this.AlacakKalemiAlani.takipOncesiFaiz) {
                        this.setFaizData(alacakKalemi);
                    }

                    if (alacakKalemi.alacak_kalemi_alani.takip_oncesi_mi) {
                        if (!this.takipTalebiEvrak.AlacakKalemleriByParaBirimi.has(alacakKalemi.para_birimi_id)) {
                            this.takipTalebiEvrak.AlacakKalemleriByParaBirimi.set(alacakKalemi.para_birimi_id, [])
                        }
                        let alacakKalemleri = this.takipTalebiEvrak.AlacakKalemleriByParaBirimi.get(alacakKalemi.para_birimi_id)
                        if(alacakKalemleri){
                            alacakKalemleri.push(alacakKalemi)
                        }
                    }
                    //this.setAlacakKalemleriByParaBirimi(alacakKalemi);
                });

                alacak.tasinirlar.forEach((tasinir: TasinirEntity) => {
                    this.setRehinData(tasinir);
                });

                alacak.tasinmazlar.forEach((tasinmaz: TasinmazEntity) => {
                    this.setRehinData(tasinmaz);
                });
            });
        });
    }

    private setAlacakliData(alacakli: AlacakliEntity) {
        this.setDataByTakipTalebiMadde(EvrakMaddeler.AlacakliData, this.takipTalebiDataProvider.getAciklama(EvrakMaddeler.AlacakliData, null, alacakli));
    }

    private setTakipTalebiData(takipTalebi: TakipTalebiEntity) {
        this.setDataByTakipTalebiMadde(EvrakMaddeler.TakipYoluData, this.takipTalebiDataProvider.getAciklama(EvrakMaddeler.TakipYoluData, null, takipTalebi));
    }

    private setBorcluData(borclu: BorcluEntity) {
        if (borclu.tereke_mi) {
            this.setDataByTakipTalebiMadde(EvrakMaddeler.TerekeData, this.takipTalebiDataProvider.getAciklama(EvrakMaddeler.TerekeData, null, borclu));
        } else {
            this.setDataByTakipTalebiMadde(EvrakMaddeler.BorcluData, this.takipTalebiDataProvider.getAciklama(EvrakMaddeler.BorcluData, null, borclu));
        }
    }

    private setTakipData(takip: TakipEntity) {

        let tutarVeAciklama = this.takipTalebiDataProvider.getAlacakKalemiTutarVeAciklama(takip);
        this.takipTalebiEvrak.TutarVeAciklama=tutarVeAciklama;

        let alacakToplami = this.takipTalebiDataProvider.getAlacaklarGenelToplam(takip);
        this.takipTalebiEvrak.AlacakToplami=alacakToplami;

        let dovizDetay = this.takipTalebiDataProvider.getDovizDetayToplam(takip);
        this.takipTalebiEvrak.DovizDetayi=dovizDetay;

        let tahsilat = this.takipTalebiDataProvider.getTakipOncesiTahsilat(takip);
        this.takipTalebiEvrak.TakipOncesiTahsilat=tahsilat;

        let alacaklininTalepEttigiHak=this.takipTalebiDataProvider.AlacaklininTalepEttigiHak(takip)
        this.takipTalebiEvrak.AlacaklininTalepEttigiHak=alacaklininTalepEttigiHak;

        let kesideciMetni=this.takipTalebiDataProvider.KesideciMetni(takip)
        this.takipTalebiEvrak.KesideciMetni=kesideciMetni;
    }

    private setRehinData(rehin: TasinirEntity | TasinmazEntity) {
        this.setDataByTakipTalebiMadde(EvrakMaddeler.RehinData, this.takipTalebiDataProvider.getAciklama(EvrakMaddeler.RehinData, null, rehin));
        this.setDataByTakipTalebiMadde(EvrakMaddeler.MalikData, this.takipTalebiDataProvider.getAciklama(EvrakMaddeler.MalikData, null, rehin));
    }

    private setFaizData(alacakKalemi: AlacakKalemiEntity) {
        this.setDataByTakipTalebiMadde(EvrakMaddeler.FaizData, this.takipTalebiDataProvider.getAciklama(EvrakMaddeler.FaizData, null, alacakKalemi));
    }


    private setAlacakData(alacak: AlacakEntity) {

        switch (alacak.alacak_belge_turu_id) {
            case AlacakBelgeTuru.tahliyeTaahhudu: {
                this.setDataByTakipTalebiMadde(EvrakMaddeler.TahliyeTaahhuduData, this.takipTalebiDataProvider.getAciklama(EvrakMaddeler.TahliyeTaahhuduData, null, alacak));
                break;
            }
        }

        this.setDataByTakipTalebiMadde(EvrakMaddeler.AlacakData, this.takipTalebiDataProvider.getAciklama(EvrakMaddeler.AlacakData, null, alacak));

        if (!!alacak.ilam_tipi) {
            this.setDataByTakipTalebiMadde(EvrakMaddeler.IlamData, this.takipTalebiDataProvider.getAciklama(EvrakMaddeler.IlamData, null, alacak));
            this.setDataByTakipTalebiMadde(EvrakMaddeler.IlamOzetiData, this.takipTalebiDataProvider.getAciklama(EvrakMaddeler.IlamOzetiData, null, alacak));
        }

    }

    private setAlacakKalemiData(alacakKalemi: AlacakKalemiEntity) {
        if (alacakKalemi.alacak_kalemi_turu_id == AlacakKalemiTuru.kiraAlacagi) {
            this.setDataByTakipTalebiMadde(EvrakMaddeler.KiraKontratiData, this.takipTalebiDataProvider.getAciklama(EvrakMaddeler.KiraKontratiData,null, alacakKalemi));
        }
        if (alacakKalemi.alacak_kalemi_alani_id == AlacakKalemiAlani.takipOncesiFaiz) {
            this.setDataByTakipTalebiMadde(EvrakMaddeler.AlacakKalemiData, this.takipTalebiDataProvider.getAciklama(EvrakMaddeler.AlacakKalemiData, null, alacakKalemi));
        }
    }

    setDataByTakipTalebiMadde(takipTalebiMadde: EvrakMaddeler, data: any) {
        if (data) {
            switch (takipTalebiMadde) {
                case EvrakMaddeler.AlacakliData: {
                    this.takipTalebiEvrak.AlacakliData.push(data)
                    break;
                }
                case EvrakMaddeler.BorcluData: {
                    this.takipTalebiEvrak.BorcluData.push(data)
                    break;
                }
                case EvrakMaddeler.TerekeData: {
                    this.takipTalebiEvrak.TerekeData.push(data)
                    break;
                }
                case EvrakMaddeler.AlacakKalemiData: {
                    this.takipTalebiEvrak.AlacakKalemiData.push(data)
                    break;
                }
                case EvrakMaddeler.RehinData: {
                    this.takipTalebiEvrak.RehinData.push(data)
                    break;
                }
                case EvrakMaddeler.IlamData: {
                    this.takipTalebiEvrak.IlamData.push(data)
                    break;
                }
                case EvrakMaddeler.KiraKontratiData: {
                    this.takipTalebiEvrak.KiraKontratiData.push(data)
                    break;
                }
                case EvrakMaddeler.TahliyeTaahhuduData: {
                    this.takipTalebiEvrak.TahliyeTaahhuduData.push(data)
                    break;
                }
                case EvrakMaddeler.AlacakData: {
                    this.takipTalebiEvrak.AlacakData.push(data)
                    break;
                }
                case EvrakMaddeler.TakipYoluData: {
                    this.takipTalebiEvrak.TakipYoluData.push(data)
                    break;
                }
                case EvrakMaddeler.MalikData: {
                    this.takipTalebiEvrak.MalikData.push(data)
                    break;
                }
                case EvrakMaddeler.IlamOzetiData: {
                    this.takipTalebiEvrak.IlamOzetiData.push(data)
                    break;
                }
                case EvrakMaddeler.FaizData: {
                    this.takipTalebiEvrak.FaizData.push(data)
                    break;
                }
            }
        }

    }
}

