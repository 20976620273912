
import {Component, Mixins, Prop} from "vue-property-decorator";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {DatabasePicker},
})
export default class DosyaTuruPicker extends Mixins(SimpleInputMixin) {
  @Prop() returnId!: boolean;
  @Prop() ilam!: number;
  @Prop() color: any;
  @Prop() required!: boolean;
  @Prop() rules!: string;

  get action(): string {
    if (this.ilam)
      return "/api/v1/dosya-turu/filter?ilamTuru=" + this.ilam;
    else
      return "/api/v1/dosya-turu";
  }

  returnObject(e: any) {
    this.$emit('return-object', e)
  }
}
