
import {Component, Prop, Mixins, Watch, Ref} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import {NotEntity} from "@/entity/NotEntity";
import NotTurPicker from "@/components/pickers/NotTurPicker.vue";
import NotEditor from "@/components/inputs/NotEditor.vue";
import NotInfo from "@/components/infos/NotInfo.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {NotTuru} from "@/enum/NotTuru";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import NotForm from "@/components/forms/NotForm.vue";

@Component({
  components: {NotForm, FormDialog, FormWrapper, NotInfo, NotEditor, NotTurPicker}
})

export default class NotList extends Mixins(SimpleInputMixin) {
  @Prop() defaultType!: number;
  @Prop() defaultBorclu!: number;
  @Prop({required: true}) takipId!: number;
  @Prop() hideCloseButton!: boolean | string;
  @Prop() hideElevation!: boolean | string;
  @Ref('notForm') readonly notForm!: NotForm;

  localValue!: Array<NotEntity>;
  cloneValue: Array<NotEntity> = []
  selectedType: Array<number> = [];
  notTuru = NotTuru;

  get defaultNot(){
    let notObject=new NotEntity();
    notObject.not='<p></p>'
    return notObject;
  }

  get isHideClose() {
    return (this.hideCloseButton || this.hideCloseButton === "");
  }

  get isHideElevation() {
    return this.hideElevation || this.hideElevation === "";
  }

  onValueChange() {
    this.cloneValue = [...this.localValue];
    this.sortByDate();
  }

  @Watch('selectedType')
  onSelectedTypeChange() {
    this.sortByDate();
  }

  onTakipBorclulariChange() {
    this.notForm?.onTakipBorclulariChange();
  }

  onTakipAlacaklilariChange() {
    this.notForm?.onTakipAlacaklilariChange();
  }

  mounted() {
    if (this.defaultType)
      this.selectedType.push(this.defaultType);
    this.sortByDate();
  }

  sortByDate() {
    if (this.localValue) {
      this.cloneValue = [...this.localValue].filter((not: NotEntity) => (not.not_tur_id != NotTuru.ozel || (not.not_tur_id == NotTuru.ozel && not.ekleyen_id == this.$store.state.user.id)));
      if (this.selectedType.length > 0) {
        this.cloneValue = this.localValue.filter((not) => {
          return this.selectedType.indexOf(not.not_tur_id) != -1;
        });
      }
      this.cloneValue.sort((a: NotEntity, b: NotEntity) => {
        return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
      }).reverse();
      let favori = this.cloneValue.filter((not) => {
        return not.favori_mi;
      });
      let favoriOlmayan = this.cloneValue.filter((not) => {
        return !not.favori_mi;
      });
      this.cloneValue = favori.concat(favoriOlmayan);
    }
  }

  load() {
    this.$emit('load');
  }

  isAuthorized(keySuffix: string) {
    let ap = this.$store.state.activeProcesses;
    let authKey = null;
    if (keySuffix[0] !== '-') {
      keySuffix = '-' + keySuffix;
    }
    if (ap.filter((item: any) => item.key === "takip-ac").length > 0) {
      authKey = "takip-ac";
    } else if (ap.filter((item: any) => item.key === "takip-duzenle").length > 0) {
      authKey = 'takip-not' + keySuffix;
    }
    if (!authKey) {
      return false;
    }
    return this.$helper.isAuthorized(authKey)
  }
}
