

import {Component, Mixins} from "vue-property-decorator";
import EnumPicker from "@/components/pickers/EnumPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {IhaleAlicisi, IhaleAlicisiLabel} from "@/enum/IhaleAlicisi";

@Component({
  components: {EnumPicker}
})
export default class IhaleAlicisiPicker extends Mixins(SimpleInputMixin) {
  alicilar = IhaleAlicisi;
  alicilarLabel = IhaleAlicisiLabel;
}
