export enum TebligatDurumu
{
    gonderildi="gonderildi",
    teblig_edildi="teblig_edildi",
    bila_oldu="bila_oldu",

}
export enum TebligatDurumuLabel
{
    gonderildi="Gönderildi",
    teblig_edildi="Tebliğ Edildi",
    bila_oldu="Bila Oldu",

}
