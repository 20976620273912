
import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import MuzekkereAdresBilgileriForm from "@/components/forms/haciz/MuzekkereAdresBilgileriForm.vue";
import {MuzekkereAdresBilgileriEntity} from "@/entity/Haciz/MuzekkereAdresBilgileriEntity";

@Component({
  components: {MuzekkereAdresBilgileriForm, FormDialog, DeleteDialog}
})
export default class MuzekkereAdresBilgileriList extends Mixins(SimpleInputMixin) {
  @Prop({}) successOnValidate !: any;
  headers = [
    {text: "Müzekkere Kişi/Kurum", value: "isim"},
    {text: "İşlemler", value: "actions", width: 100, sortable: false, align: 'center'},
  ];
  localValue!: Array<MuzekkereAdresBilgileriEntity>;

  mounted(){
    if(!this.value) {
      this.localValue = [];
    }
  }
  onAdd(item: MuzekkereAdresBilgileriEntity) {
    this.localValue.push(item);
    this.input();
  }

  onUpdate(item: MuzekkereAdresBilgileriEntity, index: number) {
    let newArray = [...this.localValue];
    newArray[index] = item;
    this.localValue = newArray;
    this.input();
  }

  onDelete(index: number) {
    this.localValue.splice(index, 1);
    this.input();
  }
}
