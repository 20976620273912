
import { Component, Mixins } from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import TahsilatTuruPicker from "@/components/pickers/TahsilatTuruPicker.vue";

@Component({
  components: {TahsilatTuruPicker, FormWrapper },
})
export default class AltTahsilatTuruForm extends Mixins(ObjectInputMixin) {}
