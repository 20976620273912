
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import YedieminInfo from "@/components/infos/haciz/YedieminInfo.vue";
import IstihkakList from "@/components/lists/haciz/IstihkakList.vue";
import NoterPicker from "@/components/pickers/NoterPicker.vue";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import EnabledSwitch from "@/components/inputs/EnabledSwitch.vue";
import Dates from "@/components/inputs/Dates.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import Plaka from "@/components/inputs/Plaka.vue";
import TebligatList from "@/components/anakart/tebligat/TebligatList.vue";
import KiymetTakdiriForm from "@/components/forms/KiymetTakdiriForm.vue";
import DosyaBorcluEgmPicker from "@/components/pickers/uyap/DosyaBorcluEgmPicker.vue";
import HacizSerhiPicker from "@/components/pickers/uyap/HacizSerhiPicker.vue";
import {AvukatTalepListCevapRow} from "@/plugins/uyap-plugin/uyap/AvukatTalepList";
import YevmiyeNumarasi from "@/components/inputs/YevmiyeNumarasi.vue";

@Component({
  components: {
    YevmiyeNumarasi,
    HacizSerhiPicker,
    DosyaBorcluEgmPicker,
    KiymetTakdiriForm,
    TebligatList,
    FormDialog,
    DeleteDialog,
    YedieminInfo,
    IstihkakList,
    NoterPicker,
    ParaBirimiPicker,
    EnabledSwitch,
    Dates,
    Plaka,
    Tutar,
  }
})
export default class AracHacziForm extends Mixins(ObjectInputMixin) {
  @Prop() kisiKurumId!: number;
  @Prop() dosyaId!: number;
  @Prop() avukatTalepList!: Array<AvukatTalepListCevapRow>;

  mounted() {
    if ((!this.localValue.uyap_data || this.localValue.uyap_data.talepKodu != this.localValue.haciz_turu.talep_kodu) && this.$store.getters.isUyapConnected) {
      let talep = this.avukatTalepList.find(value => value.talepKodu == this.localValue.haciz_turu.talep_kodu);
      this.localValue.uyap_data = {...talep};
      this.localValue.uyap_data.sorguId = 0;
      this.input();
    }
  }
}
