

import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {IBorcluTakbisSorgulamaSonuc} from "@/plugins/uyap-plugin/uyap/BorcluTakbisSorgulama";

@Component({
  components: {}
})

export default class BorcluTakbisPicker extends Mixins(SimpleInputMixin) {
  @Prop({required: true}) dosyaId!: string;
  @Prop({required: true}) kisiKurumId!: number;
  @Prop() rules!: string;
  @Prop() showUyapIcon!: string | boolean;
  @Prop() tooltip!: string;

  items: Array<IBorcluTakbisSorgulamaSonuc> = [];

  get isShowUyapIcon() {
    return this.showUyapIcon || this.showUyapIcon === "";
  }

  onTakbisChange(v: IBorcluTakbisSorgulamaSonuc) {
    this.$emit('takbisData', this.items.find(item => item.tasinmazDVO.tasinmazId == this.localValue));
    this.input();
  }

  created() {
    this.load();
  }

  async load() {
    let items = (await this.$uyap.BorcluTakbisSorgulama().run({
      dosyaId: this.dosyaId,
      kisiKurumId: this.kisiKurumId,
    })).sorguSonucDVO;
    if (items && typeof items != "string")
      this.items = items;
  }
}
