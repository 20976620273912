
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import {TakipEntity} from "@/entity/TakipEntity";
import TakipTalebiTaslak from "@/components/TakipTalebiTaslak.vue";
import Emirler from "@/components/Emirler.vue";
import MasrafList from "@/components/lists/MasrafList.vue";
import HarcList from "@/components/lists/HarcList.vue";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import {AlacakEntity} from "@/entity/AlacakBelgeler/AlacakEntity";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {AlacakKalemiAlani} from "@/enum/AlacakKalemiAlani";

@Component({
  components: {HarcList, MasrafList, Emirler, TakipTalebiTaslak, FormWrapper}
})

export default class MasrafHarc extends Vue {
  @Prop() takip!: TakipEntity;
  @Prop() tab !: number;
  @Prop() takipSonrasiMasraf !: boolean;
  isKartNoVisible: boolean = true;
  AlacakKalemiAlani = AlacakKalemiAlani;


  get harclar() {
    let harclar:any = [];
    if (this.takip) {
      this.takip.talepler.forEach((takipTalebi: TakipTalebiEntity) => {
        takipTalebi.alacaklar.forEach((alacak: AlacakEntity) => {
          alacak.alacak_kalemleri.forEach((alacakKalemi: AlacakKalemiEntity) => {
            if (alacakKalemi.alacak_kalemi_alani.id == this.AlacakKalemiAlani.harc) {
              harclar.push(alacakKalemi);
            }
          });
        });
      });
    }

    harclar.sort((a: AlacakKalemiEntity, b: AlacakKalemiEntity) => {
      return String(a.harc_turu).localeCompare(String(b.harc_turu), 'tr');
    });

    return harclar;
  }

  get masraflar() {
    let masraflar:any = [];
    if (this.takip) {
      this.takip.talepler.forEach((takipTalebi: TakipTalebiEntity) => {
        takipTalebi.alacaklar.forEach((alacak: AlacakEntity) => {
          alacak.alacak_kalemleri.forEach((alacakKalemi: AlacakKalemiEntity) => {
            if (alacakKalemi.alacak_kalemi_alani.id == this.AlacakKalemiAlani.takipsonrasiMasraf || alacakKalemi.alacak_kalemi_alani.id == this.AlacakKalemiAlani.takipOncesiMasraf) {
              masraflar.push(alacakKalemi);
            }
          });
        });
      });
    }
    masraflar.sort((a: AlacakKalemiEntity, b: AlacakKalemiEntity) => {
      return String(a.masraf_cesidi).localeCompare(String(b.masraf_cesidi), 'tr');
    });
    return masraflar;
  }

  load() {
    this.$emit('load');
  }
}
