
import {Component, Mixins, Prop} from "vue-property-decorator";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {DatabasePicker},
})
export default class MahkemePicker extends Mixins(SimpleInputMixin) {
  @Prop() returnId!: boolean;
  @Prop() label!: string;
  @Prop({}) adliye!: any;
  @Prop({}) sehir!: any;
  @Prop({}) yargiBirimi!: any;
  @Prop({}) rules!: string;

  get action(): string {
    return `/api/v1/mahkeme/filter?adliye=${this.adliye}&sehir=${this.sehir}&yargiBirimi=${this.yargiBirimi}`;
  }
}
