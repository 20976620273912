
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import Faizler from "@/components/forms/Faizler.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import Dates from "@/components/inputs/Dates.vue";
import {ParaBirimi} from "@/enum/ParaBirimi";
import {AlacakBelgeTuru} from "@/enum/AlacakBelgeTuru";
import AlacakTuruPicker from "@/components/pickers/AlacakTuruPicker.vue";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {AlacakKalemiTuru} from "@/enum/AlacakKalemiTuru";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import Kur from "@/components/inputs/Kur.vue";
import {FaizTuruEntity} from "@/entity/FaizTuruEntity";

@Component({
  components: {Kur, AlacakTuruPicker, Dates, ParaBirimiPicker, Tutar, Faizler, FormWrapper}
})
export default class ItirazinIptaliAlacakKalemiForm extends Mixins(ObjectInputMixin) {
  @Prop() takipTipiId!: number;
  @Prop() alacakId!: number;

  localValue!: AlacakKalemiEntity;
  paraBirimiTuru = ParaBirimi;
  AlacakBelgeTuru = AlacakBelgeTuru;
  alacakKalemiTuru = AlacakKalemiTuru;
  paraBirimi !: ParaBirimiEntity;

  @Watch("tarih")
  onVadeTarihiChange() {
    this.localValue.faiz_baslangic_tarihi = this.tarih;
  }

  get tarih() {
    if (this.localValue.vade_tarihi) {
      return this.localValue.vade_tarihi;
    } else if (this.localValue.ibraz_tarihi) {
      return this.localValue.ibraz_tarihi;
    }
  }

  get alacakKalemiRules() {
    if (this.localValue.aciklama) {
      if (this.localValue.aciklama.match(/["'<>&]/g) == null)
        return true;
      else
        return `Yasak karakter. ", ', <, >, & karakterleri kullanılamaz.`;
    } else
      return true;
  }

  @Watch("localValue.para_birimi_id")
  onParaBirimiIdChange() {
    this.localValue.para_birimi = this.$helper.tutarStr(this.localValue.para_birimi_id, this.paraBirimi)
  }

  @Watch("aciklama")
  onAciklamaChange() {
    this.localValue.aciklama = this.aciklama;
  }

  get aciklama() {
    if (this.localValue.alacak_kalemi_turu) {
      return this.localValue.alacak_kalemi_turu.isim + " Alacağı";
    }
  }

  async save() {
    if (this.localValue.elle_faiz_oran_mi) {
      this.localValue.takip_oncesi_faiz_turu_id = await this.saveFaizTuru(this.localValue.takip_oncesi_faiz_turu);
      this.localValue.takip_sonrasi_faiz_turu_id = this.localValue.takip_oncesi_faiz_turu_id;
    }
    if (this.localValue.id) {
      this.$http.put('/api/v1/alacak-kalemi/' + this.localValue.id, this.localValue).then(response => {
        this.$emit("onSuccess");
      });
    } else {
      this.localValue.alacak_id = this.alacakId;
      this.localValue.alacak_kalemi_alani_id = 1;
      if (this.localValue.alacak_kalemi_turu)
        this.localValue.alacak_kalemi_turu_id = this.localValue.alacak_kalemi_turu.id;
      this.$http.post('/api/v1/alacak/' + this.alacakId + '/alacak-kalemi', this.localValue).then(response => {
        this.$emit("onSuccess");
      });
    }
  }

  async saveFaizTuru(faizTuru: FaizTuruEntity) {
    let response = null;
    if (faizTuru && faizTuru.id != null) {
      response = await this.$http.put("/api/v1/faiz-turu/" + faizTuru.id, faizTuru);
    } else {
      if (this.$route.params.id) {
        response = await this.$http.post("/api/v1/takip/" + this.$route.params.id + "/faiz-turu/", faizTuru);
      } else {
        response = await this.$http.post("/api/v1/faiz-turu/", faizTuru);
      }
    }
    faizTuru.id = response.data.id;
    await this.saveFaizOranlar(faizTuru);
    return response.data.id;
  }

  async saveFaizOranlar(item: FaizTuruEntity) {
    if (item.faiz_oranlari) {
      for (const faizOran of item.faiz_oranlari) {
        if (faizOran && faizOran.id != null) {
          await this.$http.put("/api/v1/faiz-oran/" + faizOran.id, faizOran);
        } else {
          faizOran.faiz_turu_id = item.id;
          await this.$http.post("/api/v1/faiz-oran/", faizOran);
        }
      }
    }
  }
}
