
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import BorcluSgkHacizPicker from "@/components/pickers/uyap/BorcluSgkHacizPicker.vue";
import {BorcluEntity} from "@/entity/BorcluEntity";
import {AvukatTalepListCevapRow} from "@/plugins/uyap-plugin/uyap/AvukatTalepList";

@Component({
  components: {BorcluSgkHacizPicker}
})
export default class SgkMossipHacizForm extends Mixins(ObjectInputMixin) {
  @Prop() kisiKurumId!: number;
  @Prop() dosyaId!: number;
  @Prop() avukatTalepList!: Array<AvukatTalepListCevapRow>;

  mounted() {
    if ((!this.localValue.uyap_data || this.localValue.uyap_data.talepKodu != this.localValue.haciz_turu.talep_kodu) && this.$store.getters.isUyapConnected) {
      let talep = this.avukatTalepList.find(value => value.talepKodu == this.localValue.haciz_turu.talep_kodu);
      this.localValue.uyap_data = {...talep};
      this.localValue.uyap_data.sorguId = 0;
      this.input();
    }
  }
}
