import {AlacakKalemiTuru} from "@/enum/AlacakKalemiTuru";
import {HesapSekli} from "@/enum/HesapSekli";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import {TakipEntity} from '../TakipEntity';
import {AlacakTuruEntity} from "@/entity/AlacakTuruEntity";
import {AlacakKalemiAlaniEntity} from "@/entity/AlacakKalemiAlaniEntity";
import {FaizTuruEntity} from "@/entity/FaizTuruEntity";
import {FaizOranEntity} from "@/entity/FaizOranEntity";
import IhtiyatiHacizEntity from "@/entity/IhtiyatiHacizEntity";
import {HarcTuruEntity} from "@/entity/HarcTuruEntity";
import {MasrafCesitEntity} from "@/entity/MasrafCesitEntity";
import {AlacakKalemiTuruEntity} from "@/entity/AlacakKalemiTuruEntity";

export default class AlacakKalemiEntity {
    id!: number;
    aciklama!: string|undefined;
    alacak_id!: number;
    alacak_kalemi_alani_id!: number;
    alacak_kalemi_alani!: AlacakKalemiAlaniEntity;
    alacak_kalemi_turu!: AlacakKalemiTuruEntity;
    alacak_kalemi_turu_id!: number;
    baslangic_tarihi!: Date;
    belge_tarihi!: Date;
    bitis_tarihi!: Date;
    borclu_id!: number;
    calculation_type!: any;
    children: Array<AlacakKalemiEntity> = [];
    cocuk_isim!: string;
    elle_faiz_mi!: boolean;
    elle_faiz_oran_mi!: boolean;
    elle_faiz_tutari !: number;
    faiz_baslangic_tarihi!:Date|any;
    faiz_hesap_sekli: HesapSekli = HesapSekli['365_gun'];
    faiz_oran!: number;
    gayri_resmi_mi!: boolean;
    haciz_id!: number;
    harc_orani!: any;
    harc_turu!: HarcTuruEntity;
    harc_turu_id !: number;
    ibraz_tarihi!: Date;
    is_calculated!: boolean;
    is_devam_eden_aylar: boolean = false;
    kasaya_ajandaya_aktar!: boolean;
    kur!: number;
    masraf_belgeli_mi!: boolean;
    masraf_cesidi!: MasrafCesitEntity;
    masraf_cesidi_id!:number;
    masraf_giris_tarihi!: Date;
    masraf_yapan_kisi!: any;
    nafaka_alacak_olan_kisi!: number;
    noter_id!: number;
    para_birimi!: ParaBirimiEntity|any;
    para_birimi_id!: number;
    parent_id!: number;
    satis_id!: number;
    takip_oncesi_tahsil_edilen_tutar!: number;
    takip_sonrasi_tahsil_edilen_tutar!: number;
    tahsil_edilen_tutar!: number;
    tahsil_edilmeyen_tutar!: number;
    takip_oncesi_tahsil_edilmeyen_tutar!: number;
    takip_sonrasi_tahsil_edilmeyen_tutar!: number;
    tahsilat_id!: number;
    tahsilat_tarihi!: Date;
    takip_oncesi_faiz_isle!: boolean;
    takip_oncesi_faiz_turu!: FaizTuruEntity;
    takip_oncesi_faiz_turu_id!: number;
    takip_sonrasi_faiz_isle!: boolean;
    takip_sonrasi_faiz_turu!: FaizTuruEntity;
    takip_sonrasi_faiz_turu_id!: number;
    tarih!: Date|string;
    tebligat_id!: number;
    tutar!: number;
    vade_tarihi!: Date;
}
