export enum SatisYapilamamaSebebi {
    alici_istekli_cikmadigindan = "alici_istekli_cikmadigindan",
    satis_talebinden_vazgecildi = "satis_talebinden_vazgecildi",
    borc_odendi = "borc_odendi",
    diger = "diger"
}

export enum SatisYapilamamaSebebiLabel {
    alici_istekli_cikmadigindan = "Alıcı İstekli Çıkmadığından",
    satis_talebinden_vazgecildi = "Satış Talebinden Vazgeçildi",
    borc_odendi = "Borç Ödendi",
    diger = "Diğer",
}