
import {Component, Vue} from "vue-property-decorator";
import BorcluInfo from "@/components/infos/BorcluInfo.vue";
import IletisimList from "@/components/lists/IletisimList.vue";
import AdresSelectableList from "@/components/lists/AdresSelectableList.vue";
import BankaHesabiList from "@/components/lists/BankaHesabiList.vue";
import KimlikBilgileriList from "@/components/lists/KimlikBilgileriList.vue";
import BorcluVekilList from "@/components/lists/BorcluVekilList.vue";
import TebligatList from "@/components/anakart/tebligat/TebligatList.vue";
import HacizList from "@/components/lists/haciz/HacizList.vue";
import BorcluDetayUyapInfoList from "@/components/lists/BorcluDetayUyapInfoList.vue";
import {BorcluEntity} from "@/entity/BorcluEntity";
import {AdresEntity} from "@/entity/AdresEntity";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import DokumanList from "@/components/lists/DokumanList.vue";
import SatisList from "@/components/lists/SatisList.vue";
import HacizTalepList from "@/components/lists/haciz/HacizTalepList.vue";
import DibList from "@/components/lists/BorcluBilgisiList.vue";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {BorcluVekiliEntity} from "@/entity/BorcluVekiliEntity";
import BorcluBilgisiList from "@/components/lists/BorcluBilgisiList.vue";
import {DosyaBorclu} from "@/plugins/uyap-plugin/uyap/DosyaBorclu";


@Component({
    computed: {
        AlacakKalemiEntity() {
            return AlacakKalemiEntity
        }
    },
    components: {
        BorcluBilgisiList,
        DibList,
        HacizTalepList,
        SatisList,
        DokumanList,
        AdresSelectableList,
        KimlikBilgileriList,
        IletisimList,
        BankaHesabiList,
        BorcluInfo,
        BorcluVekilList,
        TebligatList,
        HacizList,
        BorcluDetayUyapInfoList,
    }
})
export default class BorcluDetayView extends Vue {
    item: BorcluEntity = new BorcluEntity();
    borcluData: DosyaBorclu = new DosyaBorclu(this.item);


    mounted() {
        let activeProcess = {id: this.$route.params.id, key: 'borclu-detay'};
        this.$store.commit('setActiveProcess', activeProcess);
        this.borcluGetir();
        this.load();
    }


    load() {
        this.$http
            .get('/api/v1/borclu/' + parseInt(this.$route.params.id))
            .then(response => {
                this.item = response.data
            });
    }

    borcluGetir() {

        /*this.$http.get('/api/v1/dib-bilgileri').then((response) => {
                this.borcluData.dib = response.data;
        });

         */


        /* this.$http.get('/api/v1/gsm-bilgileri').then((response) => {
            this.borcluData = response.data;
        });

         */


      /*  this.$http.get('/api/v1/mernis-adres-bilgileri').then((response) => {
            this.borcluData.mernis.mernisAdresBilgileri = response.data;
        });

       */
       /* this.$http.get('/api/v1/posta-ceki-bilgileri').then((response) => {
            this.borcluData = response.data;
        });

        */



    }



    onDefaultKisiAdresChange(adres: AdresEntity) {
        if (adres.id && adres.adres_tur_id) {
            this.$http
                .put('/api/v1/kisi/' + this.item.kisi_id + '/varsayilan-adres', adres)
                .then(() => {
                    this.load()
                });

        }
    }

    onDefaultKurumAdresChange(adres: AdresEntity) {
        if (adres.adres_tur_id) {
            this.$http
                .put('/api/v1/kurum/' + this.item.kurum_id + '/varsayilan-adres', adres)
                .then(() => {
                    this.load()
                });
        }
    }

    get isTakipTipiRehin() {
        let isRehin = false;
        if (this.item.takip) {
            this.item.takip.talepler.forEach((talepler: TakipTalebiEntity) => {
                if (talepler.takip_tipi.rehin_mi)
                    isRehin = true;
            });
        }
        return isRehin;
    }

    isAuthorized(keySuffix: string) {
        let ap = this.$store.state.activeProcesses;
        let authKey = null;
        if (keySuffix[0] !== '-')
            keySuffix = '-' + keySuffix;
        if (ap.filter((item: any) => item.key === "takip-ac").length > 0) {
            authKey = "takip-ac";
        } else if (ap.filter((item: any) => item.key === "takip-duzenle").length > 0) {
            if ((ap.filter((item: any) => item.key === "borclu-detay").length > 0)) {
                authKey = 'borclu' + keySuffix;
            }
        }
        if (!authKey)
            return false;
        return this.$helper.isAuthorized(authKey)
    }
}
