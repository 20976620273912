
import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {},
})
export default class NotSablonPicker extends Mixins(SimpleInputMixin) {
  @Prop() returnObject!: string | boolean;
  @Prop() disabled: any;

  items: Array<object> = [];

  mounted() {
    this.load();
  }

  load() {
    this.$http.get("/api/v1/not-sablon").then((response: any) => {
      this.items = response.data;
    });
  }
}
