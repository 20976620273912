export enum TakipTipi {
    ilamsiz = 1,
    menkulRehni,
    adiKira,
    kambiyoSenetleriyleIflas,
    paraBorcuVeyaTeminat,
    menkulTeslimi,
    cocukTeslimi,
    tahliye,
    ipotekIcraEmri,
    ipotekOdemeEmri,
    iflas,
    kambiyoSenetleriyleHaciz,
    tasinirRehni,

}

export enum TakipYoluLabel {
    ilamsiz = "İlamsız Takipler",
    menkulRehni = "Menkul Rehninin Paraya Çevrilmesi",
    adiKira = "Adi Kira ve Hasılat Kirası",
    kambiyoSenetleriyleIflas = "Kambiyo Senetleriyle İflas Takibi",
    paraBorcuVeyaTeminat = "Para borcuna veya teminat verilmesine ilişkin ilam veya ilam niteliğindeki belgelere dayanan takipler",
    menkulTeslimi = "Menkul Teslimi veya GM Tahliye ve Teslimi",
    cocukTeslimi = "Çocuk Teslimi veya Çocukla Kişisel İlişki",
    tahliye = "Tahliye",
    ipotekIcraEmri = "İpoteğin Paraya Çevrilmesi (İcra Emri)",
    ipotekOdemeEmri = "İpoteğin Paraya Çevrilmesi (Ödeme Emri)",
    iflas = "İflas Yolu ile Adi Takip",
    kambiyoSenetleriyleHaciz = "Kambiyo Senetleriyle Haciz Takibi",
    tasinirRehni = "Taşınır Rehnin Paraya Çevrilmesi (İcra Emri)",
}