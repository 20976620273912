

import {Component, Mixins} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import EnumPicker from "@/components/pickers/EnumPicker.vue";
import {GemiTuru, GemiTuruLabel} from "@/enum/haciz/GemiTuru";

@Component({
  components: {EnumPicker}
})
export default class AlacakHacizTuruPicker extends Mixins(SimpleInputMixin) {
  gemiTuru = GemiTuru;
  gemiTuruLabel = GemiTuruLabel
}
