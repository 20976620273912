export enum TebligatAdresTuru {
    etebligatadresi = "etebligatadresi",
    mersisadresi = "mersisadresi",
    mernisadresi = "mernisadresi",
    yeniadres = "yeniadres",
}
export enum KisiTebligatAdresTuru {
    etebligatadresi = "etebligatadresi",
    mernisadresi = "mernisadresi",
    yeniadres = "yeniadres",
}
export enum KurumTebligatAdresTuru {
    etebligatadresi = "etebligatadresi",
    mersisadresi = "mersisadresi",
    yeniadres = "yeniadres",
}

export enum TebligatAdresTuruLabel {
    etebligatadresi = "E-Tebligat Adresi",
    mersisadresi = "Mersis Adresi",
    mernisadresi = "Mernis Adresi",
    yeniadres = "Borclu Adresi",

}