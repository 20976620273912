
import {Component, Mixins, Prop, Ref, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import {NotEntity} from "@/entity/NotEntity";
import TakipBorcluPicker from "@/components/pickers/TakipBorcluPicker.vue";
import NotSablonPicker from "@/components/pickers/NotSablonPicker.vue";
import NotEditor from "@/components/inputs/NotEditor.vue";
import {NotSablonEntity} from "@/entity/NotSablonEntity";
import NotTurPicker from "@/components/pickers/NotTurPicker.vue";
import AlacakliPicker from "@/components/pickers/AlacakliPicker.vue";

@Component({
  components: {AlacakliPicker, NotTurPicker, NotEditor, NotSablonPicker, TakipBorcluPicker, FormWrapper}
})
export default class NotForm extends Mixins(ObjectInputMixin) {
  @Prop() takipId!: number;
  @Prop() defaultType!: number | null;
  @Prop() defaultBorclu!: number | null;
  @Ref('borcluPicker') readonly borcluPicker!: TakipBorcluPicker;
  @Ref('alacakliPicker') readonly alacakliPicker!: AlacakliPicker;

  localValue: NotEntity = new NotEntity();
  secilenSablon: any = new NotSablonEntity();
  error = false;

  onSablonChange() {
    this.localValue.not = this.secilenSablon.not;
  }

  @Watch('localValue.not')
  isMailChange() {
    this.error = false;
  }

  onTakipBorclulariChange() {
    this.borcluPicker?.load();
  }

  onTakipAlacaklilariChange() {
    this.alacakliPicker?.load();
  }

  mounted() {
    if (this.defaultType && !this.localValue.id)
      this.localValue.not_tur_id = this.defaultType;
    if (this.defaultBorclu && !this.localValue.id)
      this.localValue.borclu_id = this.defaultBorclu;
  }

  save() {
    if (this.localValue.id) {
      if (!this.localValue.not || this.localValue.not == "") this.error = true;
      else {
        this.$http.put('/api/v1/not/' + this.localValue.id, this.localValue).then(() => {
          this.$emit('onSuccess')
        });
      }
    } else {
      if (!this.localValue.not || this.localValue.not == "") this.error = true;
      else {
        this.localValue.takip_id = this.takipId;
        this.$http.post('/api/v1/not/', this.localValue).then(() => {
          this.localValue = new NotEntity();
          this.localValue.not = null;
          if (this.defaultType)
            this.localValue.not_tur_id = this.defaultType;
          if (this.defaultBorclu)
            this.localValue.borclu_id = this.defaultBorclu;
          this.$emit('onSuccess')
        });
      }
    }
  }

  async close() {
    await this.resetValue();
    this.$emit('onClose');
  }

  async resetValue() {
    if (!this.localValue.id) {
      this.localValue = new NotEntity();
      delete this.$data.secilenSablon;
      this.input();
    }
  }
}
