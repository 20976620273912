
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import MalCinsiPicker from "@/components/pickers/haciz/MalCinsiPicker.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import {TasinirTuru} from "@/enum/haciz/TasinirTuru";
import {TasinirBilgileriEntity} from "@/entity/Haciz/TasinirBilgileriEntity";

@Component({
  components: {FormWrapper, MalCinsiPicker, Tutar}
})
export default class TasinirEkleForm extends Mixins(ObjectInputMixin) {
  @Prop({}) successOnValidate !: any;
  TasinirTuru = TasinirTuru;
  localValue!: TasinirBilgileriEntity;
}
