
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  components: {}
})
export default class KisiDetayUyapInfoList extends Vue {

  data() {
    return {
      tabs: null,
    }
  }
  load() {
    this.$emit("load");
  }
}
