

import {Component, Mixins} from "vue-property-decorator";
import SelectableListMixin from "@/mixins/SelectableListMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import YedieminForm from "@/components/forms/haciz/YedieminForm.vue";
import {YedieminEntity} from "@/entity/Haciz/YedieminEntity";

@Component({
  components: {
    FormWrapper,
    FormDialog,
    DeleteDialog,
    YedieminForm,
  },
})
export default class YedieminPicker extends Mixins(SelectableListMixin) {
  items: Array<YedieminEntity> = [];
  search: string = "";

  yedieminHeaders = [
    {text: "TC Kimlik/Mersis No", value: "mersis_no",},
    {text: "Adı Ünvanı", value: "sirket_unvani",},
    {text: "Yediemin Ücreti", value: "yediemin_ucreti",},
    {text: "İşlemler", value: "actions", width: 100, sortable: false, align: 'center'},
  ];

  mounted() {
    this.load();
  }

  load() {
    this.$http.get("/api/v1/yediemin").then(response => {
      this.items = response.data;
    });
  }
};
