
import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import IhbarnameAdresBilgileriForm from "@/components/forms/haciz/IhbarnameAdresBilgileriForm.vue";
import {IhbarnameAdresBilgileriEntity} from "@/entity/Haciz/IhbarnameAdresBilgileriEntity";

@Component({
  components: {FormDialog, DeleteDialog, IhbarnameAdresBilgileriForm}
})
export default class IhbarnameAdresBilgileriList extends Mixins(SimpleInputMixin) {
  @Prop({}) successOnValidate !: any;
  headers = [
    {text: "İhbarname Kişi/Kurum", value: "isim"},
    {text: "İşlemler", value: "actions", width: 100, sortable: false, aling: 'center'},
  ];
  localValue!: Array<IhbarnameAdresBilgileriEntity>;

  mounted() {
    if (!this.value) {
      this.localValue = [];
    }
  }

  onAdd(item: IhbarnameAdresBilgileriEntity) {
    this.localValue.push(item);
    this.input();
  }

  onUpdate(item: IhbarnameAdresBilgileriEntity, index: number) {
    let newArray = [...this.localValue];
    newArray[index] = item;
    this.localValue = newArray;
    this.input();
  }

  onDelete(index: number) {
    this.localValue.splice(index, 1);
    this.input();
  }
}
