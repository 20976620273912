import {SeksenDokuzTebligatEntity} from "@/entity/Haciz/SeksenDokuzTebligatEntity";
import {IhbarnameAdresBilgileriEntity} from "@/entity/Haciz/IhbarnameAdresBilgileriEntity";
import {TemlikEntity} from "@/entity/Haciz/TemlikEntity";

export class IhbarnameAlacakHacziEntity {
    alacak_tutari!: number;
    ihbarname_adres_bilgileri!: Array<IhbarnameAdresBilgileriEntity>;
    seksen_dokuz_bir_tebligat!: SeksenDokuzTebligatEntity;
    seksen_dokuz_iki_tebligat!: SeksenDokuzTebligatEntity;
    seksen_dokuz_uc_tebligat!: SeksenDokuzTebligatEntity;
    haciz_var_mi!: boolean;
    haciz_sirasi!: number;
    tedbir_var_mi!: boolean;
    tedbir_kararinin_verildigi_mahkeme!: string;
    temlikler!: Array<TemlikEntity>;
}