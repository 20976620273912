
import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {TakipEntity} from "@/entity/TakipEntity";
import {TasinmazEntity} from "@/entity/Haciz/TasinmazEntity";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import {AlacakEntity} from "@/entity/AlacakBelgeler/AlacakEntity";

@Component({
  components: {}
})
export default class TasinmazlarPicker extends Mixins(SimpleInputMixin) {
  @Prop({required: true}) takip!: TakipEntity;
  @Prop() multiple!: string | boolean;
  @Prop() rules!: any;
  @Prop() returnObject!: string | boolean;

  get isMultiple() {
    return this.multiple || this.multiple === "";
  }

  get isReturnObject() {
    return this.returnObject || this.returnObject === "";
  }

  get items() {
    let tasinmazlar: Array<TasinmazEntity> = []
    this.takip.talepler.forEach((talep: TakipTalebiEntity) => {
      talep.alacaklar.forEach((alacak: AlacakEntity) => {
        alacak.tasinmazlar.forEach((tasinmaz: TasinmazEntity) => (tasinmazlar.push(tasinmaz)));
      });
    });
    return tasinmazlar;
  }
}
