

import {Component, Mixins} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import EnumPicker from "@/components/pickers/EnumPicker.vue";
import {TasinirTuru, TasinirTuruLabel} from "@/enum/haciz/TasinirTuru";

@Component({
  components: {EnumPicker}
})
export default class MalCinsiPicker extends Mixins(SimpleInputMixin) {
  turler = TasinirTuru;
  turlerLabel = TasinirTuruLabel;
}
