
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import ItirazinIptaliAlacakKalemiForm from "@/components/forms/alacak-kalemi/ItirazinIptaliAlacakKalemiForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {AlacakKalemiTuruLabel} from "@/enum/AlacakKalemiTuru";

@Component({
  components: {DeleteDialog, ItirazinIptaliAlacakKalemiForm, FormDialog}
})
export default class ItirazinIptaliAlacakKalemiList extends Mixins(SimpleInputMixin) {
  @Prop() takipTipiId!: number;

  localValue!: number;
  items: Array<AlacakKalemiEntity> = [];

  headers = [
    {text: "Alacak Kalemi Türü", value: "alacak_kalemi_alani"},
    {text: "Tarih", value: "faiz_baslangic_tarihi"},
    {text: "Tutar", value: "tutar"},
    {text: "İşlemler", value: "actions", align: 'center', sortable: false},
  ]

  @Watch('localValue')
  onAlacakIdChange() {
    if (this.localValue)
      this.load();
  }

  mounted() {
    if (this.localValue)
      this.load();
  }

  load() {
    this.$http
        .get("/api/v1/alacak/" + this.localValue + "/alacak-kalemi")
        .then((response: any) => {
          this.items = response.data;
        });
  }
}
