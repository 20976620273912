
import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {DosyaBorclu} from "@/plugins/uyap-plugin/uyap/DosyaBorclu";
import UyapImzaBilgi from "@/components/UyapImzaBilgi.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import NotList from "@/components/lists/NotList.vue";
import HacizTalepForm from "@/components/forms/haciz/HacizTalepForm.vue";
import TebligatForm from "@/components/anakart/tebligat/TebligatForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import TebligatMasrafList from "@/components/forms/TebligatMasrafList.vue";
import AltTahsilatTuruForm from "@/components/forms/AltTahsilatTuruForm.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import AdresForm from "@/components/forms/AdresForm.vue";
import TakipTipiPicker from "@/components/pickers/TakipTipiPicker.vue";
import BuroForm from "@/components/forms/BuroForm.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import MahrumiyetInfo from "@/components/infos/eicrapro/MahrumiyetInfo.vue";
import MahrumiyetList from "@/components/MahrumiyetList.vue";

@Component({
    components: {
        MahrumiyetList,
        MahrumiyetInfo,
        BuroForm,
        TakipTipiPicker,
        AdresForm,
        FormWrapper,
        AltTahsilatTuruForm,
        TebligatMasrafList, DeleteDialog, TebligatForm, HacizTalepForm, NotList, FormDialog, UyapImzaBilgi
    }
})
export default class BorcluBilgisiList extends Mixins(SimpleInputMixin) {

    @Prop() borcluData!: DosyaBorclu

    senkronizeEtData: DosyaBorclu[] = []

    senkronizeTarih = ''

    tabs = null
    bankaBilgileriYuklendi = false
    dibBilgileriYuklendi=false
    egmYuklendi = false
    gibYuklendi = false
    gsmYuklendi = false
    iskiYuklendi = false
    mernisYuklendi = false
    postaYuklendi = false
    sgkYuklendi = false
    sskYuklendi = false
    icraDosyaYuklendi = false
    takbisYuklendi = false
    mersisYuklendi = false

    hideCloseButton = false
    uyapImzaBilgi = false

    snackbarSenkronize = false

    bankaBilgileriHeaders = [
        {text: 'Banka Adı', value: 'banka_adi'},
    ];

    egmBilgileriHeaders = [
        {text: 'Marka', value: 'marka'},
        {text: 'Model', value: 'model'},
        {text: 'Plaka', value: 'plaka'},
        {text: 'Takyidat Sırası', value:'takyidatSirasi'},
        {text: 'Cins', value: 'cins'},
        {text: 'Renk', value: 'renk'},
        {text: 'Tipi', value: 'tipi'},
        {text: 'Haciz Şerhi', value: 'haciz_serhi'}
    ];

    mahrumiyetBilgileriHeaders = [
        {text: 'Takyidat Sırası', value: 'takyidatSirasi'},
        {text: 'Eklenme Tarihi', value: 'eklenmeTarihi'},
        {text: 'Şerh Türü', value: 'serhTuru'},
        {text: 'Kurum Adı', value: 'kurumAdi'},
        {text: 'Ekleyen Birim', value: 'ekleyenBirim'}
    ];

    dibBilgileriHeaders = [
        {text: 'Adres Türü', value: 'adresTuru'},
        {text: 'Şehir', value: 'sehir'},
        {text: 'Adres', value: 'adres'},
        {text: 'Posta Kodu', value: 'postaKodu'}
    ];

    gibBilgileriHeaders = [
        {text: 'Gib Mesajı', value: 'message'},
        {text: 'Adres', value: 'adres'}
    ];

    gsmBilgileriHeaders = [
        {text:'Adres', value:'adres'},
        {text:'Operatör', value:'operator'},
    ]

    iskiBilgileriHeaders = [
        {text: 'İski Mesajı', value: 'message'},
        {text: 'Ad Soyad', value: 'adSoyad'},
        {text: 'Sözleşme Tarihi', value: 'sozlesmeTarihi'},
        {text: 'Adres', value: 'adres'},
        {text: 'Durum', value: 'durum'}
    ];

    mernisBilgileriHeaders = [
        {text: 'Adı', value: 'adi'},
        {text: 'Soyadı', value: 'soyadi'},
        {text: 'Anne Adı', value: 'anaAdi'},
        {text: 'Baba Adı', value: 'babaAdi'},
        {text: 'Aile Sıra No', value: 'aileSiraNo'},
        {text: 'Cilt No', value: 'ciltNo'},
        {text: 'Cüzdan No', value: 'cuzdanNo'},
        {text: 'Cüzdan Seri No', value: 'cuzdanSeri'},
        {text: 'Tc Kimlik No', value: 'tcKimlikNo'},
        {text: 'Cinsiyeti', value: 'cinsiyeti'},
        {text: 'Dini', value: 'dini'},
        {text: 'Doğum Tarihi', value: 'dogumTarihi'},
        {text: 'Doğum Yeri', value: 'dogumYeri'},
        {text: 'Mahalle-Köy', value: 'mahalleKoy'},
        {text: 'Nüfusa Kayıtlı İl', value: 'nufusKayitIl'},
        {text: 'Nüfusa Kayıtlı İlçe', value: 'nufusKayitIlce'},
        {text: 'Ölüm Tarihi', value: 'olumTarihi'},
        {text: 'Önceki Soyadı', value: 'oncekiSoyadi'},
        {text: 'Verildiği Tarih', value: 'verildigiTarih'},
        {text: 'Verildiği İl', value: 'verildigiIl'},
        {text: 'Verildiği İlçe', value: 'verildigiIlce'},
        {text: 'Veriliş Nedeni', value: 'verilisNedeni'}
    ];

    mernisAdresBilgileriHeaders = [
        {text: 'Adres Tipi', value: 'adresTipi'},
        {text: 'Beyan Tarihi', value: 'beyanTarihi'},
        {text: 'Cadde-Sokak', value: 'caddeSokak'},
        {text: 'Dış Kapı No', value: 'disKapiNo'},
        {text: 'İç Kapı No', value: 'icKapiNo'},
        {text: 'İl', value: 'il'},
        {text: 'İlçe', value: 'ilce'},
        {text: 'Mahalle', value: 'mahalle'},
        {text: 'Taşınma Tarihi', value: 'tasinmaTarihi'},
        {text: 'Tescil Tarihi', value: 'tescilTarihi'}
    ];

    postaCekiHeaders = [
        {text: 'Posta Çeki Mesajı', value: 'message'}
    ];

    sgkBagkurBilgileriHeaders = [
        {text: 'Bağkur No', value: 'bagkurNo'},
        {text: 'Meslek İl', value: 'meslekIl'},
        {text: 'Meslek İlçe', value: 'meslekIlce'},
        {text: 'Terk Açıklama', value: 'terkAciklama'},
        {text: 'Terk Tarihi', value: 'terkTarihi'},
        {text: 'tescilTarihi', value: 'tescilTarihi'},
        {text: 'Ad Soyad', value: 'adSoyad'},
        {text: 'Baba Anne Adı', value: 'babaAnaAd'},
        {text: 'Doğum Yılı', value: 'dogumYili'},
        {text: 'Tc No', value: 'tcNo'}
    ];

    sskBilgileriHeaders = [
        {text: 'Adı Soyadı', value: 'adiSoyadi'},
        {text: 'Anne Adı', value: 'anaAdi'},
        {text: 'Baba Adı', value: 'babaAdi'},
        {text: 'Doğum Yılı', value: 'dogumYili'},
        {text: 'Dönem Yıl Ay', value: 'donemYilAy'},
        {text: 'İlk İşe Giriş Tarihi', value: 'ilkIseGirisTarihi'},
        {text: 'İş Yeri Adresi', value: 'isYeriAdresi'},
        {text: 'İş Yeri İl', value: 'isYeriIl'},
        {text: 'İş Yeri Sicil', value: 'isYeriSicil'},
        {text: 'İş Yeri Ünvanı', value: 'isYeriUnvani'},
        {text: 'İşten Çıkış Tarihi', value: 'istenCikisTarihi'},
        {text: 'Sicil No', value: 'sicilNo'},
        {text: 'Son İşe Giriş Tarihi', value: 'sonIseGirisTarihi'},
        {text: 'Tc Kimlik No', value: 'tcKimlikNo'}
    ];

    icraDosyaBilgileriHeaders = [
        {text: 'Açılış', value: 'acilis'},
        {text: 'Birim Adı', value: 'birimAdi'},
        {text: 'Dosya No', value: 'dosyaNo'},
        {text: 'Dosya Türü', value: 'dosyaTuru'},
        {text: 'Takip Durumu', value: 'takipDurumu'},
        {text: 'Takip Şekli', value: 'takipSekli'},
        {text: 'Takip Türü', value: 'takipTuru'},
        {text: 'Takip Yolu', value: 'takipYolu'},
        {text: 'Kapanış', value: 'kapanis'}
    ];

    takbisBilgileriHeaders = [
        {text: 'Ada', value: 'ada'},
        {text: 'Parsel', value: 'parsel'},
        {text: 'Hisse', value: 'hisse'},
        {text: 'İl', value: 'il'},
        {text: 'İlçe', value: 'ilce'},
        {text: 'Mahalle', value: 'mahalle'},
        {text: 'Mevki', value: 'mevki'},
        {text: 'Tapu Müdürlüğü', value: 'tapuMudurlugu'},
        {text: 'Vasıf', value: 'vasif'},
        {text: 'Yüz Ölçümü', value: 'yuzOlcumu'}
    ];

    mersisBilgileriHeaders = [
        {text: 'Mersis Mesajı', value:'message'},
        {text: 'Adres', value:'adres'}
    ]


    load() {
        this.$emit("load");
    }



    /*uyapGirisiYapılıdı(){
        if (!this.$store.getters.isImzaConnected){
            this.senkronizEt = false
        }
    }

     */

    senkronizeEvet() {
    }

    uyapBorcluSnackbar() {
        this.snackbarSenkronize = true
    }

    uyapBilgileriniGetir() {

    }


    bankaBilgileriniGetir() {
        if (!this.bankaBilgileriYuklendi) {
            this.$http.get('/api/v1/banka-bilgileri/' + parseInt(this.$route.params.id)).then((response) => {
                this.borcluData.bankaBilgileri.items = response.data;
                this.senkronizeEtData.push(response.data)
                this.bankaBilgileriYuklendi = true

            });
        }
    }

    dibBilgileriniGetir(){
        if (!this.dibBilgileriYuklendi) {
            this.$http.get('/api/v1/dib-bilgileri/' + parseInt(this.$route.params.id)).then((response) => {
                this.borcluData.dib.data = response.data;
                this.dibBilgileriYuklendi = true

            });
        }
    }

    egmBilgileriniGetir() {
        if (!this.egmYuklendi) {
            this.$http.get('/api/v1/arac-bilgileri/' + parseInt(this.$route.params.id)).then((response) => {
                this.borcluData.egm.items = response.data;
                this.egmYuklendi = true;
            });
        }
    }


    gibBilgileriniGetir() {
        if (!this.gibYuklendi) {
            this.$http.get('/api/v1/gib-bilgileri/' + parseInt(this.$route.params.id)).then((response) => {
                this.borcluData.gib.adres = response.data;
                this.gibYuklendi = true
            });
        }

    }

    gsmBilgileriniGetir() {
        if (!this.gsmYuklendi) {
            this.$http.get('/api/v1/gsm-bilgileri/' + parseInt(this.$route.params.id)).then((response) => {
                this.borcluData.gsm.data = response.data;
                this.gsmYuklendi = true
            });
        }

    }

    iskiBilgileriniGetir() {
        if (!this.iskiYuklendi) {
            this.$http.get('/api/v1/iski-bilgileri/' + parseInt(this.$route.params.id)).then((response) => {
                this.borcluData.iski.data = response.data;
                this.iskiYuklendi = true
            });
        }

    }

    mernisBilgileriniGetir() {
        if (!this.mernisYuklendi) {
            this.$http.get('/api/v1/mernis-bilgileri/' + parseInt(this.$route.params.id)).then((response) => {
                this.borcluData.mernis = response.data;
                this.mernisYuklendi = true
            });
        }

    }

    postaCekiBilgileriniGetir() {
        if (!this.postaYuklendi) {
            this.$http.get('/api/v1/posta-ceki-bilgileri/' + parseInt(this.$route.params.id)).then((response) => {
                this.borcluData.postaCeki.message = response.data;
                this.postaYuklendi = true
            });
        }

    }

    sgkBagkurBilgileriniGetir() {
        if (!this.sgkYuklendi) {
            this.$http.get('/api/v1/sgk-bilgileri/' + parseInt(this.$route.params.id)).then((response) => {
                this.borcluData.sgkbagkur = response.data;
                this.sgkYuklendi = true
            });
        }

    }

    sskBilgileriniGetir() {
        if (!this.sskYuklendi) {
            this.$http.get('/api/v1/ssk-bilgileri/' + parseInt(this.$route.params.id)).then((response) => {
                this.borcluData.ssk = response.data;
                this.sskYuklendi = true
            });
        }

    }

    icraDosyaBilgileriniGetir() {
        if (!this.icraDosyaYuklendi) {
            this.$http.get('/api/v1/uyap-icra-dosya-bilgileri/' + parseInt(this.$route.params.id)).then((response) => {
                this.borcluData.icraDosyaKaydi = response.data;
                this.icraDosyaYuklendi = true
            });
        }
    }

    takbisBilgileriniGetir() {
        if (!this.takbisYuklendi) {
            this.$http.get('/api/v1/takbis-bilgileri/' + parseInt(this.$route.params.id)).then((response) => {
                this.borcluData.takbis = response.data;
                this.takbisYuklendi = true
            });
        }
    }

    mersisBilgileriniGetir(){
        if(!this.mersisYuklendi){
            this.$http.get('/api/v1/mersis-bilgileri/' + parseInt(this.$route.params.id)).then((response) => {
                this.borcluData.mersis = response.data;
                this.mersisYuklendi = true
            });
        }
    }

}
