
import {Component, Mixins, Prop, Ref, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import BelgeTuruPicker from "@/components/pickers/BelgeTuruPicker.vue";
import TebligatAdresTuruPicker from "@/components/pickers/TebligatAdresTuruPicker.vue";
import AdresSelectableList from "@/components/lists/AdresSelectableList.vue";
import TebligatDurumuPicker from "@/components/pickers/TebligatDurumuPicker.vue";
import TebligatSekliPicker from "@/components/pickers/TebligatSekliPicker.vue";
import TebligatTeslipTipiPicker from "@/components/pickers/TebligatTeslipTipiPicker.vue";
import TakipTipiPicker from "@/components/pickers/TakipTipiPicker.vue";
import EnabledSwitch from "@/components/inputs/EnabledSwitch.vue";
import Dates from "@/components/inputs/Dates.vue";
import {TebligatSekli} from "@/enum/TebligatSekli";
import {TebligatDurumu} from "@/enum/TebligatDurumu";
import {TebligatAdresTuru} from "@/enum/TebligatAdresTuru";
import {TebligatEntity} from "@/entity/TebligatEntity";
import {BorcluEntity} from "@/entity/BorcluEntity";
import {AdresEntity} from "@/entity/AdresEntity";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import {TakipTipiEntity} from "@/entity/TakipTipiEntity";
import {IlamliTebligatBelgeTuru, IlamsizTebligatBelgeTuru} from "@/enum/TebligatBelgeTuru";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import ItirazinIptaliDavasiInfo from "@/components/infos/ItirazinIptaliDavasiInfo.vue";
import {TakipYoluLabel} from "@/enum/TakipTipi";
import {TakipDurumu} from "@/enum/TakipDurumu";
import TebligatTuruPicker from "@/components/pickers/TebligatTuruPicker.vue";

@Component({
  components: {
    TebligatTuruPicker,
    ItirazinIptaliDavasiInfo,
    FormDialog,
    FormWrapper,
    TakipTipiPicker,
    BelgeTuruPicker,
    TebligatAdresTuruPicker,
    AdresSelectableList,
    TebligatDurumuPicker,
    TebligatSekliPicker,
    TebligatTeslipTipiPicker,
    EnabledSwitch,
    Dates
  }
})
export default class TebligatForm extends Mixins(ObjectInputMixin) {
  @Prop({}) borclu!: BorcluEntity;
  @Prop({}) successOnValidate !: any;
  @Ref('formWrapper') formWrapper!: FormWrapper;

  tebligatAdresTuru = TebligatAdresTuru;
  TebligatDurumu = TebligatDurumu;
  localValue: TebligatEntity = new TebligatEntity();
  adresLength: boolean = false
  TakipYoluLabel = TakipYoluLabel;
  takipDurumu = TakipDurumu;
  warning = false;

  get adresler() {
    return this.borclu.kisi ? this.borclu.kisi.adresler : (this.borclu.kurum ? this.borclu.kurum.adresler : []);
  }

  get isSuccessOnValidate() {
    return this.successOnValidate || this.successOnValidate === "";
  }

  get action() {
    if (this.isSuccessOnValidate) {
      return null;
    }
    return "/api/v1/tebligat";
  }

  get adresEkleAction() {
    if (this.borclu.kisi) {
      return '/api/v1/kisi/' + this.borclu.kisi_id + '/adres';
    } else if (this.borclu.kurum) {
      return '/api/v1/kurum/' + this.borclu.kurum_id + '/adres';
    }
    return null;
  }

  get takipTipleri(): Array<TakipTipiEntity> {
    return this.borclu.takip.talepler.map((takipTalebi: TakipTalebiEntity) => {
      return takipTalebi.takip_tipi;
    });
  }

  get ilamli() {
    if (this.localValue.takip_tipi) {
      return this.localValue.takip_tipi.ilamli;
    }
    return null;
  }

  get minTebligatGonderimTarihi() {
    if (this.borclu.takip.takip_durumu == this.takipDurumu.acik && this.borclu.takip.takip_tarihi)
      return this.borclu.takip.takip_tarihi;
    return;
  }

  @Watch("localValue.tebligat_durumu")
  onTebligatDurumuValueChange() {
    this.clearTebligatForm();
  }

  @Watch("localValue.teblig_tarihi")
  onPropertyChanged(value: string, oldValue: string) {
    let kesinlesmeSuresi = this.localValue.takip_tipi?.kesinlesme_suresi;
    let sonOdemeSuresi = this.localValue.takip_tipi?.odeme_suresi;
    if (this.localValue.teblig_tarihi) {
      let sonOdemeTarihi = new Date(this.localValue.teblig_tarihi);
      let kesinlesmeTarihi = new Date(this.localValue.teblig_tarihi);
      sonOdemeTarihi.setDate(sonOdemeTarihi.getDate() + sonOdemeSuresi);
      kesinlesmeTarihi.setDate(kesinlesmeTarihi.getDate() + kesinlesmeSuresi);
      this.localValue.son_odeme_tarihi = sonOdemeTarihi;

      this.localValue.kesinlesme_tarihi = kesinlesmeTarihi;
    }
    this.tebligTarihiniIzleyenGun;
  }

  @Watch('localValue.son_odeme_tarihi')
  onSonOdemeTarihiChange() {
    if (!this.localValue.son_odeme_tarihi) {
      this.tebligTarihiniIzleyenGun
    }
  }

  onItirazVarMiChange() {
    if (this.localValue.itiraz_var_mi) {
      delete this.localValue.kesinlesme_tarihi;
      delete this.localValue.son_odeme_tarihi;
    }
    delete this.localValue.itiraz_nedeni;
    delete this.localValue.itiraz_tarihi;
    this.input();
  }

  barkodSorgulama() {
    window.open('https://www.turkiye.gov.tr/ptt-gonderi-takip', '_blank');
  }

  clearTebligatForm() {
    if (this.localValue.tebligat_durumu == TebligatDurumu.gonderildi) {
      this.localValue.teblig_tarihi = undefined;
      this.localValue.kesinlesme_tarihi = undefined;
      this.localValue.son_odeme_tarihi = undefined;
      this.localValue.tebligat_teslim_tipi_id = undefined;
      this.localValue.teslim_alan = undefined;
      this.localValue.bila_sebebi = undefined;
      this.localValue.bila_tarihi = undefined;
      this.localValue.itiraz_var_mi = false;
      this.localValue.mal_beyaninda_bulundu_mu = false;
    } else if (this.localValue.tebligat_durumu == TebligatDurumu.teblig_edildi) {
      this.localValue.bila_sebebi = undefined;
      this.localValue.bila_tarihi = undefined;
    } else if (this.localValue.tebligat_durumu == TebligatDurumu.bila_oldu) {
      this.localValue.teblig_tarihi = undefined;
      this.localValue.kesinlesme_tarihi = undefined;
      this.localValue.son_odeme_tarihi = undefined;
      this.localValue.tebligat_teslim_tipi_id = undefined;
      this.localValue.teslim_alan = undefined;
      this.localValue.itiraz_var_mi = false;
      this.localValue.mal_beyaninda_bulundu_mu = false;
    }
  }

  setDefaultValues() {
    if (!this.localValue.adres) {
      if (this.borclu?.kisi) {
        this.localValue.adres = <AdresEntity>{...this.borclu.kisi.varsayilan_adres};
      } else if (this.borclu?.kurum) {
        this.localValue.adres = <AdresEntity>{...this.borclu.kurum.varsayilan_adres};
      }
    }
    this.localValue.borclu_id = this.borclu.id;

    if (!this.localValue.tebligat_adres_turu) {
      this.localValue.tebligat_adres_turu = TebligatAdresTuru.yeniadres;
    }

    if (!this.localValue.tebligat_sekli) {
      this.localValue.tebligat_sekli = TebligatSekli.normal;
    }

    if (!this.localValue.itiraz_var_mi) {
      this.localValue.itiraz_var_mi = false;
    }
    if (!this.localValue.mal_beyaninda_bulundu_mu) {
      this.localValue.mal_beyaninda_bulundu_mu = false;
    }
    if (this.localValue?.takip_tipi) {
      if (this.ilamli && this.localValue.belge_turu == IlamsizTebligatBelgeTuru.odemeemri) {
        this.localValue.belge_turu = IlamliTebligatBelgeTuru.icraemri;
      } else if (this.localValue.belge_turu == IlamliTebligatBelgeTuru.icraemri) {
        this.localValue.belge_turu = IlamsizTebligatBelgeTuru.odemeemri;
      }
    }
    if (!this.localValue.itirazin_iptali_davasi) {
      this.localValue.itirazin_iptali_davasi = [];
    }
    this.input();
  }

  onTakipTipiChange() {
    if (this.localValue.takip_tipi?.id) {
      this.localValue.takip_tipi_id = this.localValue.takip_tipi.id;
    }
    this.setDefaultValues();
  }

  get itirazVarMi() {
    return !(this.localValue.takip_tipi.aciklama === TakipYoluLabel.cocukTeslimi ||
        this.localValue.takip_tipi.aciklama === TakipYoluLabel.menkulTeslimi ||
        this.localValue.takip_tipi.aciklama === TakipYoluLabel.paraBorcuVeyaTeminat ||
        this.localValue.takip_tipi.aciklama === TakipYoluLabel.ipotekIcraEmri ||
        this.localValue.takip_tipi.aciklama === TakipYoluLabel.tasinirRehni);
  }

  get sonOdemeTarihiLabel() {
    let label = "";
    switch (this.localValue.takip_tipi.aciklama) {
      case TakipYoluLabel.cocukTeslimi: {
        label = "Çocuğun Teslim Edildiği Tarih";
        break;
      }
      case TakipYoluLabel.menkulTeslimi: {
        label = "Tahliye/Teslim/İşin Yapılması Tarihi";
        break;
      }
      case TakipYoluLabel.tahliye: {
        label = "Tahliye Tarihi";
        break;
      }
      default:
        label = "Son Ödeme Tarihi";
        break;
    }
    return label;
  }

  get tebligTarihiniIzleyenGun() {
    let warningMessage = "";
    if (this.localValue.teblig_tarihi) {
      let fark = this.$helper.tarihFarkiGunSayisi(new Date(), new Date(this.localValue.teblig_tarihi));
      if (Math.abs(fark) >= this.localValue.takip_tipi.odeme_suresi && !this.localValue.son_odeme_tarihi) {
        warningMessage = "Tebliğ tarihi üzerinden " + Math.abs(fark) + " gün geçti.";
        this.warning = true;
      } else {
        this.warning = false;
      }
    }
    return warningMessage
  }

  mounted() {
    this.setDefaultValues();
    this.tebligTarihiniIzleyenGun;
  }

  load() {
    this.$emit("load");
  }

  onSuccess(tebligat: TebligatEntity) {
    this.formWrapper.onFly = true;
    if (!tebligat.adres?.id) {
      if (this.localValue.itirazin_iptali_davasi[0]) {
        this.$http.post('/api/v1/tebligat/' + tebligat.id + '/itirazin-iptali-davasi', this.localValue.itirazin_iptali_davasi[0]);
      }
      this.saveAdres(tebligat.id, this.localValue.adres);
    } else {
      if (this.localValue.itirazin_iptali_davasi[0]?.id) {
        this.$http.put('/api/v1/tebligat/' + tebligat.id + '/itirazin-iptali-davasi/', this.localValue.itirazin_iptali_davasi[0]).finally(() => (this.formWrapper.onFly = false));
      } else if (this.localValue.itirazin_iptali_davasi[0]) {
        this.$http.post('/api/v1/tebligat/' + tebligat.id + '/itirazin-iptali-davasi', this.localValue.itirazin_iptali_davasi[0]).finally(() => (this.formWrapper.onFly = false));
      }
      this.$emit("onSuccess", tebligat);
    }
    this.load()
  }

  saveAdres(tebligatId: number, adres: AdresEntity) {
    this.$http.post("/api/v1/tebligat/" + tebligatId + "/adres", adres).then(() => {
      this.$emit("onSuccess");
    }).finally(() => (this.formWrapper.onFly = false));
  }
}
