
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import HacizTuruPicker from "@/components/pickers/haciz/HacizTuruPicker.vue";
import AracHacziForm from "@/components/forms/haciz/haciz-turu/AracHacziForm.vue";
import MaasHacziForm from "@/components/forms/haciz/haciz-turu/MaasHacziForm.vue";
import HisseKarPayiHacziForm from "@/components/forms/haciz/haciz-turu/HisseKarPayiHacziForm.vue";
import MarkaPatentHacziForm from "@/components/forms/haciz/haciz-turu/MarkaPatentHacziForm.vue";
import GemiHacziForm from "@/components/forms/haciz/haciz-turu/GemiHacziForm.vue";
import GayrimenkulHacziForm from "@/components/forms/haciz/haciz-turu/GayrimenkulHacziForm.vue";
import TasinirHacziForm from "@/components/forms/haciz/haciz-turu/TasinirHacziForm.vue";
import DigerHacizForm from "@/components/forms/haciz/haciz-turu/DigerHacizForm.vue";
import {HacizEntity} from "@/entity/Haciz/HacizEntity";
import {HacizTuru} from "@/enum/haciz/HacizTuru";
import {BorcluEntity} from "@/entity/BorcluEntity";
import AlacakliOlduguDosyaHacizForm from "@/components/forms/haciz/haciz-turu/AlacakliOlduguDosyaHacizForm.vue";
import BankaHacizForm from "@/components/forms/haciz/haciz-turu/BankaHacizForm.vue";
import SgkMossipHacizForm from "@/components/forms/haciz/haciz-turu/SgkMossipHacizForm.vue";
import TasinirHacizTalimatiForm from "@/components/forms/haciz/haciz-turu/TasinirHacizTalimatiForm.vue";
import {AvukatTalepListCevapRow} from "@/plugins/uyap-plugin/uyap/AvukatTalepList";
import PostaCekiHacizForm from "@/components/forms/haciz/haciz-turu/PostaCekiHacizForm.vue";
import Ihbarname891HacizForm from "@/components/forms/haciz/haciz-turu/Ihbarname891HacizForm.vue";
import Ihbarname892HacizForm from "@/components/forms/haciz/haciz-turu/Ihbarname892HacizForm.vue";
import Ihbarname893HacizForm from "@/components/forms/haciz/haciz-turu/Ihbarname893HacizForm.vue";
import MuzekkereAlacakHacziForm from "@/components/forms/haciz/haciz-turu/MuzekkereAlacakHacziForm.vue";

@Component({
  components: {
    MuzekkereAlacakHacziForm,
    Ihbarname893HacizForm,
    Ihbarname892HacizForm,
    Ihbarname891HacizForm,
    PostaCekiHacizForm,
    TasinirHacizTalimatiForm,
    SgkMossipHacizForm,
    BankaHacizForm,
    AlacakliOlduguDosyaHacizForm,
    DigerHacizForm,
    FormWrapper,
    HacizTuruPicker,
    AracHacziForm,
    MaasHacziForm,
    HisseKarPayiHacziForm,
    MarkaPatentHacziForm,
    GemiHacziForm,
    GayrimenkulHacziForm,
    TasinirHacziForm,
  }
})
export default class HacizForm extends Mixins(ObjectInputMixin) {
  @Prop() borclu!: BorcluEntity;

  localValue: HacizEntity = new HacizEntity();
  hacizTuru = HacizTuru;
  avukatTalepList: Array<AvukatTalepListCevapRow> = [];

  mounted() {
    this.getTalepList();
  }

  get kisiKurumId(){
    if (this.borclu.kisi_id)
      return this.borclu.kisi.uyap_kisi_kurum_id;
    else
      return this.borclu.kurum.uyap_kisi_kurum_id;
  }

  get dosyaId(){
    return this.borclu.takip.uyap_dosya_id;
  }

  async getTalepList() {
    this.avukatTalepList = (await this.$uyap.AvukatTalepList().run({
      dosyaId: this.borclu?.takip.uyap_dosya_id,
      islemTuru: 'haciz'
    })).items;
  }

  onHacizTuruChange(e: any) {
    this.localValue.haciz_turu = e;
    this.input();
  }
}
