
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import AdresForm from "@/components/forms/AdresForm.vue";
import {IhbarnameAdresBilgileriEntity} from "@/entity/Haciz/IhbarnameAdresBilgileriEntity";

@Component({
  components: {
    FormWrapper,
    AdresForm,
  }
})
export default class IhbarnameAdresBilgileriForm extends Mixins(ObjectInputMixin) {
  @Prop({}) successOnValidate !: any;
  localValue!: IhbarnameAdresBilgileriEntity;
};
