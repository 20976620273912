
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import AdresForm from "@/components/forms/AdresForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {AdresEntity} from "@/entity/AdresEntity";
import {AdresTuruLabel} from "@/enum/AdresTuru";

@Component({
  components: {FormDialog, AdresForm, DeleteDialog}
})
export default class AdresSelectableList extends Mixins(SimpleInputMixin) {

  @Prop({}) items!: Array<AdresEntity>;
  @Prop({}) action!: string;
  @Prop({}) successOnValidate !: any;
  AdresTuruLabel = AdresTuruLabel;
  localItems = this.items;
  localValue: AdresEntity | null | undefined
  @Watch("items")
  onItemsChange() {
    this.localItems = this.items;
    if (this.localValue === null) {
      if (this.localItems.length > 0) {
        this.localValue = this.localItems[0];
      } else {
        this.localValue = new AdresEntity();
      }
      this.input();
    } else {
      const selected = this.localItems.find(adres => adres.id === this.localValue.id);
      this.localValue = selected ? { ...selected } : new AdresEntity();
    }
  }

  onCheckedChange() {
    let selected = this.localItems.filter((adres: AdresEntity) => adres.id == this.localValue.id)[0];
    this.localValue = selected ? {...selected} : new AdresEntity();
    localStorage.setItem("selectedAddressItemId", String(this.localValue.id));
    this.input();
  }

  onAdd(item: AdresEntity) {
    if (this.action) {
      this.load();
    } else {
      this.localItems.push(item);
      this.input();
    }
  }

  load() {
    this.$emit("load");
  }

  isAuthorized(keySuffix: string) {
    let ap = [...this.$store.state.activeProcesses].reverse();
    let authKey = null;

    if (keySuffix[0] !== '-')
      keySuffix = '-' + keySuffix;

    if (ap[0].key === "borclu-detay")
      authKey = "borclu-adres-bilgisi" + keySuffix;
    else if (ap[0].key === "alacakli-detay")
      authKey = "muvekkil-adres-bilgisi" + keySuffix;
    else if (ap[0].key === "muvekkil-detay")
      authKey = "muvekkil-adres-bilgisi" + keySuffix;

    if (!authKey) return false;
    return this.$helper.isAuthorized(authKey)
  }

  mounted() {
    if (this.localValue === null) {
      if (this.localItems.length > 0) {
        this.localValue = { ...this.localItems[0] };
      } else {
        this.localValue = new AdresEntity();
      }
    } else {
      const selectedItemId = this.localValue.id;
      const selected = this.localItems.find(item => item.id === selectedItemId);

      if (selected) {
        this.localValue = { ...selected };
      } else if (this.localItems.length > 0) {
        this.localValue = { ...this.localItems[0] };
      } else {
        this.localValue = new AdresEntity();
      }
    }
    const savedSelectedItemId = localStorage.getItem("selectedAddressItemId");
    if (savedSelectedItemId) {
      const selectedId = Number(savedSelectedItemId);
      const selected = this.localItems.find(item => item.id === selectedId);
      if (selected) {
        this.localValue = { ...selected };
      }
    }
  }


}
