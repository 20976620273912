
import {Component, Prop, Vue} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import TakipTalebiTaslak from "@/components/TakipTalebiTaslak.vue";
import Emirler from "@/components/Emirler.vue";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {MasrafEntity} from "@/entity/MasrafEntity";
import HacizMasrafForm from "@/components/forms/HacizMasrafForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";

@Component({
  components: {
    FormDialog,
    DeleteDialog,
    HacizMasrafForm, Emirler, TakipTalebiTaslak, FormWrapper
  }
})

export default class HacizMasrafList extends Vue {
  @Prop() hacizId !: number;
  @Prop({}) items!: Array<MasrafEntity>;
  @Prop() hideCloseButton!: boolean | string;
  hacizMasraflari: Array<AlacakKalemiEntity> = [];


  get isHideClose() {
    return this.hideCloseButton || this.hideCloseButton === "";
  }

  search: string = "";
  headers = [
    {text: "Tarih", align: "start", sortable: false, value: "tarih",},
    {text: "Masraf Çeşidi", align: "start", sortable: false, value: "masraf_cesidi",},
    {text: "Tutar", value: "tutar", align: "right"},
    {text: "Masraf Türü", value: "gayri_resmi_mi"},
    {text: "Belgeli", value: "masraf_belgeli_mi"},
    {text: "İşlemler", value: "actions", sortable: false, align: 'center', width: 100},
  ];


  load() {
    this.$emit('load');
    this.getHacizMasraflari();
  }

  mounted() {
    this.getHacizMasraflari();
  }

  getHacizMasraflari() {
    this.$http
        .get('/api/v1/haciz/' + this.hacizId + '/masraf')
        .then((response: any) => {
          this.hacizMasraflari = response.data;
        });
  }

}
