
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import NoterPicker from "@/components/pickers/NoterPicker.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import Dates from "@/components/inputs/Dates.vue";
import {IpotekEntity} from "@/entity/Haciz/IpotekEntity";
import YevmiyeNumarasi from "@/components/inputs/YevmiyeNumarasi.vue";

@Component({
  components: {
    YevmiyeNumarasi,
    FormWrapper,
    NoterPicker,
    Tutar,
    Dates
  }
})
export default class IpotekForm extends Mixins(ObjectInputMixin) {
  @Prop({}) successOnValidate !: any;
  localValue!: IpotekEntity;
}
