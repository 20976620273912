export enum EvrakMaddeler{
    AlacakliData = 1,
    BorcluData,
    TerekeData,
    AlacakKalemiData,
    RehinData,
    IlamData,
    KiraKontratiData,
    AlacakData,
    TakipYoluData,
    MalikData,
    IlamOzetiData,
    TahliyeTaahhuduData,
    FaizData,
    AlacakKalemleriByParaBirimi
}