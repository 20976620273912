
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {TakipEntity} from "@/entity/TakipEntity";
import {BankaHesabiSahibiLabel} from "@/enum/BankaHesabiSahibi";
import TakipTalebiGenerator from "@/classes/evrak/TakipTalebi/TakipTalebiGenerator";
import FormWrapper from "@/components/FormWrapper.vue";
import Evrak from "@/classes/evrak/Evrak";
import OdemeEmri49Taslak from "@/components/OdemeEmriTaslaklar/OdemeEmri49Taslak.vue";
import OdemeEmri50Taslak from "@/components/OdemeEmriTaslaklar/OdemeEmri50Taslak.vue";
import OdemeEmri51Taslak from "@/components/OdemeEmriTaslaklar/OdemeEmri51Taslak.vue";
import OdemeEmri52Taslak from "@/components/OdemeEmriTaslaklar/OdemeEmri52Taslak.vue";
import IcraEmri53Taslak from "@/components/IcraEmriTaslaklar/IcraEmri53Taslak.vue";
import IcraEmri54Taslak from "@/components/IcraEmriTaslaklar/IcraEmri54Taslak.vue";
import IcraEmri55Taslak from "@/components/IcraEmriTaslaklar/IcraEmri55Taslak.vue";
import IcraEmri151Taslak from "@/components/IcraEmriTaslaklar/IcraEmri151Taslak.vue";
import OdemeEmri152Taslak from "@/components/OdemeEmriTaslaklar/OdemeEmri152Taslak.vue";
import OdemeEmri163Taslak from "@/components/OdemeEmriTaslaklar/OdemeEmri163Taslak.vue";
import TahliyeEmri56Taslak from "@/components/TahliyeEmriTaslaklar/TahliyeEmri56Taslak.vue";
import IcraEmri201Taslak from "@/components/IcraEmriTaslaklar/IcraEmri201Taslak.vue";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import OdemeEmri153Taslak from "@/components/OdemeEmriTaslaklar/OdemeEmri153Taslak.vue";

@Component({
  components: {
    OdemeEmri153Taslak,
    IcraEmri201Taslak,
    TahliyeEmri56Taslak,
    OdemeEmri163Taslak,
    OdemeEmri152Taslak,
    IcraEmri151Taslak,
    IcraEmri55Taslak,
    IcraEmri54Taslak,
    IcraEmri53Taslak,
    OdemeEmri52Taslak,
    OdemeEmri51Taslak,
    OdemeEmri50Taslak,
    OdemeEmri49Taslak,
    FormWrapper
  },
})
export default class Emirler extends Vue {
  @Prop() takip!: TakipEntity;
  @Prop() isKartNoVisible!: boolean;

  ttEvrak: Evrak = new Evrak();
  bankaHesabiSahibiLabel = BankaHesabiSahibiLabel;
  takipEskiNo!: number;

  mounted() {
    if (this.takip) {
      this.onTakipChange();
    }
  }

  constructor() {
    super();
    this.takip.talepler.forEach((talep: TakipTalebiEntity) => {
      this.takipEskiNo = Number(talep.takip_tipi.eski_no);
    });
  }

  @Watch("takip")
  onTakipChange() {
    let takipTalebiGenerator = new TakipTalebiGenerator()
    takipTalebiGenerator.setTakip(this.takip);
    this.ttEvrak = takipTalebiGenerator.getTakipTalebiEvrak();
  }

  print() {
    this.$helper.printComponent('emirler');
  }
}
