
import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component({})
export default class EnabledSwitch extends Vue {
  private enabled: boolean = false;
  @Prop({}) value!: string;
  @Prop({}) action!: string;
  @Prop({default: "12"}) md!: string;
  @Prop({default: "enabled"}) queryString!: string;

  @Watch('value')
  onPropertyChanged(value: string, oldValue: string) {
    this.switchChange();
  }

  async changeVal() {
    this.$emit('input', this.enabled);
  }

  switchChange() {
    if (this.value) {
      this.enabled = true;
    } else {
      this.enabled = false;
    }
  }

  created() {
    this.switchChange()
  }
}
