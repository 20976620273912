import {TakipDurumu} from "@/enum/TakipDurumu";

export class IsEntity{
    id!: number;
    isim!: string;
    aciklama!: string;
    atanan_id!: number;
    atanan_isim!: string;
    atayan_id!: number;
    atayan_isim!: string;
    favori_mi: boolean = false;
    tamamlandi_mi: boolean = false;
    takip_id!: number;
    dosya_esas_no!: string;
    takip_durumu!: TakipDurumu;
    not_id!: number;
    tarih!: Date;
    saat!: Date;
    hatirlatici_id!: number;
}