import {
    AlacakliOlduguDosyaTalep,
    BankaHacziTalep,
    EGMTalep,
    Ihbarname89_1Talep,
    Ihbarname89_2Talep,
    Ihbarname89_3Talep,
    MaasHacziTalep,
    PostaCekiHacizTalep,
    SgkMossipHacziTalep,
    TakbisTalep,
    TasinirHacizTalep,
    TasinirHacizTalimatiTalep
} from "@/interface/IUyapTalep";
import {AlacakliOlduguDosyaHacziEntity} from "@/entity/Haciz/AlacakliOlduguDosyaHacziEntity";
import {AracHacziEntity} from "@/entity/Haciz/AracHacziEntity";
import {BankaHacziEntity} from "@/entity/Haciz/BankaHacziEntity";
import {MaasHacziEntity} from "@/entity/Haciz/MaasHacziEntity";
import {PostaCekiHacziEntity} from "@/entity/Haciz/PostaCekiHacziEntity";
import {TasinirHacziEntity} from "@/entity/Haciz/TasinirHacziEntity";
import {SGKMossipHacziEntity} from "@/entity/Haciz/SGKMossipHacziEntity";
import {TasinirHacizTalimatiEntity} from "@/entity/Haciz/TasinirHacizTalimatiEntity";
import {Ihbarname89_1HacizEntity} from "@/entity/Haciz/Ihbarname89_1HacizEntity";
import {Ihbarname89_2HacizEntity} from "@/entity/Haciz/Ihbarname89_2HacizEntity";
import {Ihbarname89_3HacizEntity} from "@/entity/Haciz/Ihbarname89_3HacizEntity";
import {MuzekkereAlacakHacziEntity} from "@/entity/Haciz/MuzekkereAlacakHacziEntity";
import {GayrimenkulHacziEntity} from "@/entity/Haciz/GayrimenkulHacziEntity";
import {HisseKarPayiHacziEntity} from "@/entity/Haciz/HisseKarPayiHacziEntity";
import {MarkaPatentHacziEntity} from "@/entity/Haciz/MarkaPatentHacziEntity";
import {DigerHacizEntity} from "@/entity/Haciz/DigerHacizEntity";
import {HacizTuruEntity} from "@/entity/Haciz/HacizTuruEntity";
import {
    TurkUluslararasiGemiSicilineKayitliGemilerEntity
} from "@/entity/Haciz/TurkUluslararasiGemiSicilineKayitliGemilerEntity";
import {MilliGemiSicilineKayitliGemilerEntity} from "@/entity/Haciz/MilliGemiSicilineKayitliGemilerEntity";
import {IsverenEntitiy} from "@/entity/Haciz/IsverenEntitiy";
import {SatisBilgiEntity} from "@/entity/SatisBilgiEntity";

export class HacizEntity {
    id!: number;
    satis_bilgi!: SatisBilgiEntity;
    satis_bilgi_id!: number;
    haciz_talep_id!: number;
    isverenler!: Array<IsverenEntitiy>;
    haciz_turu_id!: number;
    haciz_turu!: HacizTuruEntity;
    uyap_data:  AlacakliOlduguDosyaTalep |
                EGMTalep |
                BankaHacziTalep |
                TakbisTalep |
                MaasHacziTalep |
                PostaCekiHacizTalep |
                SgkMossipHacziTalep |
                TasinirHacizTalep |
                TasinirHacizTalimatiTalep |
                Ihbarname89_1Talep |
                Ihbarname89_2Talep |
                Ihbarname89_3Talep |
                null = {};
    ekstra_data:    AlacakliOlduguDosyaHacziEntity |
                    AracHacziEntity |
                    BankaHacziEntity |
                    GayrimenkulHacziEntity |
                    MaasHacziEntity |
                    PostaCekiHacziEntity |
                    SGKMossipHacziEntity |
                    TasinirHacziEntity |
                    TasinirHacizTalimatiEntity |
                    Ihbarname89_1HacizEntity |
                    Ihbarname89_2HacizEntity |
                    Ihbarname89_3HacizEntity |
                    MuzekkereAlacakHacziEntity |
                    HisseKarPayiHacziEntity |
                    MarkaPatentHacziEntity |
                    TurkUluslararasiGemiSicilineKayitliGemilerEntity |
                    MilliGemiSicilineKayitliGemilerEntity|
                    DigerHacizEntity |
                    null = {};

    /*
        UYAP
        "Alacaklı Olduğu Dosya",
        "Araç Haczi Talebi",
        "Banka Haczi Talebi",
        "Gayrimenkul Haczi Talebi",
        "Maaş Haczi Talebi",
        "Posta Çeki Haczi Talebi",
        "SGK Mossip Haczi Talebi",
        "Taşınır Haciz Talebi",
        "Taşınır Haciz Talimatı Talebi",
        "89-1 İhbarname Talebi",
        "89-2 İhbarname Talebi",
        "89-3 İhbarname Talebi"
        ----------------------
        ICP Ekstra
        "Müzekkere Alacak Haczi",
        "Hisse/Kar Payı Haczi",
        "Marka/Patent/Endüstriyel Tasarım Haczi",
        "Gemi Haczi",
        "Diğer Haciz",
     */
}
