
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import FaizTuruPicker from "@/components/pickers/FaizTuruPicker.vue";
import HesapSekliPicker from "@/components/pickers/HesapSekliPicker.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {AlacakKalemiAlani} from "@/enum/AlacakKalemiAlani";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import FaizTuruForm from "@/components/forms/FaizTuruForm.vue";
import {HesapSekliAciklamaLabel} from "@/enum/HesapSekli";
import {FaizTuruEntity} from "@/entity/FaizTuruEntity";
import {AlacakBelgeTuru} from "@/enum/AlacakBelgeTuru";

@Component({
  components: {
    FaizTuruForm,
    FormDialog,
    FaizTuruPicker,
    HesapSekliPicker,
    Tutar
  },
})
export default class Faizler extends Mixins(ObjectInputMixin) {
  @Prop() title!: string;
  @Prop() expanded!: boolean;
  @Prop() takipTalebi !: TakipTalebiEntity;
  @Prop() alacakBelgeTuruId!:number
  @Prop() defaultFaiz!: number;
  @Prop() protestoIhtar!: boolean | string;


  action: string = "";
  panel: number = -1;
  elleFaiz: AlacakKalemiEntity = new AlacakKalemiEntity();
  hesapSekliAciklamaLabel = HesapSekliAciklamaLabel;


  get isMerkezBankasi() {
    if ((this.localValue.takip_oncesi_faiz_turu_id == 5) ||
        (this.localValue.takip_oncesi_faiz_turu_id == 6) ||
        (this.localValue.takip_oncesi_faiz_turu_id == 10) ||
        (this.localValue.takip_oncesi_faiz_turu_id == 11) ||
        (this.localValue.takip_oncesi_faiz_turu_id == 12) ||
        (this.localValue.takip_oncesi_faiz_turu_id == 13) ||
        (this.localValue.takip_sonrasi_faiz_turu_id == 5) ||
        (this.localValue.takip_sonrasi_faiz_turu_id == 6) ||
        (this.localValue.takip_sonrasi_faiz_turu_id == 10) ||
        (this.localValue.takip_sonrasi_faiz_turu_id == 11) ||
        (this.localValue.takip_sonrasi_faiz_turu_id == 12) ||
        (this.localValue.takip_sonrasi_faiz_turu_id == 13)) {
      return true;
    } else
      return false
  }
  get isProtestoIhtar() {
    return this.protestoIhtar || this.protestoIhtar === '';
  }

  onValueChange() {
    if (this.localValue.oran_aylik_mi == null) {
      this.localValue.oran_aylik_mi = false;
    }

    this.localValue.takip_sonrasi_faiz_isle = this.localValue.takip_sonrasi_faiz_isle ?? true;
    this.localValue.gecikme_tazminati_mi = this.localValue.gecikme_tazminati_mi ?? true;
    this.localValue.takip_oncesi_faiz_isle = this.localValue.takip_oncesi_faiz_isle ?? true;

    this.setElleFaiz();
  }

  onElleFaizOranMiChange() {
    this.localValue.takip_oncesi_faiz_turu = new FaizTuruEntity();
    this.localValue.takip_oncesi_faiz_turu_id = null;
    this.localValue.takip_sonrasi_faiz_turu_id = null;
    this.localValue.elle_faiz_mi=false;
    this.input();
  }

  elleFaizInput() {
    this.localValue.takip_sonrasi_faiz_turu={...this.localValue.takip_oncesi_faiz_turu};
    this.input();
  }

  mounted() {
    this.action = "/api/v1/faiz-turu/filter?aktif_mi=1";
    if (this.expanded) this.panel = 0;
    this.setElleFaiz();
  }

  setElleFaiz() {
    let elleFaiz = this.localValue?.children?.filter((child: AlacakKalemiEntity) => child.alacak_kalemi_alani_id == AlacakKalemiAlani.takipOncesiFaiz && !child.is_calculated)[0];
    if (elleFaiz) {
      this.elleFaiz = elleFaiz;
    }
  }
}
