export enum TasinirTuru {
    arac = "arac",
    ticari_isletme = "ticari_isletme",
    diger = "diger"
}

export enum TasinirTuruLabel {
    arac = "Araç",
    ticari_isletme = "Ticari İşletme",
    diger = "Diğer",
}