export enum AlacakKalemiTuru {
    asilAlacak = 1,
    kiraAlacagi,
    kiraFarkiAlacagi,
    aidatAlacagi,
    cezaiSart,
    cariHesapAlacagi,
    iseBaslatmamaTazminati,
    isGormezlikTazminati,
    isGucuKaybiTazminati,
    kidemTazminati,
    ihbarTazminati,
    ucretAlacagi,
    fazlaCalismaAlacagi,
    yillikIzinUcreti,
    haftaTatiliUcreti,
    ulusalBayramVeGenelTatilAlacagi,
    ikaramiyeVePrimAlacagi,
    donerSermayeAlacagi,
    istirakNafakasi,
    yoksullukNafakasi,
    yapilmasiYaDaYapilmamasiIstenenIs,
    yargilamaGideri,
    mahkemeVekaletUcreti,
    irtifakHakkiYargilamaGideri,
    irtifakHakkiMahkemeVekaletUcreti,
    icraInkarTazminati,
    itirazinIptaliYargilamaGideri,
    itirazinIptaliDavaVekaletUcreti
}

export enum AlacakKalemiTuruLabel {
    "Asıl Alacak" = 1,
    "Kira Alacağı" = 2,
    "Kira Farkı Alacağı" = 3,
    "Aidat Alacağı" = 4,
    "Cezai Şart" = 5,
    "Cari Hesap Alacağı" = 6,
    "İşe Başlatmama Tazminatı" = 7,
    "İş Görmezlik Tazminatı" = 8,
    "İş Gücü Kaybı Tazminatı" = 9,
    "Kıdem Tazminatı" = 10,
    "İhbar Tazminatı" = 11,
    "Ücret Alacağı" = 12,
    "Fazla Çalışma Alacağı" = 13,
    "Yıllık İzin Ücreti	" = 14,
    "Hafta Tatili Ücreti" = 15,
    "Ulusal Bayram ve Genel Tatil Alacağı" = 16,
    "İkramiye ve Prim Alacağı" = 17,
    "Döner Sermaye Alacağı	" = 18,
    "İştirak Nafakası" = 19,
    "Yoksulluk Nafakası" = 20,
    "Yapılması Ya Da Yapılmaması İstenen İş" = 21,
    "Yargılama Gideri" = 22 | 24 | 27,
    "Mahkeme Vekalet Ücreti" = 23 | 25,
    "İcra İnkar Tazminatı" = 26,
    "Dava Vekalet Ücreti" = 28
}

