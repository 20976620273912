
import { Component, Mixins, Prop } from "vue-property-decorator";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: { DatabasePicker },
})
export default class AdliyePicker extends Mixins<SimpleInputMixin>(
  SimpleInputMixin
) {
  @Prop() returnId!: boolean;
  @Prop() label!: string;
  @Prop({}) sehir!: any;
  @Prop() color: any;
  @Prop({}) required!: boolean;
  @Prop({}) rules!: string;

  get message(): string {
    return this.sehir ? "" : "Lütfen İl Seçiniz";
  }

  get action(): string {
    return "/api/v1/adliye/filter?sehir=" + this.sehir;
  }
}
