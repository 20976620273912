
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import YedieminInfo from "@/components/infos/haciz/YedieminInfo.vue";
import TasinirBilgileriList from "@/components/lists/haciz/TasinirBilgileriList.vue";
import IstihkakList from "@/components/lists/haciz/IstihkakList.vue";
import EnabledSwitch from "@/components/inputs/EnabledSwitch.vue";
import Dates from "@/components/inputs/Dates.vue";
import {AvukatTalepListCevapRow} from "@/plugins/uyap-plugin/uyap/AvukatTalepList";

@Component({
  components: {
    FormDialog,
    YedieminInfo,
    TasinirBilgileriList,
    IstihkakList,
    EnabledSwitch,
    Dates,
  }
})
export default class TasinirForm extends Mixins(ObjectInputMixin) {
  @Prop() avukatTalepList!: Array<AvukatTalepListCevapRow>;

  mounted() {
    if ((!this.localValue.uyap_data || this.localValue.uyap_data.talepKodu != this.localValue.haciz_turu.talep_kodu) && this.$store.getters.isUyapConnected) {
      let talep = this.avukatTalepList.find(value => value.talepKodu == this.localValue.haciz_turu.talep_kodu);
      this.localValue.uyap_data = {...talep};
      this.localValue.uyap_data.sorguId = '';
      this.localValue.uyap_data.tarafList = '';
      this.input();
    }
  }
}
