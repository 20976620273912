import { render, staticRenderFns } from "./MahrumiyetList.vue?vue&type=template&id=88e41d2c&scoped=true"
import script from "./MahrumiyetList.vue?vue&type=script&lang=ts"
export * from "./MahrumiyetList.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88e41d2c",
  null
  
)

export default component.exports