
import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {TakipEntity} from "@/entity/TakipEntity";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import {AlacakEntity} from "@/entity/AlacakBelgeler/AlacakEntity";
import {TasinirEntity} from "@/entity/AlacakBelgeler/TasinirEntity";

@Component({
  components: {}
})
export default class TasinirlarPicker extends Mixins(SimpleInputMixin) {
  @Prop({required: true}) takip!: TakipEntity;
  @Prop() multiple!: string | boolean;
  @Prop() rules!: string;
  @Prop() returnObject!: string | boolean;

  get isMultiple() {
    return this.multiple || this.multiple === "";
  }

  get isReturnObject() {
    return this.returnObject || this.returnObject === "";
  }

  get items() {
    let tasinirlar: Array<TasinirEntity> = []
    this.takip.talepler.forEach((talep: TakipTalebiEntity) => {
      talep.alacaklar.forEach((alacak: AlacakEntity) => {
        alacak.tasinirlar.forEach((tasinir: TasinirEntity) => (tasinirlar.push(tasinir)));
      });
    });
    return tasinirlar;
  }
}
