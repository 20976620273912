

import {Component, Mixins, Prop} from "vue-property-decorator";
import EnumPicker from "@/components/pickers/EnumPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {TahsilatTuru, TahsilatTuruLabel} from "@/enum/TahsilatTuru";

@Component({
  components: {EnumPicker}
})
export default class TahsilatTuruPicker extends Mixins(SimpleInputMixin) {

  @Prop({default:TahsilatTuru}) enumValues!: any;
  @Prop({default:TahsilatTuruLabel}) labels!: any;

  }
