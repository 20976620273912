

import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import {CinsiyetLabel} from "@/enum/Cinsiyet";
import {BorcluEntity} from "@/entity/BorcluEntity";
import BorcluForm from "@/components/forms/BorcluForm.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {BorcluForm, FormDialog}
})
export default class BorcluInfo extends Mixins(SimpleInputMixin) {
  @Prop({}) action!: string;
  cinsiyetLabels = CinsiyetLabel;

  load(){
    this.$emit('load')
  }
}
