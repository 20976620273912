

import {Component, Mixins} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import TebligatAlicisiPicker from "@/components/pickers/TebligatAlicisiPicker.vue";
import {TebligatAlicisi, TebligatAlicisiLabel} from "@/enum/TebligatAlicisi";
import {FileType} from "@/enum/FileType";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import SaveFile from "@/templates/SaveFile.vue";

@Component({
  components: {SaveFile, TebligatAlicisiPicker, FormWrapper, FormDialog}
})

export default class SatisTebligatZarfiYazdirForm extends Mixins(ObjectInputMixin) {
  //TODO: kaldırılacak
  borcluAdresBilgileri = [];
  TebligatAlicisi = TebligatAlicisi;
  TebligatAlicisiLabel = TebligatAlicisiLabel;
  downloadUdf: boolean = false;
  downloadPdf: boolean = false;
  downloadWord: boolean = false;
  FileType = FileType;
  fileName = "Satis Tabligat Zarfi"
  fileTitle = "SATIŞ TEBLİGAT ZARFI"

  mounted() {
    this.load();
  }

  load() {
    this.$emit("load");
  }

  get items() {
    let itemsLocal = [{header: "TEBLİGAT ALICISI: ", content: this.localValue?.tebligat_alicisi}]
    if (this.localValue?.tebligat_alicisi === TebligatAlicisi.ucuncuSahis) {
      itemsLocal.push({header: "AD-SOYAD: ", content: this.localValue?.ad_soyad});
      itemsLocal.push({header: "ADRES: ", content: this.localValue?.adres});
    } else {
      itemsLocal.push({header: "BORÇLU ADRES BİLGİSİ: ", content: this.localValue?.borcluAdresBilgileri})
    }
    itemsLocal.push({header: "ADET: ", content: this.localValue.adet})
    return itemsLocal;
  }
}
