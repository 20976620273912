
import {Component, Mixins, Prop} from 'vue-property-decorator';
import FormWrapper from "@/components/FormWrapper.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import TasinmazlarPicker from "@/components/pickers/TasinmazlarPicker.vue";
import TasinirlarPicker from "@/components/pickers/TasinirlarPicker.vue";
import {SatisBilgiEntity} from "@/entity/SatisBilgiEntity";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import Dates from "@/components/inputs/Dates.vue";
import EnabledSwitch from "@/components/inputs/EnabledSwitch.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import {SatisTalebiEntity} from "@/entity/SatisTalebiEntity";
import SatisTalebiForm from "@/components/forms/satis/SatisTalebiForm.vue";

@Component({
  computed: {
    SatisTalebiEntity() {
      return SatisTalebiEntity
    }
  },
  components: {
    SatisTalebiForm,
    FormDialog,
    DeleteDialog,
    Tutar, EnabledSwitch, Dates, ParaBirimiPicker, TasinirlarPicker, TasinmazlarPicker, FormWrapper
  }
})
export default class SatisBilgiForm extends Mixins(ObjectInputMixin) {
  @Prop() hacizId!: number;
  @Prop() takipId!: number;

  localValue!: SatisBilgiEntity;
  headers = [
    {text: 'Talep', value: 'talep'},
    {text: 'Durum', value: 'durum'},
    {text: 'İşlemler', value: 'actions'}
  ];

  get satisTalepleri() {
    return this.localValue.satis_talepleri.filter(item => item.isDeleted != true);
  }

  save() {
    if (this.hacizId) {
      if (this.localValue.id)
        this.$http.put('/api/v1/haciz/' + this.hacizId + '/satis/' + this.localValue.id, this.localValue);
      else
        this.$http.post('/api/v1/haciz/' + this.hacizId + '/satis', this.localValue);
    } else if (this.takipId) {
      if (this.localValue.id)
        this.$http.put('/api/v1/takip/' + this.takipId + '/satis/' + this.localValue.id, this.localValue);
      else
        this.$http.post('/api/v1/takip/' + this.takipId + '/satis', this.localValue);
    }
  }

  onAdd(satisTalebi: SatisTalebiEntity) {
    this.localValue.satis_talepleri.push(satisTalebi);
    this.input();
  }

  onUpdate(satisTalebi: SatisTalebiEntity, index: number) {
    let newArray = [...this.localValue.satis_talepleri];
    newArray[index] = satisTalebi;
    this.localValue.satis_talepleri = newArray;
    this.input();
  }

  onDelete(item: SatisTalebiEntity, index: number) {
    if (item.id)
      item.isDeleted = true;
    else
      this.localValue.satis_talepleri.splice(index, 1);
    this.input();
  }

  onKiymetOrMasrafChange() {
    this.localValue.muhammen_bedel = (this.localValue.kiymeti / 2) + this.localValue.satis_masrafi;
    this.input();
  }

  get isTakipTipiRehin(): boolean {
    // let rehin = false;
    // this.borclu.takip.talepler.forEach((talep: TakipTalebiEntity) => {
    //   if (talep.takip_tipi.rehin_mi)
    //     rehin = true;
    // })
    // return rehin;
    return false;
  }

  get kiymetTakdiriRule() {
    if (this.localValue.kiymet_takdiri_yapildi_mi !== undefined) {
      if (!this.localValue.kiymet_takdiri_yapildi_mi)
        return ['Satış bilgisi eklemek için kıymet takdiri yapılması gerekmektedir.']
      else true;
    } else return ['Lütfen bu alanı doldurun.']
  }

  satisTarihiAlert(): boolean {
    // en son oluşturulan talebin başlangıç tarihi 0 ise true döndür
    return false;
  }
}
