import {SatisEntity} from "@/entity/SatisEntity";

export class SatisTalebiEntity {
    id: number | null = null;
    satis_bilgi_id:number | null = null;
    birinci_satis: SatisEntity = new SatisEntity();
    birinci_satis_id: number | null = null;
    ikinci_satis: SatisEntity = new SatisEntity();
    ikinci_satis_id: number | null = null;
    isDeleted: boolean | null = null;
}