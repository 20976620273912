
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import IstihkakList from "@/components/lists/haciz/IstihkakList.vue";
import HisseTuruPicker from "@/components/pickers/haciz/HisseTuruPicker.vue";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import NoterPicker from "@/components/pickers/NoterPicker.vue";
import EnabledSwitch from "@/components/inputs/EnabledSwitch.vue";
import Dates from "@/components/inputs/Dates.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import KiymetTakdiriForm from "@/components/forms/KiymetTakdiriForm.vue";

@Component({
  components: {
    KiymetTakdiriForm,
    FormDialog,
    IstihkakList,
    HisseTuruPicker,
    ParaBirimiPicker,
    NoterPicker,
    EnabledSwitch,
    Dates,
    Tutar,
  }
})
export default class HisseKarPayiHacziForm extends Mixins(ObjectInputMixin) {
  mounted() {
    if (this.localValue.uyap_data)
      delete this.localValue.uyap_data;
  }
}
