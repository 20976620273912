import {ParaBirimi} from "@/enum/ParaBirimi";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import {SatisTalebiEntity} from "@/entity/SatisTalebiEntity";

export class SatisBilgiEntity {
    id: number | null = null;
    haciz_id: number | null = null;
    takip_id: number | null = null;
    kiymet_takdiri_yapildi_mi: boolean = false;
    kiymet_takdiri_tarihi: Date | null = null;
    kiymeti: number = 0;
    para_birimi_id: number = ParaBirimi.TRY;
    para_birimi: ParaBirimiEntity | null = null;
    itiraz_var_mi: boolean = false;
    itiraz_kesinlesme_tarihi: Date | null = null;
    satis_avansi_yatirildi_mi: boolean = false;
    satis_masrafi: number = 0;
    muhammen_bedel: number = 0;
    satis_talepleri: Array<SatisTalebiEntity> = [];
}