
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import AdresForm from "@/components/forms/AdresForm.vue";
import Tutar from "@/components/inputs/Tutar.vue"
import {YedieminEntity} from "@/entity/Haciz/YedieminEntity";

@Component({
  components: {FormWrapper, AdresForm, Tutar}
})
export default class YedieminForm extends Mixins(ObjectInputMixin) {
  localValue!: YedieminEntity;
  @Prop() update: any;

  get isUpdate() {
    return this.update || this.update === "";
  }

  adresUpdate(yedieminId: number) {
    if (this.localValue.adres_id != null) {
      return this.$http.put('/api/v1/adres/' + this.localValue.adres_id, this.localValue.adres)
    } else if (this.localValue.adres) {
      return this.$http.post('/api/v1/yediemin/' + yedieminId + '/adres', this.localValue.adres)
    }
  }

  save() {
    if (this.isUpdate) {
      this.$http.put('/api/v1/yediemin/' + this.localValue.id, this.localValue)
          .then((response) => {
            let yedieminId = response.data.id;
            Promise.all([this.adresUpdate(yedieminId)]).then(() => {
              this.$emit("onSuccess");
            }).catch(() => {
            })
          });
    } else {
      this.$http.post('/api/v1/yediemin', this.localValue)
          .then((response) => {
            let yedieminId = response.data.id;
            Promise.all([this.adresUpdate(yedieminId)]).then(() => {
              this.$emit("onSuccess");
            });
          });
    }
  }
}
