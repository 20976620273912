
import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import IsverenForm from "@/components/forms/haciz/IsverenForm.vue";
import {IsverenEntitiy} from "@/entity/Haciz/IsverenEntitiy";

@Component({
  components: {IsverenForm, FormDialog, DeleteDialog}
})
export default class IsverenList extends Mixins(SimpleInputMixin) {
  @Prop({}) successOnValidate !: any;
  headers = [
    {text: "İşveren Kişi/Kurum", value: "isim"},
    {text: "İşlemler", value: "actions", width: 100, sortable: false, align: 'center'},
  ];
  localValue!: Array<IsverenEntitiy>;

  mounted() {
    if (!this.value) {
      this.localValue = [];
    }
  }

  onAdd(item: IsverenEntitiy) {
    this.localValue.push(item);
    this.input();
  }

  onUpdate(item: IsverenEntitiy, index: number) {
    let newArray = [...this.localValue];
    newArray[index] = item;
    this.localValue = newArray;
    this.input();
  }

  onDelete(index: number) {
    this.localValue.splice(index, 1);
    this.input();
  }
}
