
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import Dates from "@/components/inputs/Dates.vue";
import HacizTalepBankaPicker from "@/components/pickers/uyap/HacizTalepBankaPicker.vue";
import HacizTalepHesapPicker from "@/components/pickers/uyap/HacizTalepHesapPicker.vue";
import HacizTalepEvrakAdiRadio from "@/components/radio/uyap/HacizTalepEvrakAdiRadio.vue";
import HacizTalepBankaHaricAdiRadio from "@/components/radio/uyap/HacizTalepBankaHaricAdiRadio.vue";
import {AvukatTalepListCevapRow} from "@/plugins/uyap-plugin/uyap/AvukatTalepList";

@Component({
  components: {
    HacizTalepBankaHaricAdiRadio,
    HacizTalepEvrakAdiRadio, HacizTalepHesapPicker, HacizTalepBankaPicker, Dates
  }
})
export default class BankaHacizForm extends Mixins(ObjectInputMixin) {
  @Prop({required: true}) kisiMi !: boolean;
  @Prop() avukatTalepList!: Array<AvukatTalepListCevapRow>;

  mounted() {
    if ((!this.localValue.uyap_data || this.localValue.uyap_data.talepKodu != this.localValue.haciz_turu.talep_kodu) && this.$store.getters.isUyapConnected) {
      let talep = this.avukatTalepList.find(value => value.talepKodu == this.localValue.haciz_turu.talep_kodu);
      this.localValue.uyap_data = {...talep};
      this.localValue.uyap_data.sorguId = '';
      this.localValue.uyap_data.tarafList = '';
      this.input();
    }
  }

  onEvrakAdiChange(e: string) {
    this.localValue.uyap_data.evrakAdiSTR = e;
    if (!(this.localValue.uyap_data.evrakAdi == 'IHB_2' || this.localValue.uyap_data.evrakAdi == 'IHB_3')) {
      this.localValue.uyap_data.tebligTarihi = '';
    }
    this.input();
  }
}
