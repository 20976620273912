

import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {AracHacziParamsCevapRow} from "@/plugins/uyap-plugin/uyap/AracHacziParams";

@Component({
  components: {}
})

export default class HacizSerhiPicker extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;
  @Prop() showUyapIcon!: string | boolean;
  @Prop() tooltip!: string;

  items: Array<AracHacziParamsCevapRow> = [];

  get isShowUyapIcon() {
    return this.showUyapIcon || this.showUyapIcon === "";
  }

  onHacizSerhiChange(v: AracHacziParamsCevapRow) {
    this.$emit('hacizSerhiText', (this.items.find(item => item.value == this.localValue))?.name);
    this.input();
  }

  created() {
    this.load();
  }

  async load() {
    this.items = (await this.$uyap.AracHacziParams().run({})).items;
  }
}
