
import {Component, Mixins, Prop} from "vue-property-decorator";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {DatabasePicker},
})
export default class NotTurPicker extends Mixins(SimpleInputMixin) {
  @Prop() returnId!: boolean | string;
  @Prop() rules!: string;
  @Prop() hideDetails!: string | boolean;
  @Prop() multiple!: string | boolean;

  get isReturnId(){
    return this.returnId || this.returnId===""
  }

  get isHideDetails(){
    return this.hideDetails || this.hideDetails===""
  }

  get isMultiple(){
    return this.multiple || this.multiple===""
  }
}
