
import {Component, Prop, Vue} from "vue-property-decorator";
import MasrafForm from "@/components/forms/MasrafForm.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {MasrafEntity} from "@/entity/MasrafEntity";
import FormWrapper from "@/components/FormWrapper.vue";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";

@Component({
  components: {FormWrapper, DeleteDialog, FormDialog, MasrafForm},
})
export default class MasrafList extends Vue {
  @Prop({}) items!: Array<MasrafEntity>;
  @Prop({required: true}) alacakId!: number;
  @Prop({required: true}) takipDurumu!: string;
  @Prop({required: true}) takipTarihi!: string;
  @Prop() hideCloseButton!: boolean | string;



  get isHideClose() {
    return this.hideCloseButton || this.hideCloseButton === "";
  }

  search: string = "";
  headers = [
    {text: "Takip Tarihi", align: "start", sortable: false, value: "takip_tarihi",},
    {text: "Masraf Tarihi", align: "start", sortable: false, value: "tarih",},
    {text: "Masraf Çeşidi", align: "start", sortable: false, value: "masraf_cesidi",},
    {text: "Tutar", value: "masraf_tutari", align: "right"},
    {text: "Masraf Türü", value: "gayri_resmi"},
    {text: "Belgeli", value: "belgeli_mi"},
    {text: "İşlemler", value: "actions", sortable: false, align: 'center', width: 100},
  ];

  getColor(alacakKalem: AlacakKalemiEntity) {
    if (alacakKalem.alacak_kalemi_alani.takip_oncesi_mi && alacakKalem.tarih < this.takipTarihi) {
      return '#F4511E'
    } else {
      return '#039BE5'
    }
  }

  load() {
    this.$emit('load');
  }

}
