
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import YedieminInfo from "@/components/infos/haciz/YedieminInfo.vue";
import IstihkakList from "@/components/lists/haciz/IstihkakList.vue";
import NoterPicker from "@/components/pickers/NoterPicker.vue";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import EnabledSwitch from "@/components/inputs/EnabledSwitch.vue";
import Dates from "@/components/inputs/Dates.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import TebligatList from "@/components/anakart/tebligat/TebligatList.vue";
import YevmiyeNumarasi from "@/components/inputs/YevmiyeNumarasi.vue";

@Component({
  components: {
    YevmiyeNumarasi,
    TebligatList,
    FormDialog,
    DeleteDialog,
    YedieminInfo,
    IstihkakList,
    NoterPicker,
    ParaBirimiPicker,
    EnabledSwitch,
    Dates,
    Tutar,
  }
})
export default class DigerHacizForm extends Mixins(ObjectInputMixin) {
  mounted() {
    if (this.localValue.uyap_data)
      delete this.localValue.uyap_data;
  }
}
