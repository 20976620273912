
import {Component, Prop, Vue} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";

@Component({
    components: {FormWrapper}
})
export default class MahrumiyetList extends Vue {
    @Prop() plaka!: string;

    items: Array<any> = [];
    loading = true;

    headers = [
        {text: 'Takyidat Sırası', value: 'takyidatSirasi'},
        {text: 'Ekleyen Birim', value: 'ekleyenBirim'},
        {text: 'Ekleyen Tarihi', value: 'eklenmeTarihi'},
        {text: 'Şerh Türü', value: 'serhTuru'},
        {text: 'Kurum Adı', value: 'kurumAdi'},

    ];

    mounted() {
        this.mahrumiyetBilgileriniGetir();
    }

    mahrumiyetBilgileriniGetir() {
        this.loading = true;
        this.$http.get('/api/v1/arac-mahrumiyet-bilgileri/' + this.plaka).then((response) => {
            this.items = response.data;
            this.loading = false;
        });

    }
}
