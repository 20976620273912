

import {Component, Mixins, Prop} from "vue-property-decorator";
import EnumPicker from "@/components/pickers/EnumPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {SatisYapilamamaSebebi, SatisYapilamamaSebebiLabel} from "@/enum/SatisYapilamamaSebebi";

@Component({
  components: {EnumPicker}
})
export default class SatisYapilamamaSebebiPicker extends Mixins(SimpleInputMixin) {
  SatisYapilamamaSebebi = SatisYapilamamaSebebi;
  SatisYapilamamaSebebiLabel = SatisYapilamamaSebebiLabel;
}
