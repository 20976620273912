import {TasinirEntity} from "@/entity/AlacakBelgeler/TasinirEntity";
import {TasinmazEntity} from "@/entity/Haciz/TasinmazEntity";

export class KiymetTakdiriEntity {
    itiraz_var_mi : boolean = false;
    itiraz_kesinlesme_tarihi !: Date;
    takdir_edilen_deger !: number;
    tarih !: Date;
    // TODO: Rehin satışı netleşince devam edilecek
    // tasinir_id!: number;
    // tasinir!: TasinirEntity
    // tasinmaz_id!: number;
    // tasinmaz!: TasinmazEntity;
}