
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import IstihkakList from "@/components/lists/haciz/IstihkakList.vue";
import MarkaTuruPicker from "@/components/pickers/haciz/MarkaTuruPicker.vue";
import NoterPicker from "@/components/pickers/NoterPicker.vue";
import EnabledSwitch from "@/components/inputs/EnabledSwitch.vue";
import Dates from "@/components/inputs/Dates.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import YevmiyeNumarasi from "@/components/inputs/YevmiyeNumarasi.vue";

@Component({
  components: {
    YevmiyeNumarasi,
    IstihkakList,
    NoterPicker,
    FormDialog,
    EnabledSwitch,
    Tutar,
    MarkaTuruPicker,
    Dates,
  }
})
export default class MarkaPatentHacziForm extends Mixins(ObjectInputMixin) {
  mounted() {
    if (this.localValue.uyap_data)
      delete this.localValue.uyap_data;
  }
}
