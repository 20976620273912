

import {Component, Mixins, Prop} from "vue-property-decorator";
import EnumPicker from "@/components/pickers/EnumPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {
  KisiTebligatAdresTuru,
  KurumTebligatAdresTuru,
  TebligatAdresTuru,
  TebligatAdresTuruLabel
} from "@/enum/TebligatAdresTuru";

@Component({
  components: {EnumPicker}
})
export default class TebligatAdresTuruPicker extends Mixins(SimpleInputMixin) {
  @Prop() kisiMi !: boolean | null;
  @Prop() rules !: string;

  turlerLabel = TebligatAdresTuruLabel;

  get turler() {
    if (this.kisiMi)
      return KisiTebligatAdresTuru;
    else if (this.kisiMi == false)
      return KurumTebligatAdresTuru;
    else
      return TebligatAdresTuru;
  }
}
