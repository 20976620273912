
import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import {mask} from "vue-the-mask";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  directives: {
    mask,
  },
})
export default class Times extends Mixins(SimpleInputMixin) {
  @Prop({}) label!: string;
  @Prop() color!: string;
  @Prop({default: true}) clearable!: boolean;
  @Prop({}) required!: boolean;
  @Prop({}) rules!: string;
  @Prop({}) reverse!: any;
  @Prop({}) readonly!: boolean;
  @Prop({}) aciklama!: string;
  @Prop({}) persistentHint!: any;

  mask = "##:##";

  get isReverse() {
    return this.reverse || this.reverse === "";
  }

  get isPersistentHint() {
    return this.persistentHint || this.persistentHint === "";
  }

}
