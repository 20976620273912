export enum TakipYolu {
    haciz = 1,
    ihtiyatiHaciz_haciz,
    haciz_satis,
    rehninParayaCevrilmesi,
    haciz_tahliye,
    iflas,
    haciz_mudahaleninMeniSuretiyleTahliye,
    haciz_mudahaleninMeniSuretiyleTahliye_kal,
    mudahaleninMeni_kal,
    tahliye_kal,
    ecrimisil,
    teslim,
    mudahaleninMeni_tahliye,
    cocukTeslimi,
    tahliye,
    ipoteginPrayaCevrilmesi,
    diger,
}

export enum TakipYoluLabel {
    haciz = "Haciz",
    ihtiyatiHaciz_haciz = "İhtiyati Haciz - Haciz",
    haciz_satis = "Haciz - Satış",
    rehninParayaCevrilmesi = "Rehnin Paraya Çevrilmesi",
    haciz_tahliye = "Haciz - Tahliye",
    iflas = "İflas",
    haciz_mudahaleninMeniSuretiyleTahliye = "Haciz - Müdahalenin Men’i Suretiyle Tahliye",
    haciz_mudahaleninMeniSuretiyleTahliye_kal = "Haciz - Müdahalenin Men’i Suretiyle Tahliye - Kal",
    mudahaleninMeni_kal = "Müdahalenin Men’i ve Kal",
    tahliye_kal = "Tahliye ve Kal",
    ecrimisil = "Ecrimisil",
    teslim = "Teslim",
    mudahaleninMeni_tahliye = "Müdahalenin Men’i ve Tahliye",
    cocukTeslimi = "Çocuk Teslimi",
    tahliye = "Tahliye",
    ipoteginPrayaCevrilmesi = "İpoteğin Paraya Çevrilmesi",
    diger = "Diğer",
}