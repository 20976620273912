
import {Prop, Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {ItirazinIptaliDavasiEntity} from "@/entity/ItirazinIptaliDavasiEntity";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import ItirazinIptaliDavasiForm from "@/components/forms/ItirazinIptaliDavasiForm.vue";
import {DavaSonucu, DavaSonucuLabel} from "@/enum/DavaSonucu";
import {BorcluEntity} from "@/entity/BorcluEntity";

@Component({
  components: {ItirazinIptaliDavasiForm, FormDialog}
})

export default class ItirazinIptaliDavasiInfo extends Mixins(ObjectInputMixin) {
  @Prop() takipId!: number;
  @Prop() disabled!: boolean;
  @Prop() takipTipiId!: number;

  localValue!: ItirazinIptaliDavasiEntity;
  davaSonucu = DavaSonucu;
  davaSonucuLabel = DavaSonucuLabel;

  load() {
    this.$emit("load");
  }

  onAdd(item: ItirazinIptaliDavasiEntity) {
    this.localValue = item;
    this.input();
  }
}
