

import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";
import {MasrafCesitEntity} from "@/entity/MasrafCesitEntity";

@Component({
  components: {DatabasePicker}
})
export default class MasrafCesidiPicker extends Mixins(SimpleInputMixin) {
  @Prop() returnId!: string;
  @Prop() type!: string;
  @Prop() rules !: any;

  get action(): string {
    if(this.type && this.type != "") {
      return "/api/v1/masraf-cesidi/filter?masrafCesidiAlani=" + this.type;
    }
    return "/api/v1/masraf-cesidi";
  }

  get isReturnId(){
    return this.returnId || this.returnId === "";
  }
}
