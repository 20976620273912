export enum MasrafCesidi {
    dosyamasrafi = 1,
    evrakmasrafi,
    damgapulumasrafi,
    tebligatgideri,
    haciztaksigideri,
    talimatdosyagideri,
    memuryollukgideri,
    cilingirmasrafi,
    i̇langideri,
    mahkemedosyagideri,
    kesifmasrafi,
    zabitatahkikatgideri,
    teskeremasrafi,
    suretharci,
    nufusteskeregideri,
    i̇cravekaletharci,
    mbbcezadavvekucreti,
    otuzbesegoreteblig,
    barovekaletpulu,
    tebligatcevappulu,
    cumsavfaksgideri,
    yenidenodemeemri,
    muhtiragideri,
    maashaczimasrafi,
    maashaczifekki,
    maashaczitekidi,
    seksendokuzabirmasrafi,
    yuzucdavetiyemasrafi,
    mbbtebligpulu,
    mbbtalimatpulu,
    i̇ti̇pdavvekaletpulu,
    i̇ti̇pdavtebligpulu,
    kararharci,
    karartebligpulu,
    takipgenelgiderucreti,
    talimathacizgenelgider,
    i̇crai̇nkartazminati,
    i̇ti̇pdavharclari,
    i̇cradosyasicelpgideri,
    bilirkisiucreti,
    bilirkisitebligucreti,
    satistaksigideri,
    satismemuryollukgideri,
    satistebligpulu,
    yediemin,
    hammaliyevenaklgideri,
    mbbvekaletharci,
    mbbvekaletpulu,
    seksendokuzaikimasrafi,
    eskimasraflar,
    i̇crakefaletgideri,
    i̇tirazini̇ptalidosyagideri,
    bilirkisiraporununtebligi,
    yetkii̇tirazipostamasrafi,
    postaagirlikmasrafi,
    temyizgiderleri,
    yenilenentakipharclari,
    yetkii̇tirazitebligpulu,
    i̇crakefilitebligatmasrafi,
    cezadusumfaksmasrafi,
    mektupgonderimmasrafi,
    entaksiucreti,
    haricengorusme,
    i̇htiyatihacizmasrafi,
    diger,
}

export enum MasrafCesidiLabel {
    "Dosya Masrafı" = 1,
    "Evrak Masrafı",
    "Damga Pulu Masrafı",
    "Tebligat Gideri",
    "Haciz Taksi Gideri",
    "Talimat Dosya Gideri",
    "Memur Yolluk Gideri",
    "Çilingir Masrafı",
    "İlan Gideri",
    "Mahkeme Dosya Gideri",
    "Keşif Masrafı",
    "Zabıta Tahkikat Gideri",
    "Teskere Masrafı",
    "Suret Harcı",
    "Nüfus Teskere Gideri",
    "İcra Vekalet Harcı",
    "MBB. Ceza Dav. Vek. Ücreti",
    "35e Göre Tebliğ",
    "Baro Vekalet Pulu",
    "Tebligat Cevap Pulu",
    "Cum. Sav. Faks Gideri",
    "Yeniden Ödeme Emri",
    "Muhtıra Gideri",
    "Maaş Haczi Masrafı",
    "Maaş Haczi Fekki",
    "Maaş Haczi Tekidi",
    "89/1 Masrafı",
    "103 Davetiye Masrafı",
    "MBB Tebliğ Pulu",
    "MBB Talimat Pulu",
    "İt. İp. Dav. Vekalet Pulu",
    "İt. İp. Dav. Tebliğ Pulu",
    "Karar Harcı",
    "Karar Tebliğ Pulu",
    "Takip Genel Gider Ücreti",
    "Talimat Haciz Genel Gider",
    "İcra İnkar Tazminatı",
    "İt. İp. Dav. Harçları",
    "İcra Dosyası Celp Gideri",
    "Bilirkişi Ücreti",
    "Bilirkişi Tebliğ Ücreti",
    "Satış Taksi Gideri",
    "Satış Memur Yolluk Gideri",
    "Satış Tebliğ Pulu",
    "Yed-i Emin",
    "Hammaliye ve Nakl. Gideri",
    "MBB Vekalet Harcı",
    "MBB Vekalet Pulu",
    "89/2 Masrafı",
    "Eski Masraflar",
    "İcra Kefalet Gideri",
    "İtirazin İptali Dosya Gideri",
    "Bilirkişi Raporunun Tebliği",
    "Yetki İtirazı Posta Masrafı",
    "Posta Ağırlık Masrafı",
    "Temyiz Giderleri",
    "Yenilenen Takip Harçları",
    "Yetki İtirazı Tebliğ Pulu",
    "İcra Kefili Tebligat Masrafı",
    "Ceza Düşüm Faks Masrafı",
    "Mektup Gönderim Masrafı",
    "En Taksi Ücreti",
    "Haricen Görüşme",
    "İhtiyati Haciz Masrafı",
    "Diğer"

}