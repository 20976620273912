
import {Prop, Component ,Mixins} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";

@Component({
  components:{DatabasePicker}
})

export default class AlacakPicker extends Mixins(SimpleInputMixin){
  @Prop() returnId!: string | boolean;
  @Prop() takipId!: number;
  @Prop({default: 'Alacak'}) label!: string;

}
