
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import IstihkakList from "@/components/lists/haciz/IstihkakList.vue";
import TemlikList from "@/components/lists/haciz/TemlikList.vue";
import MuzekkereAdresBilgileriList from "@/components/lists/haciz/MuzekkereAdresBilgileriList.vue";
import EnabledSwitch from "@/components/inputs/EnabledSwitch.vue";
import Dates from "@/components/inputs/Dates.vue";
import Tutar from "@/components/inputs/Tutar.vue";

@Component({
  components: {
    FormDialog,
    IstihkakList,
    TemlikList,
    MuzekkereAdresBilgileriList,
    Tutar,
    EnabledSwitch,
    Dates,
  }
})
export default class MuzekkereAlacakHacziForm extends Mixins(ObjectInputMixin) {

  mounted() {
    if (this.localValue.uyap_data)
      delete this.localValue.uyap_data;
  }

  barkodSorgulama() {
    window.open('https://www.turkiye.gov.tr/ptt-gonderi-takip', '_blank');
  }
}
