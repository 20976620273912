

import { Component, Mixins, Prop, Ref, Watch } from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import KisiKurumForm from "@/components/forms/KisiKurumForm.vue";
import TarafSifatiPicker from "@/components/pickers/TarafSifatiPicker.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import { KisiKurumTuru } from "@/enum/KisiKurumTuru";
import { BorcluEntity } from "@/entity/BorcluEntity";
import { KisiEntity } from "@/entity/KisiEntity";
import { KurumEntity } from "@/entity/KurumEntity";
import { AdresEntity } from "@/entity/AdresEntity";
import { StatusCodeHelper } from "@/helpers/StatusCodeHelper";
import BorcluVekilList from "@/components/lists/BorcluVekilList.vue";
import BorcluVekiliForm from "@/components/forms/BorcluVekiliForm.vue";
import { ItemArrayManagerTableHeader } from "@/interface/TableHeader";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import {onUpdated} from "vue";

@Component({
  methods: {onUpdated},
  components: {
    BorcluVekiliForm,
    ItemArrayManager,
    BorcluVekilList,
    FormWrapper,
    Tutar,
    KisiKurumForm,
    TarafSifatiPicker
  }
})
export default class BorcluForm extends Mixins(ObjectInputMixin) {
  @Prop({ required: true }) takipId!: number;
  @Prop() borclular!: Array<BorcluEntity>;
  @Prop({ default: false }) updateBorcluInfo!: boolean;
  @Ref('formWrapper') formWrapper!: FormWrapper;

  searched: boolean = false;
  existings: Array<KisiEntity> | Array<KurumEntity> = [];
  adresler: Array<AdresEntity> = [];
  snackbar:boolean = false;
  adrescheck: boolean = false;

  headers: Array<ItemArrayManagerTableHeader> = [
    { text: "Adı", value: "ad", renderer: (row) => (row?.ad) },
    { text: "Soyad", value: "soyad", renderer: (row) => row?.soyad },
    { text: "Teblig Tarihi", value: "teblig_tarihi", renderer: (row) => this.$helper.tarihStr(row?.teblig_tarihi) }
  ];

  async handleVekiller(){
    const id = this.localValue.kisi?.id || this.localValue.kurum?.id;
    if(id){
      const response = await this.$http.get(`/api/v1/kisi/${id}`);
      this.localValue.vekiller = response.data.borclu_vekilleri || [];
    }

    this.input()
  }
  mounted() {
    this.adrescheck = false;
    if (this.localValue && this.localValue.id) {
      this.searched = true;
    }
    if (this.borclular) {
      this.existings = this.borclular.map((borclu: BorcluEntity) => borclu.kisi ?? borclu.kurum ?? null);
    }
  }
  @Watch('snackbar')
    adrescheckChanged() {
    if(!this.snackbar){
      this.formWrapper.onFly=false
    }
  }

  @Watch("borclular.length")
  onItemsChange() {
    this.existings = this.borclular.map((borclu: BorcluEntity) => borclu.kisi ?? borclu.kurum ?? null);
  }
  onUpdateSearched(value: boolean) {
    this.searched = value;
  }

  setDefaultFields() {
    if (!this.localValue.sorumluluk_siniri_var_mi) {
      this.localValue.sorumluluk_siniri_var_mi = false;
    }
    if (!this.localValue.tereke_mi) {
      this.localValue.tereke_mi = false;
    }
  }

  async onKisiKurumChange() {
    try {
      const kisiId = this.localValue.kisi?.id
      const kurumId = this.localValue.kurum?.id;
      if (kisiId && kisiId !== 0) {
        const response = await this.$http.get(`/api/v1/kisi/${kisiId}`);
        this.localValue.vekiller = response.data.borclu_vekilleri || [];
        if (this.localValue.vekiller.length === 0) {
          console.warn("No representatives found.");
        }
        this.searched = true;
        this.$emit("selected", response.data);
      } else if (kurumId && kurumId !== 0) {
        const response = await this.$http.get(`/api/v1/kurum/${kurumId}`);
        this.localValue.vekiller = response.data.borclu_vekilleri || [];
        if (this.localValue.vekiller.length === 0) {
          console.warn("No representatives found.");
        }
        this.searched = true;
        this.$emit("selected", response.data);
      }else{
        this.searched = true;
        this.localValue.vekiller = [];
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  onClose() {
    this.formWrapper.onFly = false;
    this.$emit("onClose");
  }

  resetForm() {
    if (!this.localValue.id) {
      delete this.localValue.kisi;
      delete this.localValue.kurum;
      this.localValue.kisi_kurum_turu = KisiKurumTuru.sahis;
      this.searched = false;
    }
    this.$emit("onClose");
  }

  /*async vekilUpdate(borcluID: number) {
    for (const vekil of this.localValue.vekiller) {
      await this.$http.post('/api/v1/borclu/' + borcluID + '/vekil', vekil).catch(error => StatusCodeHelper.error(error));

    }

  }
*/
  async save() {
    this.formWrapper.onFly = true;
    this.setDefaultFields();
    this.$store.state.createTcKimlikErrors = [];


    if((this.localValue.kisi && this.localValue.kisi.adresler.length > 0) || (this.localValue.kurum && this.localValue.kurum.adresler.length > 0)) {
      if (this.localValue.kurum_id || this.localValue.kisi_id) {
        await this.updateBorclu();
      } else {
        if (this.localValue.kisi_kurum_turu === KisiKurumTuru.sahis) {
          if (this.localValue.kisi.id === 0) {
            this.localValue.kisi.id = null;
          }
          if (this.localValue.kisi.id == null) {
            await this.insertBorclu();
          } else {
            this.localValue.kisi_id = this.localValue.kisi.id;
            await this.insertBorclu();
          }
        } else if (this.localValue.kisi_kurum_turu === KisiKurumTuru.sirket) {
          if (this.localValue.kurum.id === 0) {
            this.localValue.kurum.id = null;
          }
          if (this.localValue.kurum.id == null) {
            await this.insertBorclu();
          } else {
            this.localValue.kurum_id = this.localValue.kurum.id;
            await this.insertBorclu();
          }
        }
      }
    }else{
      this.snackbar=true
    }
  }

  async insertBorclu() {
    try {
      const response = await this.$http.post(
          `/api/v1/takip/${this.takipId}/borclu`,
          this.localValue
      );
      const borcluID = response.data.id;
      await this.handleAdresRequests(borcluID);

      if (
          (this.localValue.kisi && this.localValue.kisi.id == null) ||
          (this.localValue.kurum && this.localValue.kurum.id == null)
      ) {
        /*await this.vekilUpdate(borcluID).catch(error => {
          console.error("Vekil ekleme sırasında hata oluştu:", error);
        });*/
      }

      this.$emit("onSuccess");
      this.resetForm();

    } catch (error) {
      StatusCodeHelper.error(error);
    } finally {
      this.formWrapper.onFly = false;
    }
  }

  async updateBorclu() {
    try {
      await this.$http.put(
          `/api/v1/borclu/${this.localValue.id}`,
          this.localValue
      );
      await this.handleAdresRequests(this.localValue.id);


      /*this.vekilUpdate(this.localValue.id).catch(error => {
        console.error("Vekil güncelleme sırasında hata oluştu:", error);
      });*/

      this.$emit("onSuccess");
      this.resetForm();

    } catch (error) {
      StatusCodeHelper.error(error);
    } finally {
      this.formWrapper.onFly = false;
    }
  }

  async handleAdresRequests() {
    const adresler: AdresEntity[] = this.localValue.kisi?.adresler || this.localValue.kurum?.adresler || [];
    for (const adres of adresler) {
      if (adres.id) {
        await this.$http.put(`/api/v1/adres/${adres.id}`, adres);
      }
    }
  }
}
