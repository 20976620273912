import AlacakliData from "@/classes/evrak/AlacakliData";
import BorcluData from "@/classes/evrak/BorcluData";
import TerekeData from "@/classes/evrak/TerekeData";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";

export default class Evrak {
    ornekNo: string = "";
    dosyaEsasNo: string = "";
    kartNo: string = "";
    bankaAdi: string = "";
    ibanNo: string = "";
    icraDairesi: string = "";
    takipTarihi!: Date;
    FaizData : string[]= [];
    AlacakliData: Array<AlacakliData> = [];
    BorcluData: Array<BorcluData> = [];
    TerekeData: Array<TerekeData> = [];
    AlacakKalemiData: string[] = [];
    RehinData: string[] = [];
    IlamData: string[] = [];
    KiraKontratiData: string[] = [];
    AlacakData: string[] = [];
    TakipYoluData: string[] = [];
    AlacakKalemiHesapMetni: string = "";
    MalikData: string[] = [];
    IlamOzetiData: string[] = [];
    TahliyeTaahhuduData: string[] = [];

    TutarVeAciklama:Array<object>=[];
    DovizDetayi!:object;
    AlacaklininTalepEttigiHak!:string;
    KesideciMetni!:string;
    AlacakToplami:Array<object>=[];
    TakipOncesiTahsilat:Array<object>=[]
    AlacakKalemleriByParaBirimi: Map<number, Array<AlacakKalemiEntity>> = new Map<number, Array<AlacakKalemiEntity>>();

}